import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";

export default function AddSet({ sets }) {
    const [inputs, setInputs] = useState({});
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        axios.post('/api/create/set', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs
                    }));
                    setImageSrc(null);
                    setImageFile(null);
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Set</h3>
                    </InputLabel>
                    <Grid sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            id="title"
                            label="Name"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            id="bonus_one"
                            label="1 Bonus"
                            name="bonus_one"
                            value={inputs.bonus_one}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            id="bonus_two"
                            label="2 Bonus"
                            name="bonus_two"
                            value={inputs.bonus_two}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            id="bonus_three"
                            label="3 Bonus"
                            name="bonus_three"
                            value={inputs.bonus_three}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            id="bonus_four"
                            label="4 Bonus"
                            name="bonus_four"
                            value={inputs.bonus_four}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
            </Container>
        </>
    );
}
