import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Avatar from '@mui/material/Avatar';
import {Tooltip, tooltipClasses} from "@mui/material";

const LogoAvatar = styled(Avatar)(({ theme, shadowColor = '#000' }) => ({
    width: 100,
    height: 100,
    borderRadius: theme.shape.borderRadius * 2,
    border: '2px solid black',
    marginRight: theme.spacing(2),
    boxShadow: `0px 2px 10px 0px ${shadowColor}`,
}));

const TitleTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1.2rem', // Adjust the font size here
        padding: theme.spacing(1),
    },
}));

function ImageWithShadow({ src, alt, gameTitle}) {

    return (
        <TitleTooltip title={gameTitle}>
            <LogoAvatar src={src} alt={alt} />
        </TitleTooltip>
    );
}

export default ImageWithShadow;
