import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.scss';
import Grid from "@mui/material/Grid";
import {CssBaseline, Typography} from "@mui/material";
import RequireAdmin from "./components/auth/RequireAdmin";
import Header from "./components/header";
import ShowSnack from "./components/util/ShowSnack";
import { useSessionData } from "./components/auth/SessionDataProvider";
import BuildPlanner from "./components/build_planner/BuildPlanner";
import AddLocation from "./components/create/AddLocation";
import AddSet from "./components/create/AddSet";
import AddGear from "./components/create/AddGear";
import AddMod from "./components/create/AddMod";
import AddCradle from "./components/create/AddCradle";
import AddCalibration from "./components/create/AddCalibration";
import AddBuildType from "./components/create/AddBuildType";
import AddAnomalies from "./components/create/AddAnomalies";
import AddServerDifficulty from "./components/create/AddServerDifficulty";
import CreateNickname from "./components/create/CreateNickname";
import AddStat from "./components/create/AddStat";
import BuildList from "./components/build_planner/BuildList";
import ViewBuild from "./components/build_planner/ViewBuild";
import axios from "axios";
import SimpleBox from "./components/util/SimpleBox";
import {useMediaQuery} from "@mui/system";
import AddDamageType from "./components/create/AddDamageType";
import MainPage from "./components/build_planner/MainPage";
import AddDeviation from "./components/create/AddDeviation";
import NewsPage from "./components/build_planner/NewsPage";
import BuildsByUser from "./components/build_planner/BuildsByUser";
import Codex from "./components/build_planner/Codex";
import ItemDetail from "./components/build_planner/ItemDetail";
import SidebarDrawer from "./components/util/SidebarDrawer";
import AdminMenu from "./components/auth/AdminMenu";
import MapWithPins from "./components/tradecenter/MapWithPins";
import AddResource from "./components/create/AddResource";
import AddVendor from "./components/tradecenter/AddVendor";
import AddListing from "./components/tradecenter/AddListing";
import AddSecondaryStat from "./components/create/AddSecondaryStat";
import LatestListings from "./components/tradecenter/LatestListings";
import TradeCenter from "./components/tradecenter/TradeCenter";
import ViewListing from "./components/tradecenter/ViewListing";
import RequireAuth from "./components/auth/RequireAuth";
import MyListings from "./components/tradecenter/MyListings";
import RequireAuthLogInWarning from "./components/auth/RequireAuthLogInWarning";

function App() {
    const { sessionData, setSessionData } = useSessionData();
    const [data, setData ] = useState({});
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        getSessionData();
        getAllData();
    }, []);

    useEffect(() => {
        if (sessionData.logged && sessionData.nickname === null && !window.location.pathname.includes('/createNickname')) {
            navigate('/createNickname');
        }
    }, [sessionData, navigate]);

    async function getSessionData() {
        try {
            const result = await axios.get('/api/user/session');
            if (result.data !== false) {
                setSessionData({
                    ...sessionData,
                    userId: Number(result.data.user_id),
                    rights: Number(result.data.rights),
                    isLogged: result.data.user_id !== 0,
                    username: result.data.username,
                    nickname: result.data.nickname,
                    email: result.data.email,
                    isUserAdmin: result.data.rights === 5,
                    updated: true,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getAllData() {
        try {
            const result = await axios.get('/api/get/allData');
            if (result.data !== false) {
                setData(result.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="App">
            <CssBaseline />
            <Header />
            <Grid container sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <SidebarDrawer isSmallScreen={isSmallScreen} loading={loading} />
                <Grid item xs={10}>
                    <Routes>
                        <Route index path="/" element={<MainPage data={data} isSmallScreen={isSmallScreen} />} />
                        <Route path="/builds" element={<BuildList data={data} isSmallScreen={isSmallScreen} />} />
                        <Route path="/buildPlanner" element={<BuildPlanner data={data} />} />
                        <Route path="/builds/user/:id" element={<BuildsByUser data={data} />} />
                        <Route path="/builds/edit/:id" element={<BuildPlanner data={data} />} />
                        <Route path="/builds/view/:id" element={<ViewBuild data={data} />} />
                        <Route path="/builds/:username/:filename" element={<ViewBuild data={data} />} />
                        <Route path="/news" element={<NewsPage data={data} isSmallScreen={isSmallScreen} />} />
                        <Route path="/codex" element={<Codex data={data} />} />
                        <Route path="/view/:type/:id" element={<ItemDetail data={data} /> } />
                        <Route path="/createNickname" element={<CreateNickname userNicks={data.userNicks} />} />
                        <Route path="/tradeCenter" element={<TradeCenter />} />

                        {/*Require auth*/}
                        <Route path="/trade/listing/:id" element={<ViewListing />} />
                        <Route path="/addListing" element={<AddListing />} />
                        <Route path="/trade/edit/:id" element={<AddListing />} />
                        <Route path="/addVendor" element={<AddVendor />} />
                        <Route path="/trade/listings" element={<MyListings />} />
                        </Routes>
                    <RequireAdmin>
                        <SimpleBox>
                            <Routes>
                                <Route path="/adminMenu" element={<AdminMenu  />} />
                                <Route path="/addLocation" element={<AddLocation />} />
                                <Route path="/addSet" element={<AddSet />} />
                                <Route path="/addGear" element={<AddGear />} />
                                <Route path="/addMod" element={<AddMod />} />
                                <Route path="/addCradle" element={<AddCradle data={data} />} />
                                <Route path="/addCalibration" element={<AddCalibration />} />
                                <Route path="/addBuildType" element={<AddBuildType />} />
                                <Route path="/addAnomaly" element={<AddAnomalies />} />
                                <Route path="/addServerDifficulty" element={<AddServerDifficulty />} />
                                <Route path="/addStat" element={<AddStat />} />
                                <Route path="/addDamageType" element={<AddDamageType />} />
                                <Route path="/addDeviation" element={<AddDeviation />} />
                                <Route path="/addResource" element={<AddResource />} />
                                <Route path="/addSecondaryStat" element={<AddSecondaryStat />} />
                            </Routes>
                        </SimpleBox>
                    </RequireAdmin>
                </Grid>
            </Grid>

            {/* FOOTER */}
            <Grid item xs={12}>
                <Typography variant="body2" sx={{
                    fontWeight: 'bold',
                    display: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5
                }}>
                    Meta-Builds was created using content and materials from Once Human, NetEase®, Inc or its licensors. This site is not affiliated with Netease, and Netease is not responsible for any of the content on, or the privacy policy of this site.
                </Typography>
                <Typography variant="body2" sx={{
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    m: 2
                }}>
                    Meta-Builds is a fan created site for Once Human | <a href="/privacy_policy.html" class="plain-link">Privacy Policy</a> | <a href="/terms_of_use.html" class="plain-link">Terms of Use</a> | © 2024 Meta-Builds.net
                </Typography>
            </Grid>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </div>
    );
}

export default App;
