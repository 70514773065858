import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
    FormControl, MenuItem,
    Select,
    Tooltip, Typography,
} from "@mui/material";
import axios from "axios";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import ContentBox from "../util/ContentBox";
import SimpleBox from "../util/SimpleBox";
import {useMediaQuery} from "@mui/system";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import PercentageStar from "../util/PercentageStar";
import DarkTooltip from "../util/DarkTooltip";

export default function TopRatedBuilds({ data }) {
    const [sortedBuilds, setSortedBuilds] = useState([]);
    const [buildTypes, setBuildTypes] = useState({});
    const [damageTypes, setDamageTypes] = useState({});

    useEffect(() => {
        if (data.buildtypes) {
            const buildty = data.buildtypes.reduce((acc, type) => {
                acc[type.id] = type.title;
                return acc;
            }, {});
            setBuildTypes(buildty);

            const damagety = data.damagetypes.reduce((acc, type) => {
                acc[type.id] = type.title;
                return acc;
            }, {});
            setDamageTypes(damagety);
        }

        getBuilds();
    }, [data]);

    async function getBuilds() {
        try {
            const result = await axios.get('/api/get/buildsTopRated');
            if (result.data !== false) {
                setSortedBuilds(result.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <SimpleBox>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        fontVariant: 'small-caps',
                                        textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                    }}
                                >
                                    Top Rated builds
                                    <DarkTooltip tooltipText={'Logged in users can rate builds!'}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mr: 1 }}>
                                <Tooltip title={`Find more`}>
                                    <IconButton to={"/builds"} component={Link} color="info">
                                        <SearchIcon  />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Divider sx={{ border: '1px solid white', m: 1 }} />
                    </Grid>
                    {sortedBuilds.length > 0 ? (
                        sortedBuilds.map((build) => (
                            <Grid item xs={12} key={build.id} sx={{ mb: 1 }}>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={8}>
                                        <Grid container alignItems="center" sx={{ml: 1}}>
                                            <Tooltip title={buildTypes[build.build_type]} arrow>
                                                <img
                                                    src={`/images/buildtypes/${build.build_type}.webp`}
                                                    alt={build.title}
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        border: '2px solid black',
                                                        marginRight: '2px',
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title={damageTypes[build.damage_type]} arrow>
                                                <img
                                                    src={`/images/damagetypes/${build.damage_type}.webp`}
                                                    alt={build.title}
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        border: '2px solid black',
                                                        marginRight: '2px',
                                                    }}
                                                />
                                            </Tooltip>
                                            <Button
                                            component={Link}
                                            to={`${build.address}`}
                                            color="primary"
                                            sx={{
                                                textTransform: 'none',
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                flexGrow: 1,
                                            }}
                                            >
                                            {build.title}
                                            </Button>
                                            <PercentageStar rating={build.vote_score}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mr: 1 }}>
                                        <img
                                            src={`/images/gears/${build.main_weapon}.webp`}
                                            alt={build.title}
                                            style={{
                                                width: '100px',
                                                height: '50px',
                                                border: '2px solid black',
                                                marginRight: '2px'
                                            }}
                                        />
                                        <img
                                            src={`/images/gears/${build.secondary_weapon}.webp`}
                                            alt={build.title}
                                            style={{width: '100px', height: '50px', border: '2px solid black', marginRight: '2px'}}
                                        />
                                        <img
                                            src={`/images/deviations/${build.deviation}.webp`}
                                            alt={build.title}
                                            style={{width: '50px', height: '50px', border: '2px solid black'}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No builds found
                        </Grid>
                    )}
                </Grid>
            </SimpleBox>
        </>
    );
}
