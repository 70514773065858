import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";
import ImageUpload from "../util/ImageUpload";

export default function AddDamageType() {
    const [inputs, setInputs] = useState({});

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        setImageUrl(inputs.image instanceof Blob ? URL.createObjectURL(inputs.image) : inputs.image);
    }, [inputs]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('image', imageFile);
        axios.post('/api/create/damageType', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        title: ''
                    }));
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Damage Type</h3>
                    </InputLabel>
                    <Grid sx={{mb: 2}}>
                        <ImageUpload data={inputs} setData={setInputs} setImageFile={setImageFile}/>
                        {imageUrl && (
                            <img src={imageUrl} alt="main"/>
                        )}
                    </Grid>
                    <Grid>
                        <TextField
                            fullWidth
                            id="title"
                            label="Location"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
            </Container>
        </>
    );
}
