import {useSessionData} from "./SessionDataProvider";
import SimpleBox from "../util/SimpleBox";
import Typography from "@mui/material/Typography";
import React from "react";

function RequireAuthLogInWarning({ children, passThrough = false })
{
    const { sessionData } = useSessionData();

    if (!sessionData.updated) {
        return null;
    }

    if ((sessionData.updated && sessionData.isLogged) || passThrough) {
        return children;
    }

    if (!sessionData.isLogged && sessionData.updated) {
        return (
            <SimpleBox sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', p: 5}}>
                <Typography variant="h3" fontWeight="bold">
                    You need to log in to view this page!
                </Typography>
            </SimpleBox>
        );
    }
}

export default RequireAuthLogInWarning
