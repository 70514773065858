import React, { useState, useEffect } from 'react';
import { TextField, Pagination, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import SimpleBox from "../util/SimpleBox";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ShowTitle from "../util/ShowTitle";
import RequireAdmin from "../auth/RequireAdmin";
import axios from "axios";

const Codex = ({ data }) => {
    const [gears, setGears] = useState([]);
    const [mods, setMods] = useState([]);
    const [calibrations, setCalibrations] = useState([]);
    const [serverDifficulty, setServerDifficulty] = useState([]);
    const [anomalies, setAnomalies] = useState([]);
    const [buildTypes, setBuildTypes] = useState([]);
    const [cradles, setCradles] = useState([]);
    const [stats, setStats] = useState([]);
    const [locations, setLocations] = useState({});
    const [sets, setGeasets] = useState([]);
    const [damageTypes, setDamageTypes] = useState([]);
    const [deviations, setDeviations] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]); // To store filtered items
    const [searchTerm, setSearchTerm] = useState(''); // For the search field
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const [selectedCategory, setSelectedCategory] = useState(''); // To store the selected category
    const itemsPerPage = 20;

    useEffect(() => {
        if (data) {
            const filters = {
                gears: data.gears?.filter(gear => gear.category >= 0) || [],
                mods: data.mods?.filter(mod => mod.category >= 0) || [],
                cradles: data.cradles?.filter(cradle => cradle.id >= 0) || [],
                deviations: data.deviations?.filter(location => location.id >= 0) || []
            };

            setGears(filters.gears);
            setMods(filters.mods);
            setCalibrations(filters.calibrations);
            setCradles(filters.cradles);
            setDeviations(filters.deviations);
        }
    }, [data]);

    // Combine all items for filtering with type info
    const allItems = [
        ...gears.map(item => ({ ...item, type: 'gears' })),
        ...mods.map(item => ({ ...item, type: 'mods' })),
        ...cradles.map(item => ({ ...item, type: 'cradles' })),
        ...deviations.map(item => ({ ...item, type: 'deviations' }))
    ];

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to the first page when search term changes
    };

    // Handle category change
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset to the first page when category changes
    };

    // Filter items based on search term and selected category
    const filteredItemsToShow = allItems.filter(item => {
        const matchesSearch = item.title?.toLowerCase().trim().includes(searchTerm.toLowerCase().trim());
        const matchesCategory = selectedCategory ? item.type === selectedCategory : true;
        return matchesSearch && matchesCategory;
    });

    // Pagination logic
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    function sanitizeInput(data) {
        // Trim unnecessary spaces, tabs, newlines
        data = data.trim();

        // Remove backslashes
        data = data.replace(/\\/g, '');

        // Convert special characters to HTML entities
        data = data.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');

        return data;
    }

// Function to convert item.title to folder name format
    function folderName(newTitle) {
        // Convert to lowercase
        let title = newTitle.toLowerCase();

        // Replace spaces with underscores
        title = title.replace(/ /g, '_');

        // Remove any character that is not a-z, 0-9, or underscore
        title = title.replace(/[^a-z0-9_]/g, '');

        return title;
    }

    const generateUrls = () => {
        const baseUrl = 'https://meta-builds.net/view/';
        const urls = allItems.map(item => {
            const sanitizedTitle = sanitizeInput(item.title);
            const formattedTitle = folderName(sanitizedTitle);
            return `${baseUrl}${item.type}/${item.id}_${formattedTitle}`;
        });

        console.log(urls);

        const formData = new FormData();
        formData.append('base_urls', urls);
        axios.post('/api/create/sitemap', formData)
            .then(function (response) {
                console.log(response);
            });
    };

    const itemsToShow = filteredItemsToShow.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SimpleBox>
            <ShowTitle
                noShow={true}
                title="Codex - Search everything in game"
                description="Explore the Once Human Codex for comprehensive information on all in-game items. Find detailed guides, stats, and descriptions."
                keywords="Once Human codex, item guides, game item database, Once Human items, item stats, game guides"
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>Search for an Item, Mod, Deviation, Anything</h3>
                    <RequireAdmin>
                        <button onClick={() => generateUrls()}>Generate URLs</button>
                    </RequireAdmin>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Search anything"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="category-select-label">Filter by Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            label="Filter by Category"
                        >
                            <MenuItem value=""><em>All Categories</em></MenuItem>
                            <MenuItem value="gears">Gear</MenuItem>
                            <MenuItem value="mods">Mod</MenuItem>
                            <MenuItem value="cradles">Cradle</MenuItem>
                            <MenuItem value="deviations">Deviation</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {filteredItemsToShow.length > 0 && (
                            <>
                                {itemsToShow.map(item => {
                                    // Define cellWidth based on item type
                                    const cellWidth = item.type === 'gears' ? '100px' : '50px';

                                    return (
                                        <Grid item xs={12} key={item.id} >
                                            <Button to={`/view/${item.type}/${item.id}`} component={Link} fullWidth sx={{ alignItems: 'center', justifyContent: 'flex-start'}}>
                                                <img
                                                    src={`/images/${item.type}/${item.id}.webp`}
                                                    alt={item.title}
                                                    style={{ width: cellWidth, height: '50px', border: '2px solid black', marginRight: '10px' }}
                                                />
                                                {item.title}
                                            </Button>
                                            <Divider/>
                                        </Grid>
                                    );
                                })}
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {filteredItemsToShow.length > itemsPerPage && (
                        <Pagination
                            count={Math.ceil(filteredItemsToShow.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    )}
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default Codex;
