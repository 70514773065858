import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SessionDataProvider } from "./components/auth/SessionDataProvider";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from "react-router-dom";

// Define MUI dark theme
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
    },
});

const randomImageNumber = Math.floor(Math.random() * 6) + 1;
const randomBackgroundImage = `/images/wallpapers/${randomImageNumber}.jpg`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <SessionDataProvider>
            <ThemeProvider theme={darkTheme}>
                <BrowserRouter>
                    <CssBaseline />
                    <div style={{
                        width: '100%',
                        backgroundImage: `url(${randomBackgroundImage})`, // Use random image
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: 'fixed',
                        backgroundColor: 'rgba(33, 33, 33, 0.7)',
                    }}>
                        <App />
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </SessionDataProvider>
    </React.StrictMode>
);

reportWebVitals();
