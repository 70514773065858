import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";
import {MenuItem, Select, TextareaAutosize} from "@mui/material";
import {Editor} from "@tinymce/tinymce-react";
import ImageUpload from "../util/ImageUpload";

export default function AddDeviation() {
    const [inputs, setInputs] = useState([]);
    const [sets, setSets] = useState([]);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        setImageUrl(inputs.image instanceof Blob ? URL.createObjectURL(inputs.image) : inputs.image);
    }, [inputs]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('image', imageFile);
        axios.post('/api/create/deviation', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        title: '',
                        description: '',
                        mood_booster: '',
                        source: '',
                    }));
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Deviation</h3>
                    </InputLabel>
                    <Grid sx={{mb: 2}}>
                        <ImageUpload data={inputs} setData={setInputs} setImageFile={setImageFile} cropHeight={150} cropWidth={150}/>
                        {imageUrl && (
                        <img src={imageUrl} alt="main"/>
                            )}
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <TextField
                            fullWidth
                            id="title"
                            label="Name"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <div>
                            <h3>Description</h3>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                value={inputs.description}
                                onEditorChange={(newValue, editor) => {
                                    setInputs(values => ({...values, description: newValue}));
                                }}
                                init={{
                                    height: 250,
                                    menubar: 'file edit view insert format tools table help',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                                        'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                                        'accordion'
                                    ],
                                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                                    image_advtab: true,
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_class: 'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    contextmenu: 'link image table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <h3>Mood booster</h3>
                        <div>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                value={inputs.mood_booster}
                                onEditorChange={(newValue, editor) => {
                                    setInputs(values => ({...values, mood_booster: newValue}));
                                }}
                                init={{
                                    height: 250,
                                    menubar: 'file edit view insert format tools table help',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                                        'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                                        'accordion'
                                    ],
                                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                                    image_advtab: true,
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_class: 'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    contextmenu: 'link image table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <h3>Source</h3>
                        <div>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                value={inputs.source}
                                onEditorChange={(newValue, editor) => {
                                    setInputs(values => ({...values, source: newValue}));
                                }}
                                init={{
                                    height: 250,
                                    menubar: 'file edit view insert format tools table help',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                                        'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                                        'accordion'
                                    ],
                                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                                    image_advtab: true,
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_class: 'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    contextmenu: 'link image table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <InputLabel id="category-label" sx={{ mt: 3 }}>Kategória</InputLabel>
                        <Select
                            id="category"
                            name="category"
                            labelId="category-label"
                            value={inputs.category}
                            onChange={handleChange}
                            fullWidth
                            label="Kategória"
                            defaultValue={0}
                        >
                            <MenuItem key={1} value={1}> Attack </MenuItem>
                            <MenuItem key={2} value={2}> Defense </MenuItem>
                            <MenuItem key={3} value={3}> Territory </MenuItem>
                            <MenuItem key={4} value={4}> Crafting </MenuItem>
                        </Select>
                    </Grid>

                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity}/>
            </Container>
        </>
    );
}
