import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import { useSessionData } from "../auth/SessionDataProvider";
import ItemDisplay from "./ItemDisplay";
import SimpleBox from "../util/SimpleBox";
import ContentBox from "../util/ContentBox";
import {Button, Divider, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModGridDisplay from "./ModGridDisplay";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RateBuild from "../util/RateBuild";
import DisplayRating from "../util/DisplayRating";
import ShowTitle from "../util/ShowTitle";

export default function ViewBuild({ data }) {
    const { sessionData } = useSessionData();
    const { id, username, filename } = useParams();
    const [inputs, setInputs] = useState({});
    const [buildData, setBuildData] = useState({});
    const [gears, setGears] = useState([]);
    const [weaponOne, setWeaponOne] = useState(null);
    const [mods, setMods] = useState([]);
    const [serverDifficulty, setServerDifficulty] = useState([]);
    const [calibrations, setCalibrations] = useState([]);
    const [anomalies, setAnomalies] = useState([]);
    const [buildTypes, setBuildTypes] = useState([]);
    const [cradles, setCradles] = useState([]);
    const [stats, setStats] = useState([]);
    const [locations, setLocations] = useState([]);
    const [gearsets, setGeasets] = useState([]);
    const [linkedSetCounts, setLinkedSetCounts] = useState({});
    const [statNames, setStatNames] = useState({});
    const [usernames, setUsernames] = useState({});
    const [damageTypes, setDamageTypes] = useState({});
    const [deviations, setDeviations] = useState({});

    const extractBuildId = (filename) => {
        const match = filename.match(/^(\d+)_/); // Match the number at the beginning of the filename
        return match ? match[1] : null;
    };

    const buildId = id || extractBuildId(filename);

    const decodeHtml = (html) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    // Fetch build data based on id
    useEffect(() => {
        if (buildId) {
            axios.get(`/api/get/build/${buildId}`)
                .then(result => {
                    if (result.data !== false && Array.isArray(result.data)) {
                        setBuildData(result.data[0]);
                        setInputs(result.data[0].settings || {});
                    } else {
                        console.error('Unexpected result data format:', result.data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching build data:', error);
                });
        }
    }, [buildId]);

    // Update local state with data from props
    useEffect(() => {
        if (data) {
            setGears(Array.isArray(data.gears) ? data.gears.filter(gear => gear.category >= 0) : []);
            setMods(Array.isArray(data.mods) ? data.mods.filter(mod => mod.category >= 0) : []);
            setCalibrations(Array.isArray(data.calibrations) ? data.calibrations.filter(calibration => calibration.id >= 0) : []);
            setServerDifficulty(Array.isArray(data.serverdifficulty) ? data.serverdifficulty.filter(difficulty => difficulty.id >= 0) : []);
            setAnomalies(Array.isArray(data.anomalies) ? data.anomalies.filter(anomaly => anomaly.id >= 0) : []);
            setBuildTypes(Array.isArray(data.buildtypes) ? data.buildtypes.filter(buildType => buildType.id >= 0) : []);
            setCradles(Array.isArray(data.cradles) ? data.cradles.filter(cradle => cradle.id >= 0) : []);
            setStats(Array.isArray(data.stats) ? data.stats.filter(stat => stat.id >= 0) : []);
            setLocations(Array.isArray(data.locations) ? data.locations.reduce((map, location) => {
                map[location.id] = location;
                return map;
            }, {}) : []);
            setGeasets(Array.isArray(data.sets) ? data.sets.filter(location => location.id >= 0) : []);
            setUsernames(Array.isArray(data.userNicks) ? data.userNicks.filter(location => location.id >= 0) : []);
            setDamageTypes(Array.isArray(data.damagetypes) ? data.damagetypes.filter(location => location.id >= 0) : []);
            setDeviations(Array.isArray(data.deviations) ? data.deviations.filter(location => location.id >= 0) : []);
        }
    }, [data]);

    // Update weaponOne when inputs or gears change
    useEffect(() => {
        if ((Array.isArray(gears) && Array.isArray(mods) && Array.isArray(calibrations) && Array.isArray(cradles) &&
            Array.isArray(buildTypes) && Array.isArray(damageTypes) && Array.isArray(serverDifficulty) && Array.isArray(usernames))) {

            const anomalyIds = inputs.anomalies ? inputs.anomalies.split(',').map(Number) : [];

            setBuildData((prevInputs) => ({
                ...prevInputs,
                weaponOne: gears.find(item => item.id === inputs.weapon_one),
                weaponOneMod: mods.find(item => item.id === inputs.weapon_one_mod),
                weaponOneCalibration: calibrations.find(item => item.id === inputs.weapon_one_calibration),
                weaponTwo: gears.find(item => item.id === inputs.weapon_two),
                weaponTwoMod: mods.find(item => item.id === inputs.weapon_two_mod),
                weaponTwoCalibration: calibrations.find(item => item.id === inputs.weapon_two_calibration),
                weaponThree: gears.find(item => item.id === inputs.weapon_three),
                weaponThreeMod: mods.find(item => item.id === inputs.weapon_three_mod),
                weaponThreeCalibration: calibrations.find(item => item.id === inputs.weapon_three_calibration),
                selectedDeviation: deviations.find(item => item.id === Number(buildData.deviation)),
                cradleOne: cradles.find(item => item.id === inputs.cradle_one),
                cradleTwo: cradles.find(item => item.id === inputs.cradle_two),
                cradleThree: cradles.find(item => item.id === inputs.cradle_three),
                cradleFour: cradles.find(item => item.id === inputs.cradle_four),
                cradleFive: cradles.find(item => item.id === inputs.cradle_five),
                cradleSix: cradles.find(item => item.id === inputs.cradle_six),
                cradleSeven: cradles.find(item => item.id === inputs.cradle_seven),
                cradleEight: cradles.find(item => item.id === inputs.cradle_eight),
                glove: gears.find(item => item.id === inputs.glove),
                gloveMod: mods.find(item => item.id === inputs.glove_mod),
                mask: gears.find(item => item.id === inputs.mask),
                maskMod: mods.find(item => item.id === inputs.mask_mod),
                helmet: gears.find(item => item.id === inputs.helmet),
                helmetMod: mods.find(item => item.id === inputs.helmet_mod),
                shoe: gears.find(item => item.id === inputs.shoe),
                shoeMod: mods.find(item => item.id === inputs.shoe_mod),
                top: gears.find(item => item.id === inputs.top),
                topMod: mods.find(item => item.id === inputs.top_mod),
                bottom: gears.find(item => item.id === inputs.bottom),
                bottomMod: mods.find(item => item.id === inputs.bottom_mod),
                buildType: buildTypes.find(item => item.id === inputs.build_type),
                damageType: damageTypes.find(item => item.id === buildData.damage_type),
                serverDifficulty: serverDifficulty.find(item => item.id === inputs.server_difficulty),
                filteredAnomalies: anomalies.filter(anomaly => anomalyIds.includes(anomaly.id)),
                owner: usernames.find(item => item.id === buildData.owner_id),
                serverType: inputs.server_type === 0 ? 'PvE' : 'PvP',
            }));
        }
    }, [inputs, gears]);

    useEffect(() => {
        if (buildData) {
            const getStatNames = () => {
                const statIds = [
                    buildData.settings?.stat_one,
                    buildData.settings?.stat_two,
                    buildData.settings?.stat_three,
                    buildData.settings?.stat_four,
                    buildData.settings?.stat_five,
                    buildData.settings?.stat_six,
                    buildData.settings?.stat_seven,
                    buildData.settings?.stat_eight
                ];

                // Map stat IDs to their names
                const names = stats.reduce((acc, stat) => {
                    acc[stat.id] = stat.title;
                    return acc;
                }, {});

                // Create an array of stat names based on the IDs in buildData
                const statNamesArray = statIds.map(id => names[id] || 'Unknown');
                setStatNames(statNamesArray);
            };

            const countLinkedSets = () => {
                const linkedSets = [
                    buildData.helmet?.linked_set,
                    buildData.glove?.linked_set,
                    buildData.mask?.linked_set,
                    buildData.shoe?.linked_set,
                    buildData.top?.linked_set,
                    buildData.bottom?.linked_set,
                    // Add more items if needed
                ];

                // Count occurrences of each linked_set ID
                const counts = linkedSets.reduce((acc, setId) => {
                    if (setId) {
                        acc[setId] = (acc[setId] || 0) + 1;
                    }
                    return acc;
                }, {});

                setLinkedSetCounts(counts);
            };

            countLinkedSets();
            getStatNames();
        }
        console.log("buildData: ", buildData);
    }, [buildData]);

    const getBonuses = (setId) => {
        if (parseInt(setId) === 1) {
            return null;
        }

        const set = gearsets.find(g => g.id === parseInt(setId));
        if (!set) return { name: 'Unknown Set', bonuses: [] };

        const bonuses = [
            set.bonus_one,
            set.bonus_two,
            set.bonus_three,
            set.bonus_four
        ];

        return {
            name: set.title,
            bonuses
        };
    };

    const handleCopyClick = () => {
        if (buildData.address) {
            navigator.clipboard.writeText(buildData.address).then(() => {
            }).catch((error) => {
                console.error('Failed to copy: ', error);
            });
        }
    };

    if (!buildData.weaponOne) {
        return null;
    }

    return (
        <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mb: 1 }}>
                {/* Title */}
                    <ContentBox sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={1} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}} >
                                <Typography variant="h5"
                                            sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center', fontVariant: 'small-caps'}}>
                                    <img
                                        src={`/images/buildtypes/${buildData.build_type}.webp`}
                                        alt={buildData.title}
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            border: '2px solid black',
                                            marginRight: '8px',
                                        }}
                                    />
                                    <img
                                        src={`/images/damagetypes/${buildData.damage_type}.webp`}
                                        alt={buildData.title}
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            border: '2px solid black',
                                            marginRight: '8px',
                                        }}
                                    />
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg={7}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center' // Align items vertically centered
                                }}
                            >
                                <ShowTitle
                                    title={buildData.title}
                                    item={buildData}
                                    sx={{ display: 'flex', alignItems: 'center' }} // Ensure ShowTitle's content is also vertically centered
                                />
                                {buildData.anonymous === 0 && (
                                    <Typography
                                        variant="h5"
                                        sx={{ fontStyle: 'italic', ml: 1, alignSelf: 'center' }} // Ensure it aligns in the center
                                    >
                                        | by {buildData.owner.nickname}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={4} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <DisplayRating rating={buildData.vote_score} votes={buildData.vote_count}/>
                                {sessionData.isLogged && sessionData.userId === buildData.owner_id && (
                                    <Button component={Link} to={`/builds/edit/${buildData.id}`} variant="contained" sx={{ ml: 2, color: 'white', mr: 2, fontWeight: 'bold', backgroundColor: '#1D1D1DE5' }}>
                                        <EditIcon sx={{ mr: 2 }} /> Edit build
                                    </Button>
                                )}
                                <Tooltip title={`Copy build address`}>
                                    <IconButton onClick={handleCopyClick}>
                                        <ContentCopyIcon sx={{color: '#000'}}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ContentBox>
                </Grid>
            <Grid item xs={12} lg={8} sx={{ mb: 1 }}>
                <Grid container spacing={1} >

                {/* Loadout */}
                    <Grid item xs={12}>
                        <SimpleBox sx={{mb: 2}}>
                            <Grid container  sx={{ pt: 1 }}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Grid container >
                                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between'}}>
                                            <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                                Loadout
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Divider sx={{ bgcolor: 'white' }}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            {/* ************ Weapons *********** */}
                                <Grid item xs={12} xl={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1}}>
                                            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between'}}>
                                                <Grid item xs={12} md={6}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponOne"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponOneMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponOneCalibration"
                                                        width="100px"
                                                        height="100px"
                                                        folder="calibrations"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
                                            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between'}}>
                                                <Grid item xs={12} md={6}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponTwo"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponTwoMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponTwoCalibration"
                                                        width="100px"
                                                        height="100px"
                                                        folder="calibrations"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1, mb: 2 }}>
                                            <Grid container sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between'}}>
                                                <Grid item xs={12} md={6}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponThree"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponThreeMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="weaponThreeCalibration"
                                                        width="100px"
                                                        height="100px"
                                                        folder="calibrations"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* ************ CRADLE *********** */}
                                <Grid item xs={12} md={12} xl={6} >
                                    <Grid container  >
                                        <Grid item xs={12} md={12} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="selectedDeviation"
                                                width="130px"
                                                height="130px"
                                                folder="deviations"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleOne"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleTwo"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleThree"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleFour"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleFive"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleSix"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleSeven"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} sx={{ justifyContent: 'center', mb: 1 }}>
                                            <ItemDisplay
                                                buildData={buildData}
                                                itemName="cradleEight"
                                                width="100px"
                                                height="100px"
                                                folder="cradles"
                                                rounded={true}
                                                rarity={6}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* ************ GEAR *********** */}
                                <Grid item xs={12}>
                                    <Grid container spacing={1} sx={{ justifyContent: 'space-between', alignContent: 'center' }}>
                                        {/* Helmet and Helmet Mod */}
                                        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Grid container sx={{ justifyContent: 'center', alignContent: 'center'}}>
                                                <Grid item xs={8}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="helmet"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="helmetMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Mask and Mask Mod */}
                                        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Grid container sx={{ justifyContent: 'center', alignContent: 'center'}}>
                                                <Grid item xs={8}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="mask"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="maskMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Glove and Glove Mod */}
                                        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Grid container sx={{ justifyContent: 'center', alignContent: 'center'}}>
                                                <Grid item xs={8}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="glove"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="gloveMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Shoe and Shoe Mod */}
                                        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Grid container sx={{ justifyContent: 'center', alignContent: 'center'}}>
                                                <Grid item xs={8}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="shoe"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="shoeMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Top and Top Mod */}
                                        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Grid container sx={{ justifyContent: 'center', alignContent: 'center'}}>
                                                <Grid item xs={8}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="top"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="topMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Bottom and Bottom Mod */}
                                        <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Grid container sx={{ justifyContent: 'center', alignContent: 'center'}}>
                                                <Grid item xs={8}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="bottom"
                                                        width="200px"
                                                        height="100px"
                                                        folder="gears"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ItemDisplay
                                                        buildData={buildData}
                                                        itemName="bottomMod"
                                                        width="100px"
                                                        height="100px"
                                                        folder="mods"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SimpleBox>
                    </Grid>

                {/* Build Summary Box*/}
                    <Grid item xs={12}>
                        <SimpleBox sx={{mb: 2}}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                        Build Summary
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'white' }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2}}>
                                        <Grid item xs={1}>
                                            <Typography variant="h6">
                                                Headline:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                                {buildData.buildType.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                                {buildData.damageType.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="h6" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                                {buildData.serverType}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                            <Typography variant="h6">
                                                {buildData.serverDifficulty.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            {buildData.filteredAnomalies && buildData.filteredAnomalies.length > 0 ? (
                                                buildData.filteredAnomalies.map((anomaly, index) => (
                                                    <Typography variant="h6" key={index} sx={{ ml: 2 }}>
                                                        {anomaly.title}
                                                    </Typography>
                                                ))
                                            ) : (
                                                <Typography variant="h6" sx={{ ml: 2 }}>
                                                    No anomalies
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SimpleBox>
                    </Grid>

                {/* Weapon Bonuses */}
                    <Grid item xs={12}>
                        <SimpleBox sx={{mb: 2}}>
                            <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignContent: 'center', p: 1 }} >
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                        Weapon Bonuses
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'white', mb: 2 }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Grid item xs={4} sx={{fontWeight: 'bold'}}>
                                            {buildData.weaponOne.title}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div dangerouslySetInnerHTML={{__html: buildData.weaponOne.description}}/>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{bgcolor: 'white' }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Grid item xs={4} sx={{fontWeight: 'bold'}}>
                                            {buildData.weaponTwo.title}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div dangerouslySetInnerHTML={{__html: buildData.weaponTwo.description}}/>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{bgcolor: 'white' }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Grid item xs={4} sx={{fontWeight: 'bold'}}>
                                            {buildData.weaponThree.title}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div dangerouslySetInnerHTML={{__html: buildData.weaponThree.description}}/>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{bgcolor: 'white' }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Grid item xs={4} sx={{fontWeight: 'bold'}}>
                                            {buildData.selectedDeviation.title}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div dangerouslySetInnerHTML={{__html: buildData.selectedDeviation.description}}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div dangerouslySetInnerHTML={{__html: buildData.selectedDeviation.source}}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SimpleBox>
                    </Grid>

                {/*   Mods and Bonuses */}
                    <Grid item xs={12}>
                        <SimpleBox sx={{mb: 2}}>
                            <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignContent: 'center', p: 1 }} >
                                <Grid item xs={12}>
                                    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', p: 2 }} >
                                        <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                            Mods
                                        </Typography>
                                        <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                            Bonuses
                                        </Typography>
                                        <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                            Locations
                                        </Typography>
                                    </Grid>
                                    <Divider sx={{ bgcolor: 'white' }}/>
                                </Grid>

                                <ModGridDisplay modData={buildData.weaponOneMod} locations={locations} />
                                <ModGridDisplay modData={buildData.weaponTwoMod} locations={locations} />
                                <ModGridDisplay modData={buildData.weaponThreeMod} locations={locations} />
                                <ModGridDisplay modData={buildData.helmetMod} locations={locations} />
                                <ModGridDisplay modData={buildData.maskMod} locations={locations} />
                                <ModGridDisplay modData={buildData.gloveMod} locations={locations} />
                                <ModGridDisplay modData={buildData.shoeMod} locations={locations} />
                                <ModGridDisplay modData={buildData.topMod} locations={locations} />
                                <ModGridDisplay modData={buildData.bottomMod} locations={locations} />

                            </Grid>
                        </SimpleBox>
                    </Grid>
                </Grid>
            </Grid>

            {/* Sidebar */}

            <Grid item xs={12} lg={4}>

                <Grid item xs={12}>
                    <Grid container>

                        {/* RATING */}
                        {sessionData.userId !== 0 && !data.votedbuilds.includes(buildData.id) && (
                            <Grid item xs={12}>
                                <SimpleBox sx={{mb: 2}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                                Rate this build!
                                            </Typography>
                                            <Divider sx={{ bgcolor: 'white', mb: 2 }}/>
                                            <RateBuild buildId={buildData.id}/>
                                        </Grid>
                                    </Grid>
                                </SimpleBox>
                            </Grid>
                        )}

                        {/* Description */}
                        {inputs.description && (
                            <Grid item xs={12}>
                                <SimpleBox sx={{mb: 2}}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                                Description
                                            </Typography>
                                            <Divider sx={{ bgcolor: 'white' }}/>
                                            <Typography
                                                style={{ color: '#ffffff', padding: 3 }}
                                                dangerouslySetInnerHTML={{ __html: decodeHtml(inputs.description) }} // Decoding the HTML entities
                                            />
                                        </Grid>
                                    </Grid>
                                </SimpleBox>
                            </Grid>
                        )}

                        { inputs.youtube_link && inputs.youtube_link !== "" && (
                            <Grid item xs={12}>
                                <SimpleBox sx={{mb: 2}}>
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${inputs.youtube_link.split('v=')[1]}`}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </SimpleBox>
                            </Grid>
                        )}

                        {/* Linked sets */}

                        <Grid item xs={12}>
                            <SimpleBox sx={{ mb: 2 }}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                            Set Bonuses
                                        </Typography>
                                        <Divider sx={{ bgcolor: 'white', mb: 2}}/>
                                        <Typography variant="body2" sx={{fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                             Detailed stats and calculations coming soon <InfoOutlinedIcon color="info" sx={{ ml: 2 }}/>
                                        </Typography>

                                        {Object.entries(linkedSetCounts)
                                            .filter(([setId]) => parseInt(setId) !== 1)  // Exclude setId 1
                                            .map(([setId, count]) => {
                                                const { name, bonuses } = getBonuses(setId);

                                                return (
                                                    <div key={setId}>
                                                        <strong>{name} - {count} Piece</strong>
                                                        <ol>
                                                            {bonuses.map((bonus, index) => {
                                                                // Determine if the bonus should be greyed out
                                                                const isReached = count >= index + 1;
                                                                return (
                                                                    <li
                                                                        key={index}
                                                                        style={{
                                                                            color: isReached ? 'white' : 'grey',
                                                                        }}
                                                                    >
                                                                        {bonus}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ol>
                                                    </div>
                                                );
                                            })}

                                    </Grid>
                                </Grid>
                            </SimpleBox>
                            <SimpleBox sx={{ mb: 2 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                        Stat priority
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'white', mb: 2 }}/>
                                    <ol>
                                        {statNames.map((statName, index) => (
                                            <li key={index}>{statName}</li>
                                        ))}
                                    </ol>
                                </Grid>
                            </SimpleBox>
                        </Grid>
                    </Grid>
                </Grid>

                {/*  End sidebar  */}

            </Grid>
        </Grid>
    );
}
