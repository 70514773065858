import React, { useEffect, useState } from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import {TextField, Button, Divider, Select, MenuItem, FormControl, Typography} from '@mui/material';
import SimpleBox from "../util/SimpleBox";
import Grid from "@mui/material/Grid";
import ShowTitle from "../util/ShowTitle";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import RequireAuthLogInWarning from "../auth/RequireAuthLogInWarning";

const AddVendor = ({ initialCoords }) => {
    const [inputs, setInputs] = useState({});
    const [pinCoordX, setPinCoordX] = useState(0);
    const [pinCoordY, setPinCoordY] = useState(0);
    const [zoom, setZoom] = useState(2);
    const [mapPosition, setMapPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [mapStart, setMapStart] = useState({ x: 0, y: 0 });
    const [dropPinMode, setDropPinMode] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const imageSrc = '/images/map/once_human_map.jpg';
    const containerWidth = 500;
    const containerHeight = 500;
    const mapWidth = 500;
    const mapHeight = 500;

    const minCoordX = -8100;
    const maxCoordX = 8100;
    const minCoordY = -8100;
    const maxCoordY = 8100;

    const validateField = (name, value) => {
        let error = "";
        if (name === "title") {
            if (!value || value.length < 3 || value.length > 50) {
                error = "Vendor name must be between 3 and 50 characters";
            }
        }
        if (name === "server_id") {
            // Updated regex to allow either 5 digits or a letter followed by 4 digits
            if (!/^(?:[A-Za-z]\d{4}|\d{5})$/.test(value)) {
                error = "Server ID must be either 5 digits or a letter followed by 4 digits";
            }
        }

        return error;
    };

    const validateForm = () => {
        let formErrors = {};
        if (!inputs.title || inputs.title.length < 3 || inputs.title.length > 50) {
            formErrors.title = 'Vendor name must be between 3 and 50 characters';
        }
        if (!inputs.server_id || !/^(?:[A-Za-z]\d{4}|\d{5})$/.test(inputs.server_id)) {
            formErrors.server_id = 'Server ID must be exactly 5 digits';
        }

        if (isNaN(pinCoordX) || pinCoordX === "" || !isFinite(pinCoordX)) {
            formErrors.pinCoordX = 'X Coordinate must be a valid number';
        }

        // Validate Y Coordinate
        if (isNaN(pinCoordY) || pinCoordY === "" || !isFinite(pinCoordY)) {
            formErrors.pinCoordY = 'Y Coordinate must be a valid number';
        }
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setIsSubmitting(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('coordinate_x', pinCoordX);
        formData.append('coordinate_y', pinCoordY);
        axios.post('/api/create/vendor', formData)
            .then(function (response) {
                if (response.data === 1) {
                    window.location.href = '/addListing';
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const fieldError = validateField(name, value);
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldError
        }));
    };

    const calculatePixelPosition = (coordX, coordY) => {
        const xPercent = (coordX - minCoordX) / (maxCoordX - minCoordX);
        const yPercent = (coordY - minCoordY) / (maxCoordY - minCoordY);

        const xPixel = xPercent * mapWidth;
        const yPixel = (1 - yPercent) * mapHeight;

        return { x: xPixel, y: yPixel };
    };

    const updatePinPosition = (coordX, coordY) => {
        const { x, y } = calculatePixelPosition(coordX, coordY);
        setPinCoordX(coordX);
        setPinCoordY(coordY);
        centerOnPin(x, y);
    };

    const centerOnPin = (x, y) => {
        setMapPosition({
            x: (containerWidth / 2) - (x * zoom),
            y: (containerHeight / 2) - (y * zoom),
        });
    };

    const zoomIn = () => {
        setZoom((prevZoom) => {
            const newZoom = Math.min(prevZoom + 0.5, 5);
            centerZoom(newZoom);
            return newZoom;
        });
    };

    const zoomOut = () => {
        setZoom((prevZoom) => {
            const newZoom = Math.max(prevZoom - 0.5, 1);
            centerZoom(newZoom);
            return newZoom;
        });
    };

    const centerZoom = (newZoom) => {
        const centerX = containerWidth / 2 - mapPosition.x;
        const centerY = containerHeight / 2 - mapPosition.y;

        setMapPosition({
            x: containerWidth / 2 - (centerX * newZoom) / zoom,
            y: containerHeight / 2 - (centerY * newZoom) / zoom,
        });
    };

    useEffect(() => {
        if (initialCoords) {
            const { x, y } = initialCoords;
            updatePinPosition(x, y);
            setZoom(3);
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            title: '',
            server_type: 1,
            world: 1,

        }));
    }, [initialCoords]);

    const handleMouseDown = (e) => {
        if (e.button !== 0 || dropPinMode) return; // Only allow dragging with left mouse button and if not in drop pin mode
        e.preventDefault(); // Prevent default browser behavior (like text selection)
        setDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
        setMapStart(mapPosition);
    };

    const handleMouseMove = (e) => {
        if (!dragging) return; // Only move the map if dragging is active
        e.preventDefault(); // Prevent default behavior while dragging
        const deltaX = e.clientX - dragStart.x;
        const deltaY = e.clientY - dragStart.y;

        setMapPosition({
            x: mapStart.x + deltaX,
            y: mapStart.y + deltaY,
        });
    };

    const handleMouseUp = (e) => {
        e.preventDefault(); // Prevent any default behavior on mouse release
        setDragging(false); // Stop dragging when the mouse button is released
    };

    const handleMapClick = (e) => {
        if (!dropPinMode) return;

        const rect = e.target.getBoundingClientRect();
        const x = (e.clientX - rect.left) / zoom;
        const y = (e.clientY - rect.top) / zoom;

        const coordX = minCoordX + (x / mapWidth) * (maxCoordX - minCoordX);
        const coordY = maxCoordY - (y / mapHeight) * (maxCoordY - minCoordY);

        updatePinPosition(coordX, coordY);
        setDropPinMode(false); // Exit drop pin mode after placing pin
    };

    const handleXCoordinateChange = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            updatePinPosition(value, pinCoordY); // Update X if valid
        } else {
            console.log("Invalid X Coordinate");
        }
    };

    const handleYCoordinateChange = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            updatePinPosition(pinCoordX, value); // Update Y if valid
        } else {
            console.log("Invalid Y Coordinate");
        }
    };

    useEffect(() => {
        // Listen for global mouse movements and release
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        console.log(errors);

        return () => {
            // Clean up event listeners on unmount
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging, dragStart, mapStart]);

    const isFormComplete = inputs.title && inputs.server_id && pinCoordX !== 0 && pinCoordY !== 0 && !Object.keys(errors).some(key => errors[key]);

    return (
        <RequireAuthLogInWarning>
        <SimpleBox>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ShowTitle title="Add New Vendor"/>
                    <Divider sx={{ borderBottomWidth: 3 }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="title"
                        label="Vendor Name (Only visible to you)"
                        name="title"
                        value={inputs.title}
                        onChange={handleChange}
                        sx={{ mt: 2 }}
                        error={!!errors.title}
                        helperText={errors.title}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="server_type-label">Server Type</InputLabel>
                                <Select
                                    id="server_type"
                                    name="server_type"
                                    labelId="server-label"
                                    value={inputs.server_type}
                                    onChange={handleChange}
                                    label="Server"
                                    defaultValue={1}
                                    fullWidth
                                >
                                    <MenuItem key={1} value={1}> EU-PVE01- </MenuItem>
                                    <MenuItem key={2} value={2}> EU-PVP01- </MenuItem>
                                    <MenuItem key={3} value={3}> NA-PVE01- </MenuItem>
                                    <MenuItem key={4} value={4}> NA-PVP01- </MenuItem>
                                    <MenuItem key={5} value={5}> SEA-PVE01- </MenuItem>
                                    <MenuItem key={6} value={6}> SEA-PVP01- </MenuItem>
                                    <MenuItem key={7} value={7}> LA-PVE01- </MenuItem>
                                    <MenuItem key={8} value={8}> LA-PVP01- </MenuItem>
                                    <MenuItem key={9} value={9}> HK-MO-TW-PVE01- </MenuItem>
                                    <MenuItem key={10} value={10}> HK-MO-TW-PVP01- </MenuItem>
                                    <MenuItem key={11} value={11}> JP-PVE01- </MenuItem>
                                    <MenuItem key={12} value={12}> JP-PVP01- </MenuItem>
                                    <MenuItem key={13} value={13}> RoK-PVE01- </MenuItem>
                                    <MenuItem key={14} value={14}> RoK-PVP01- </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="server_id"
                                label="Server ID (ex.: X0005 or 00041)"
                                name="server_id"
                                type="text"
                                value={inputs.server_id}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.server_id}
                                helperText={errors.server_id}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="world-label">World</InputLabel>
                                <Select
                                    id="world"
                                    name="world"
                                    labelId="world-label"
                                    value={inputs.world}
                                    onChange={handleChange}
                                    label="Server"
                                    defaultValue={1}
                                    fullWidth
                                >
                                    <MenuItem key={1} value={1}> World 1 </MenuItem>
                                    <MenuItem key={2} value={2}> World 2 </MenuItem>
                                    <MenuItem key={3} value={3}> World 3 </MenuItem>
                                    <MenuItem key={4} value={4}> World 4 </MenuItem>
                                    <MenuItem key={5} value={5}> World 5 </MenuItem>
                                    <MenuItem key={6} value={6}> World 6 </MenuItem>
                                    <MenuItem key={7} value={7}> World 7 </MenuItem>
                                    <MenuItem key={8} value={8}> World 8 </MenuItem>
                                    <MenuItem key={9} value={9}> World 9 </MenuItem>
                                    <MenuItem key={10} value={10}> World 10 </MenuItem>
                                    <MenuItem key={11} value={11}> World 11 </MenuItem>
                                    <MenuItem key={12} value={12}> World 12 </MenuItem>
                                    <MenuItem key={13} value={13}> World 13 </MenuItem>
                                    <MenuItem key={14} value={14}> World 14 </MenuItem>
                                    <MenuItem key={15} value={15}> World 15 </MenuItem>
                                    <MenuItem key={16} value={16}> World 16 </MenuItem>
                                    <MenuItem key={17} value={17}> World 17 </MenuItem>
                                    <MenuItem key={18} value={18}> World 18 </MenuItem>
                                    <MenuItem key={19} value={19}> World 19 </MenuItem>
                                    <MenuItem key={20} value={20}> World 20 </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="X Coordinate"
                                type="text" // Change to text to allow flexibility in input
                                value={pinCoordX}
                                onChange={handleXCoordinateChange}
                                error={!!errors.pinCoordX} // Highlight the field if there's an error
                                helperText={errors.pinCoordX || ''}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Y Coordinate"
                                type="text" // Change to text to allow flexibility in input
                                value={pinCoordY}
                                onChange={handleYCoordinateChange}
                                error={!!errors.pinCoordY} // Highlight the field if there's an error
                                helperText={errors.pinCoordY || ''}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                variant={dropPinMode ? "contained" : "outlined"}
                                onClick={() => setDropPinMode(true)}
                                fullWidth
                                sx={{ p: 2 }}
                            >
                                Drop Pin
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className="map-container"
                    sx={{
                        position: 'relative',
                        width: `${containerWidth}px`,
                        height: `${containerHeight}px`,
                        overflow: 'hidden',
                        border: '2px solid black',
                        m: 2
                    }}
                >
                    <div style={{ position: 'absolute', zIndex: 1, top: '10px', left: '10px' }}>
                        <Button variant="contained" onClick={zoomIn}>+</Button>
                        <Button variant="contained" onClick={zoomOut} sx={{ ml: 1 }}>-</Button>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            top: `${mapPosition.y}px`,
                            left: `${mapPosition.x}px`,
                            transform: `scale(${zoom})`,
                            transformOrigin: 'top left',
                            transition: dragging ? 'none' : 'transform 0.3s ease, top 0.3s ease, left 0.3s ease',
                            cursor: dropPinMode ? 'crosshair' : (dragging ? 'grabbing' : 'grab'),
                        }}
                        onMouseDown={handleMouseDown}
                        onClick={handleMapClick}
                    >
                        <img src={imageSrc} alt="Map" style={{ width: `${containerWidth}px`, height: `${containerHeight}px` }} />

                        <PlaceIcon
                            style={{
                                position: 'absolute',
                                top: `${calculatePixelPosition(pinCoordX, pinCoordY).y}px`,
                                left: `${calculatePixelPosition(pinCoordX, pinCoordY).x}px`,
                                transform: 'translate(-50%, -100%)',
                                color: 'rgba(0,94,232,0.9)',
                                fontSize: '25px',
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        disabled={!isFormComplete || isSubmitting}
                        onClick={handleSubmit}
                        sx={{ mt: 4 }}
                    >
                        {isSubmitting ? 'Submitting...' : 'Add Vendor Location'}
                    </Button>
                </Grid>
            </Grid>
        </SimpleBox>
        </RequireAuthLogInWarning>
    );
};

export default AddVendor;
