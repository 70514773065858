import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { Typography, CircularProgress } from "@mui/material";
import axios from "axios";

const labels = {
    0.5: 'F',
    1: 'D',
    1.5: 'D+',
    2: 'C',
    2.5: 'C+',
    3: 'B',
    3.5: 'B+',
    4: 'A',
    4.5: 'A+',
    5: 'S',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export default function RateBuild({ buildId }) {
    const [value, setValue] = React.useState(-1);
    const [hover, setHover] = React.useState(-1);
    const [saving, setSaving] = React.useState(false);
    const [voted, setVoted] = React.useState(false);

    const handleSubmit = async (newValue) => {
        setSaving(true);
        const formData = new FormData();
        formData.append('build_id', buildId);
        formData.append('vote', newValue);

        const response = await axios.post('/api/update/vote', formData);

        if (response.data !== false) {
            setVoted(true);
        }
    };

    if (voted) {
        return (
                    <Typography variant="h6" sx={{ fontStyle: 'italic', ml: 2 }}>
                        Thank you for voting!
                    </Typography>
        );
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 1, mt: 2, height: '42px'  }}>
            <Rating
                name="rate-build"
                value={value}
                precision={0.5}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    handleSubmit(newValue); // Automatically submit when a rating is selected
                }}
                onChangeActive={(event, newHover) => setHover(newHover)}
                disabled={saving}  // Disable the rating component while saving
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {value !== null && (
                <Typography variant="h6" sx={{ fontStyle: 'italic', ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                </Typography>
            )}
            {saving && <CircularProgress size={24} sx={{ ml: 2 }} />} {/* Show spinner while saving */}
        </Box>
    );
}
