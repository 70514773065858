import React, {useEffect, useState} from 'react';
import CustomTooltip from '../util/CustomTooltip';

const ItemDisplay = ({ buildData, itemName, width = '200px', height = '100px', folder = 'gears', textPlacement = 'center', tooltipPlacement = 'left', rounded = false, rarity = 5 }) => {
    const [imageLink, setImageLink] = useState('');
    const itemData = buildData[itemName];

    useEffect(() => {
        if (itemData) {
            let link = `/images/${folder}/${itemData.id}.webp`;
            if (folder === 'calibrations') {
                link = `/images/${folder}/${itemData.category}.webp`;
                if (itemData.seasonal) {
                    link = `/images/${folder}/seasonal.webp`;
                }
            }
            setImageLink(link);
        }
    }, [itemData, folder]);

    if (!itemData) return null;

    return (
        <CustomTooltip
            title={itemData.title}
            description={itemData.description}
            rarity={itemData.rarity ?? rarity}
            placement={tooltipPlacement}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={imageLink}
                    alt={itemData.title}
                    style={{
                        width,
                        height,
                        border: '2px solid black',
                        borderRadius: rounded ? '50%' : '4px', // Make image circular if rounded is true
                        transition: 'border-color 0.3s ease-in-out', // Transition effect
                    }}
                    onMouseEnter={e => (e.currentTarget.style.borderColor = 'white')} // On hover
                    onMouseLeave={e => (e.currentTarget.style.borderColor = 'black')} // On hover out
                />
            </div>
            <div style={{ marginBottom: '4px', marginLeft: '4px', marginTop: '4px', fontSize: '14px', fontWeight: 'bold', color: '#ffffff', textAlign: `${textPlacement}` }}>
                {itemData.title}
            </div>
        </CustomTooltip>
    );
};

export default ItemDisplay;
