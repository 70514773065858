import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    styled,
    LinearProgress,
    Button,
    Collapse, Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import {format, fromZonedTime, toDate} from 'date-fns-tz';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import SimpleBox from "./SimpleBox";
import ChatIcon from '@mui/icons-material/Chat';
import BuildIcon from '@mui/icons-material/Build';
import EventCardSubTypeOne from "./EventCardSubTypeOne";
import EventCardSubTypeTwo from "./EventCardSubTypeTwo";

// Progress bar with striped background
const StripedLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 30,
    borderRadius: 5,
    backgroundColor: '#e0e0e0',
    boxShadow: `0px 3px 10px 0px #000`,
    '& .MuiLinearProgress-bar': {
        backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent)',
        backgroundSize: '4rem 4rem',
        animation: 'progress-bar-stripes 2s linear infinite',
        backgroundColor: value > 80 ? 'red' : value > 50 ? 'orange' : 'green',
    },
    '@keyframes progress-bar-stripes': {
        '0%': { backgroundPosition: '0 0' },
        '100%': { backgroundPosition: '4rem 0' },
    },
}));

const categoryIcons = {
    0: { title: 'Battlepass', icon: <LocalActivityIcon /> },
    1: { title: 'Event', icon: <CelebrationIcon /> },
    2: { title: 'News', icon: <ArticleRoundedIcon /> },
    3: { title: 'DLC', icon: <ExtensionRoundedIcon /> },
    4: { title: 'Patch Notes', icon: <BuildIcon /> },
    5: { title: 'Dev-Talks', icon: <ChatIcon /> }
};

const EventCard = ({ event, eventTypes, defaultExpanded = false, presetGameTitle = null }) => {
    // const [expanded, setExpanded] = useState(event.category >= 2 ? true : defaultExpanded);
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [shadowColor, setShadowColor] = useState('rgba(0, 0, 0, 0.3)');
    const eventType = presetGameTitle ||
        (eventTypes && eventTypes.length > 0 && event?.category !== undefined
            ? eventTypes.find(type => type.id === event.category)?.name || "Unknown Event"
            : "Unknown Event");

    const handleCopyClick = () => {
        if (event.site_link) {
            navigator.clipboard.writeText(event.site_link).catch((error) => {
                console.error('Failed to copy: ', error);
            });
        }
    };

    useEffect(() => {
        if (expanded) {
            setContentLoaded(true);
        }
    }, [expanded]);

    const convertToZonedDate = (dateStr) => {
        const utcDate = new Date(dateStr);
        const zonedDate = fromZonedTime(utcDate, 'UTC');
        return format(zonedDate, 'yyyy-MM-dd HH:mm');
    };

    // Calculate progress percentage
    const totalDays = Math.ceil((new Date(event.event_end) - new Date(event.event_start)) / (1000 * 60 * 60 * 24)); // Total duration in days
    const progressPercentage = Math.min(((totalDays - event.daysLeft) / totalDays) * 100, 100); // Calculate progress



    // Calculate Time Left
    const calculateTimeLeft = (event) => {
        const startDate = toDate(fromZonedTime(event.event_start, 'UTC'));
        const endDate = toDate(fromZonedTime(event.event_end, 'UTC'));
        const currentDate = new Date();

        const totalDuration = endDate - startDate;
        const remainingTime = endDate - currentDate;

        if (remainingTime <= 0) {
            return {
                totalDays: 0,
                daysLeft: 0,
                hoursLeft: 0,
                minutesLeft: 0,
                progressPercentage: 0, // Event is over
            };
        }

        const daysLeft = remainingTime / (1000 * 60 * 60 * 24);
        const hoursLeft = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesLeft = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

        const elapsedTime = currentDate - startDate;
        const progressPercentage = Math.min((elapsedTime / totalDuration) * 100, 100); // Bound percentage between 0-100

        return {
            totalDays: totalDuration / (1000 * 60 * 60 * 24),
            daysLeft: parseFloat(daysLeft.toFixed(1)),
            hoursLeft,
            minutesLeft,
            progressPercentage, // This value will be passed to the progress bar
        };
    };


    // Extended Event Object
    const extendedEvent = {
        ...event,
        ...calculateTimeLeft(event),
    };

    // Time Remaining String
    const timeRemaining = (daysLeft, hoursLeft, minutesLeft) => {
        if (daysLeft === 0) {
            return 'Event is over.';
        } else if (daysLeft < 1) {
            return `${hoursLeft}h ${minutesLeft}m`;
        } else {
            return `${Math.floor(daysLeft)}d ${hoursLeft}h`;
        }
    };

    const localStartDate = convertToZonedDate(event.event_start);
    const localEndDate = convertToZonedDate(event.event_end);
    const timeRemainingText = timeRemaining(extendedEvent.daysLeft, extendedEvent.hoursLeft, extendedEvent.minutesLeft);

    const logoUrl = `/images/games/${event.game_id}.webp`;

    // Render different components based on the event type
    if (event.category === 0  || event.category === 1) {
        return (
            <EventCardSubTypeOne
                event={event}
                extendedEvent={extendedEvent}
                timeRemainingText={timeRemainingText}
                eventType={eventType}
                handleCopyClick={handleCopyClick}
                progressPercentage={progressPercentage}
                localStartDate={localStartDate}
                localEndDate={localEndDate}
                shadowColor={shadowColor}
                setShadowColor={setShadowColor}
                expanded={expanded}
                setExpanded={setExpanded}
                contentLoaded={contentLoaded}
            />
        );
    } else {
        return (
            <EventCardSubTypeTwo
                event={event}
                eventType={eventType}
                handleCopyClick={handleCopyClick}
                progressPercentage={progressPercentage}
                localStartDate={localStartDate}
                localEndDate={localEndDate}
                shadowColor={shadowColor}
                setShadowColor={setShadowColor}
                expanded={expanded}
                setExpanded={setExpanded}
                contentLoaded={contentLoaded}
            />
        );
    }
};

export default EventCard;

