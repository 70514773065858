import {useSessionData} from "./SessionDataProvider";

function RequireAuth({ children })
{
    const { sessionData } = useSessionData();

    if (!sessionData.isLogged) {
        return null;
    }

    return children;
}

export default RequireAuth
