import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import UniversalSelector from "../build_planner/UniversalSelector";
import ShowSnack from "../util/ShowSnack";
import SimpleBox from "../util/SimpleBox";
import RequireAuthLogInWarning from "../auth/RequireAuthLogInWarning";
import {useParams} from "react-router-dom";
import {useSessionData} from "../auth/SessionDataProvider";
import {Checkbox, CircularProgress, circularProgressClasses, FormControlLabel} from "@mui/material";
import DarkTooltip from "../util/DarkTooltip";

export default function AddListing({}) {
    const { sessionData } = useSessionData();
    const { id } = useParams();
    const [inputs, setInputs] = useState({});
    const [resources, setResources] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [secondaryStats, setSecondaryStats] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [listing, setListing] = useState(null);
    const [pricePerUnit, setPricePerUnit] = useState(0);
    const [isContinuousSupply, setIsContinuousSupply] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getResources();
        getUserVendors();
        getSecondaryStats();
        if (id) {
            getTrade(); // Fetch listing data if editing
        } else {
            setInputs((prevInputs) => ({
                ...prevInputs,
                item_sell_qty: 1,
                item_buy_qty: 1,
                item_buy_id: 690,
                state: 1,
            }));
        }
    }, [id]);

    useEffect(() => {
        const selectedVendor = vendors.find(vendor => vendor.id === inputs.vendor_id);

        if (selectedVendor) {
            setInputs((prevInputs) => ({
                ...prevInputs,
                server_id: selectedVendor.server_id
            }));
        }
    }, [inputs.vendor_id, vendors]);

    useEffect(() => {
        const sellQty = parseFloat(inputs.item_sell_qty) || 0;
        const buyQty = parseFloat(inputs.item_buy_qty) || 0;

        if (buyQty > 0) {
            const calculatedPricePerUnit = buyQty / sellQty;
            setPricePerUnit(calculatedPricePerUnit);
        } else {
            setPricePerUnit(0);
        }
    }, [inputs.item_sell_qty, inputs.item_buy_qty]);

    // Update item_sell_qty when pricePerUnit is manually updated
    const handlePricePerUnitChange = (event) => {
        const newPricePerUnit = parseFloat(event.target.value) || 0;
        setPricePerUnit(newPricePerUnit);
        const sellQty = inputs.item_sell_qty || 0;
        setInputs((prevInputs) => ({
            ...prevInputs,
            item_buy_qty: (newPricePerUnit * sellQty)
        }));
    };

    function getResources() {
        axios.get('/api/list/resources')
            .then((result) => {
                if (result.data !== false) {
                    setResources(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getUserVendors() {
        axios.get('/api/list/userVendors')
            .then((result) => {
                if (result.data !== false) {
                    setVendors(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getSecondaryStats() {
        axios.get('/api/list/secondaryStats')
            .then((result) => {
                if (result.data !== false) {
                    setSecondaryStats(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getTrade() {
        axios.get(`/api/get/listing/${id}`)
            .then((result) => {
                if (result.data !== false) {
                    console.log(result.data);
                    setInputs(result.data); // Pre-fill the form with fetched data
                    setListing(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }

        if (id) {
            // Update the listing if editing
            axios.post(`/api/update/listingData`, formData)
                .then(function (response) {
                    setProcessing(false);
                    setSnackOpen(true);
                    setMessage('Updated Successfully!');
                    setSeverity('success');
                })
                .catch(error => {
                    setProcessing(false);
                    console.log(error);
                });
        } else {
            // Create a new listing
            axios.post('/api/create/listing', formData)
                .then(function (response) {
                    setProcessing(false);
                    setSnackOpen(true);
                    setMessage('Added Successfully!');
                    setSeverity('success');
                })
                .catch(error => {
                    setProcessing(false);
                    console.log(error);
                });
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        setProcessing(true);
        const formData = new FormData();
        formData.append('owner_id', listing.owner_id);
        formData.append('listing_id', id);
        formData.append('state', 5);
        axios.post('/api/update/listing', formData)
            .then(function (response) {
                if (response.data === 1) {
                    window.location.href = '/trade/listings';
                }
            })
    };

    const handleContinuousSupplyChange = (event) => {
        const checked = event.target.checked;
        setIsContinuousSupply(checked);
        setInputs((prevInputs) => ({
            ...prevInputs,
            state: checked ? 4 : 1, // Set state to 4 if checked, otherwise default to 1 (Available)
        }));
    };


    // Find the selected resource from resources based on inputs.sell_resource
    const selectedResource = resources.find(resource => resource.id === inputs.item_sell_id);

    // Check if selected resource has a non-zero primary_stat
    const primaryStatExists = selectedResource && selectedResource.primary_stat !== 0;
    const secondaryStatExists = selectedResource && selectedResource.secondary_stat !== 0;

    // Filter secondaryStats by the selected resource's primary_stat
    const filteredSecondaryStats = secondaryStats.filter(stat => stat.type === selectedResource?.primary_stat);

    if (id && !sessionData.isLogged) {
        return (
            <SimpleBox sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={(theme) => ({
                    color: '#1a90ff',
                    animationDuration: '550ms',
                    position: 'middle',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    ...theme.applyStyles('dark', {
                        color: '#308fe8',
                    }),
                })}
                size={40}
                thickness={4}
                />
            </SimpleBox>
        );
    }

    if ((id && inputs.owner_id !== sessionData.userId) || !sessionData.isLogged) {
        return (
            <SimpleBox>
                <Typography variant="h5" fontWeight="bold">
                    You dont have rights to modify this trade!
                </Typography>
            </SimpleBox>
        );
    }

    return (
        <RequireAuthLogInWarning>
            <SimpleBox>
                <Grid container sx={{ p: 3 }} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5" fontWeight="bold">
                                    {id ? 'Edit listing' : 'Add New listing'}
                                </Typography>
                                {sessionData.userId === inputs.owner_id && (
                                    <Button
                                        onClick={handleDelete}
                                        color="error"
                                        variant="contained"
                                    >
                                        DELETE TRADE
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ border: '1px solid white', mt: 2, mb: 2}} />
                    </Grid>

                    {id && (
                        <>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">
                                    Trade State
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="state-label">State</InputLabel>
                                    <Select
                                        id="state"
                                        name="state"
                                        labelId="state-label"
                                        value={inputs.state}
                                        onChange={handleChange}
                                        label="State"
                                    >
                                        <MenuItem key={0} value={0}> Inactive </MenuItem>
                                        <MenuItem key={1} value={1}> Available </MenuItem>
                                        <MenuItem key={2} value={2}> Buyer Applied </MenuItem>
                                        <MenuItem key={3} value={3}> Reported Missing </MenuItem>
                                        <MenuItem key={4} value={4}> Continuous Supply </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                            Selected Vendor:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="vendor_id-label">Vendor</InputLabel>
                            <Select
                                id="vendor_id"
                                name="vendor_id"
                                labelId="vendor_id-label"
                                disabled={id}
                                value={inputs.vendor_id}
                                onChange={handleChange}
                                label="Vendor"
                            >
                                {vendors.map(vendor => (
                                    <MenuItem key={vendor.id} value={vendor.id}>
                                        {vendor.title} - {vendor.server_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Item to trade
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <UniversalSelector
                            mainData={resources}
                            inputs={inputs}
                            setInputs={setInputs}
                            itemName="item_sell_id"
                            itemTitle="Sell item"
                            folder="resources"
                            defaultValue={1}
                            errorSetter={setErrors}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="item_sell_qty"
                            label="Sell Amount"
                            name="item_sell_qty"
                            type="number"
                            value={inputs.item_sell_qty}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            id="comment"
                            label="Comment"
                            name="comment"
                            value={inputs.comment || ''}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Conditionally render Primary Stat if the selected resource has a non-zero primary_stat */}
                    {primaryStatExists && (
                        <>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="primary_stat-label">Primary stat</InputLabel>
                                    <Select
                                        id="primary_stat_id"
                                        name="primary_stat_id"
                                        labelId="primary_stat-label"
                                        value={inputs.primary_stat_id}
                                        onChange={handleChange}
                                        label="Primary stat"
                                    >
                                        {filteredSecondaryStats.map(stat => (
                                            <MenuItem key={stat.id} value={stat.id}>
                                                {stat.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="primary_stat_qty"
                                    label="Value"
                                    name="primary_stat_qty"
                                    type="number"
                                    value={inputs.primary_stat_qty}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}

                    {secondaryStatExists && (
                        <>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="secondary_stat_id-label">Secondary stat</InputLabel>
                                    <Select
                                        id="secondary_stat_id"
                                        name="secondary_stat_id"
                                        labelId="secondary_stat-label"
                                        value={inputs.secondary_stat_id}
                                        onChange={handleChange}
                                        label="Primary stat"
                                    >
                                        {filteredSecondaryStats.map(stat => (
                                            <MenuItem key={stat.id} value={stat.id}>
                                                {stat.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="secondary_stat_qty"
                                    label="Value"
                                    name="secondary_stat_qty"
                                    type="number"
                                    value={inputs.secondary_stat_qty}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Exchange for (Total Price)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <UniversalSelector
                            mainData={resources}
                            inputs={inputs}
                            setInputs={setInputs}
                            itemName="item_buy_id"
                            itemTitle="Buy item"
                            folder="resources"
                            defaultValue={1}
                            errorSetter={setErrors}
                        />
                    </Grid>
                    {/* Item Buy Amount */}
                    <Grid item xs={12} md={3}>
                        <TextField
                            id="price_per_unit"
                            label="Price Per Unit"
                            name="price_per_unit"
                            type="number"
                            value={pricePerUnit}
                            onChange={handlePricePerUnitChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            id="item_buy_qty"
                            label="Total price"
                            name="item_buy_qty"
                            type="number"
                            value={inputs.item_buy_qty}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isContinuousSupply}
                                    onChange={handleContinuousSupplyChange}
                                    color="primary"
                                />
                            }
                            label="Continuous Supply"
                        />
                        <DarkTooltip tooltipText={'Check this if you always try to keep this deal in stock! Players cant make buy requests and even logged out users can check the deal.'}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            color="success"
                            fullWidth
                            variant="contained"
                            disabled={processing}
                            sx={{mt: 3, mb: 1, p: 2}}
                        >
                            {id ? 'Update Listing' : 'List Trade Offer'}
                        </Button>
                    </Grid>
                </Grid>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
            </SimpleBox>
        </RequireAuthLogInWarning>
    );

}
