import Grid from "@mui/material/Grid";
import {
    Divider
} from '@mui/material';
import React from "react";
import Countdown from "./util/Countdown";
import moment from "moment-timezone";
import SimpleBox from "./util/SimpleBox";
import ItemDisplay from "./build_planner/ItemDisplay";

export default function Header({ }) {
    const getLootCrateResetTime = () => {
        const now = moment().tz("GMT");
        const hoursPassed = now.hours() % 4;
        return moment().tz("GMT").startOf('hour').add(4 - hoursPassed, 'hours');
    };

    const getVendorResetTime = () => {
        return moment().tz("GMT").day(7).hour(5).minute(0).second(0);
    };

    const getWeeklyResetTime = () => {
        return moment().tz("GMT").day(1).hour(5).minute(0).second(0);
    };

    return (
        <SimpleBox sx={{
            width: '75%',
            display: 'flex',
            justifyContent: 'center', // Centers horizontally
            alignItems: 'center', // Centers vertically
            margin: '0 auto', // Horizontally centers the SimpleBox itself
            marginBottom: 2,
            marginTop: 2,
            textAlign: 'center', // Center text within
        }}>
            <Grid container sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, // Column on small screens, row on medium and up
                alignItems: 'center', // Align items in the center vertically
                justifyContent: 'center', // Center items horizontally
                textAlign: 'center', // Make sure text is centered
                fontVariant: 'small-caps',
            }}>
                <Grid item xs={12} md={3}>
                    <Countdown description="Loot Crate Reset" resetTime={getLootCrateResetTime} />
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem sx={{
                    border: '1px solid',
                    bgcolor: 'white',
                    display: { xs: 'none', md: 'block' } // Hide divider on small screens
                }} />
                <Grid item xs={12} md={3}>
                    <Countdown description="Vendor Reset" resetTime={getVendorResetTime} />
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem sx={{
                    border: '1px solid',
                    bgcolor: 'white',
                    display: { xs: 'none', md: 'block' } // Hide divider on small screens
                }} />
                <Grid item xs={12} md={3}>
                    <Countdown description="Weekly Reset" resetTime={getWeeklyResetTime} />
                </Grid>
            </Grid>
        </SimpleBox>

    );
}
