import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Paper } from '@mui/material';
import SimpleBox from "../util/SimpleBox";
import Divider from "@mui/material/Divider";
import ShowTitle from "../util/ShowTitle";

const ItemDetail = ({ data }) => {
    const { type, id } = useParams(); // Extract `type` and `id` from URL parameters

    const items = data[type]; // Get the correct array (e.g., gears, mods, etc.)
    const item = items ? items.find(item => item.id === parseInt(id)) : null;

    const linkedSet = item && item.linked_set ? data['sets'].find(set => set.id === item.linked_set) : null;

    const locationIds = item && typeof item.location === 'string'
        ? item.location.split(',')
        : [];

    const gearCategories = [
        "Weapon",   // 0
        "Helmet",   // 1
        "Mask",     // 2
        "Top",      // 3
        "Bottom",   // 4
        "Glove",    // 5
        "Shoe",     // 6
        "All Gear"  // 7
    ];

    const deviationCategories = [
        "None",   // 0
        "Attack",   // 1
        "Defense",     // 2
        "Territory",      // 3
        "Crafting",   // 4
    ];

    console.log(locationIds);


    if (!item) return <SimpleBox>Loading...</SimpleBox>;

    return (
        <SimpleBox>
            <Grid container spacing={2} sx={{ m: 1, alignItems: 'center', justifyItems: 'center' }}>
                <Grid item xs={12} >
                    <ShowTitle title={item.title} item={item} />
                    <Divider sx={{ border: '1px solid white', m: 1 }} />
                </Grid>
                <Grid item xs={12} md={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center' }}>
                    <img
                        src={`/images/${type}/${item.id}.webp`}
                        alt={item.title}
                        style={{width: '100%', maxWidth: '200px'}}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        {linkedSet && linkedSet.id !== 1 && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5">Set Bonus</Typography>
                                {linkedSet && linkedSet.id !== 1 && (
                                    <>
                                        <ol>
                                            <li><Typography dangerouslySetInnerHTML={{ __html: linkedSet.bonus_one }} /></li>
                                            <li><Typography dangerouslySetInnerHTML={{__html: linkedSet.bonus_two}}/></li>
                                            <li><Typography dangerouslySetInnerHTML={{__html: linkedSet.bonus_three}}/></li>
                                            <li><Typography dangerouslySetInnerHTML={{__html: linkedSet.bonus_four}}/></li>
                                        </ol>
                                    </>
                                )}
                            </Grid>
                        )}
                        {item.mod_type && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5">Mod Type: {item.mod_type }</Typography>
                            </Grid>
                        )}
                        {type === 'mods' && item.category !== undefined && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5">Item slot: {gearCategories[item.category]}</Typography>
                            </Grid>
                        )}
                        {type === 'deviations' && item.category !== undefined && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5">Type: {deviationCategories[item.category]}</Typography>
                            </Grid>
                        )}
                        {item.description && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5">Description</Typography>
                                <Typography dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Grid>
                        )}
                        {locationIds.length > 0 && (
                            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', p: 1 }}>
                                <Typography variant="h5">Farm Locations</Typography>
                                {locationIds.map((locId) => (
                                    data.locations[locId]?.title ? (
                                        <Typography key={locId} variant="h6">
                                            {data.locations[locId].title}
                                        </Typography>
                                    ) : null
                                ))}
                            </Grid>
                        )}
                        {item.source !== undefined && (
                            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', p: 1 }}>
                                <Typography variant="h5">Obtain Info</Typography>
                                <Typography dangerouslySetInnerHTML={{ __html: item.source }} />
                            </Grid>
                        )}
                        {item.mood_booster && (
                            <Grid item xs={12} md={6}>
                                <Typography variant="h5">Mood Boosters</Typography>
                                <Typography dangerouslySetInnerHTML={{ __html: item.mood_booster }} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default ItemDetail;
