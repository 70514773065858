import React from 'react';
import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const rarityColors = {
    0: '#b4b4b4',
    1: '#2ca602',
    2: '#1e88e5',
    3: '#8e24aa',
    5: '#b08d57',
    6: '#48e3bd',
};

const CustomTooltip = styled(({ className, title, rarity = 5, placement  = 'left', description, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        placement={placement}
        title={
            <div>
                <Typography
                    variant="h6"
                    style={{
                        backgroundColor: rarityColors[rarity],
                        color: '#ffffff',
                        padding: 10,
                        borderRadius: '4px 4px 0 0',
                        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))`,
                        boxShadow: `0px 0px 10px 0px #000`,
                        textTransform: "uppercase",
                    }}>
                    {title}
                </Typography>
                <Typography style={{ color: '#ffffff', padding: 10, }}>
                    <div dangerouslySetInnerHTML={{__html: description}}/>
                </Typography>
            </div>
        }
        />
))(({ theme, rarity }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D1D1DFF',
        color: 'rgba(0, 0, 0, 1)',
        width: 420,
        fontSize: theme.typography.pxToRem(16),
        border: '2px solid #000',
        marginLeft: 100,
        marginRight: 10,
        padding: 0,
        // boxShadow: `0px 0px 10px 0px ${rarityColors[rarity]}`,
        boxShadow: `0px 0px 10px 0px #000`,
    },
}));

export default CustomTooltip;
