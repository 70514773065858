import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomFileInput from "./CustomFileInput";

function ImageUpload({ data, setData, setImageFile, cropWidth = 130, cropHeight = 130 }) {
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ unit: 'px', width: cropWidth, aspect: cropWidth / cropHeight });
    const [croppedImage, setCroppedImage] = useState(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (imageSrc) {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                imageRef.current = image;

                setCrop((prevCrop) => ({
                    ...prevCrop,
                    unit: 'px',
                    width: cropWidth,
                    height: cropHeight,
                    aspect: cropWidth / cropHeight,
                }));
            };
        }
    }, [imageSrc, cropWidth, cropHeight]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            let newCrop = { ...crop };
            const step = 1; // Move by 1px per key press

            switch (event.key) {
                case 'ArrowUp':
                    newCrop.y = Math.max(crop.y - step, 0);
                    break;
                case 'ArrowDown':
                    newCrop.y = Math.min(crop.y + step, imageRef.current.height - crop.height);
                    break;
                case 'ArrowLeft':
                    newCrop.x = Math.max(crop.x - step, 0);
                    break;
                case 'ArrowRight':
                    newCrop.x = Math.min(crop.x + step, imageRef.current.width - crop.width);
                    break;
                default:
                    return; // Exit if not an arrow key
            }
            setCrop(newCrop);
        };

        if (imageSrc) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [crop, imageSrc]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
            setImageFile(file); // Set the initial file
        }
    };

    const getCroppedImageBlob = (image, crop) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            canvas.width = cropWidth;
            canvas.height = cropHeight;

            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.webkitImageSmoothingEnabled = true;
            ctx.mozImageSmoothingEnabled = true;
            ctx.msImageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                canvas.width,
                canvas.height
            );

            canvas.toBlob((blob) => {
                const croppedFile = new File([blob], "cropped_image.webp", { type: "image/webp" });
                resolve(croppedFile);
            }, 'image/webp', 0.9);
        });
    };

    const handleCropComplete = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const image = imageRef.current;

            try {
                const croppedBlob = await getCroppedImageBlob(image, crop);
                setCroppedImage(croppedBlob); // Set the cropped image as blob
            } catch (error) {
                console.error('Error cropping image:', error);
            }
        } else {
            console.error('Image reference not loaded properly.');
        }
    };

    const handleImageUpload = async () => {
        if (croppedImage) {
            setImageFile(croppedImage); // Update the imageFile with the cropped image
            setData((prevData) => ({
                ...prevData,
                image: croppedImage,
            }));
            setImageSrc(null);
            setCroppedImage(null);
        }
    };

    return (
        <Grid container spacing={2}>
            {croppedImage ? (
                <Grid item xs={9}>
                    <Button
                        onClick={handleImageUpload}
                        fullWidth
                        variant="contained"
                        color="success"
                        disabled={!croppedImage}
                        sx={{ p: 2 }}
                    >
                        Mentés
                    </Button>
                </Grid>
            ) : (
                <Grid item xs={11}>
                    <CustomFileInput handleImageChange={handleImageChange} customText={"Upload, Drop or Insert image"} />
                </Grid>
            )}
            <Grid item sx={{ width: '100%' }}>
                {imageSrc && (
                    <ReactCrop
                        src={imageSrc}
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={handleCropComplete}
                        aspect={cropWidth / cropHeight}
                    >
                        <img src={imageSrc} alt="Crop" style={{ width: '100%' }} />
                    </ReactCrop>
                )}
            </Grid>
        </Grid>
    );
}

export default ImageUpload;
