import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
    FormControl, MenuItem,
    Select,
    Tooltip, Typography,
} from "@mui/material";
import axios from "axios";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import ContentBox from "../util/ContentBox";
import SimpleBox from "../util/SimpleBox";
import UniversalSelector from "./UniversalSelector";
import PercentageStar from "../util/PercentageStar";
import IconButton from "@mui/material/IconButton";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ShowTitle from "../util/ShowTitle";

export default function BuildList({ data, isSmallScreen }) {
    const [sortedEvents, setSortedBuilds] = useState([]);
    const [usernames, setUsernames] = useState({});
    const [serverDifficulties, setServerDifficulties] = useState({});
    const [buildTypes, setBuildTypes] = useState({});
    const [damageTypes, setDamageTypes] = useState({});
    const [filters, setFilters] = React.useState({
        searchText: '',
        damage_type: -1,
        server_difficulty: -1,
        build_type: -1,
        sortBy: 'date', // or 'date'
        sortOrder: 'desc', // or 'asc'
    });
    const [loading, setLoading] = useState(true);
    const MAX_BUILD_RESULTS = 50;
    const [sortOption, setSortOption] = React.useState('date'); // 'rating' or 'date'
    const [sortOrder, setSortOrder] = React.useState('desc'); // 'asc' or 'desc'

    useEffect(() => {
        const fetchBuildsAndUsers = async () => {
            try {
                const usernameMap = data.userNicks.reduce((acc, user) => {
                    acc[user.id] = user.nickname;
                    return acc;
                }, {});
                setUsernames(usernameMap);

                const serverMap = data.serverdifficulty.reduce((acc, difficulty) => {
                    acc[difficulty.id] = difficulty.title;
                    return acc;
                }, {});
                setServerDifficulties(serverMap);

                const buildty = data.buildtypes.reduce((acc, type) => {
                    acc[type.id] = type.title;
                    return acc;
                }, {});
                setBuildTypes(buildty);

                const damagety = data.damagetypes.reduce((acc, type) => {
                    acc[type.id] = type.title;
                    return acc;
                }, {});
                setDamageTypes(damagety);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        getBuilds();
        fetchBuildsAndUsers();
    }, [data]);

    function getBuilds() {
        axios.get('/api/get/builds')
            .then((result) => {
                if (result.data !== false) {
                    console.log(result.data);
                    setSortedBuilds(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const filteredBuilds = sortedEvents
        .filter((build) => {
            const matchesDamageType = filters.damage_type === -1 || build.damage_type === parseInt(filters.damage_type);
            const matchesDifficulty = filters.server_difficulty === -1 || build.server_difficulty === parseInt(filters.server_difficulty);
            const matchesBuildType = filters.build_type === -1 || build.build_type === parseInt(filters.build_type);
            const matchesTitle = build.title.toLowerCase().includes(filters.searchText.toLowerCase());

            return matchesDamageType && matchesDifficulty && matchesBuildType && matchesTitle;
        })
        .sort((a, b) => {
            if (sortOption === 'rating') {
                return sortOrder === 'asc' ? a.vote_score - b.vote_score : b.vote_score - a.vote_score;
            } else if (sortOption === 'date') {
                return sortOrder === 'asc'
                    ? new Date(a.date) - new Date(b.date)
                    : new Date(b.date) - new Date(a.date);
            }
            return 0; // Default case (no sorting)
        })
        .slice(0, MAX_BUILD_RESULTS);

    return (
        <>
            <ShowTitle
                noShow={true}
                title="Best Once Human Builds"
                description="Browse a vast collection of Once Human builds, meticulously crafted by the community. Find the perfect build to dominate the battlefield and achieve victory."
                keywords="Once Human build list, best Once Human builds, top Once Human builds, Once Human build database, Once Human build sharing, Once Human build community,Once Human game strategies,  build theorycrafting, Once Human character builds, Once Human build optimization, Once Human build optimalization"
            />
            <ContentBox sx={{ mb: 4 }}>
                <Grid container spacing={2} sx={{
                    color: 'black', // Set the text color to black
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black', // Set the border color to black (optional)
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black', // Focused state color
                    },
                }}>
                    <Grid item xs={12} lg={6} xl={3}>
                        <TextField
                            label="Search by Title"
                            sx={{
                                color: '#000', // Text color of the label
                                '& .MuiInputBase-input': {
                                    color: '#000', // Text color within the input field
                                },
                                '& .MuiFormLabel-root': {
                                    color: '#000', // Color of the label text
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#000', // Border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#000', // Border color on hover
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#000', // Border color when focused
                                    },
                                },
                            }}
                            variant="outlined"
                            fullWidth
                            value={filters.searchText}
                            onChange={(e) =>
                                setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    searchText: e.target.value,
                                }))
                            }
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} xl={3}>
                        <FormControl fullWidth>
                            <InputLabel id="build_type-label" sx={{ color: '#000' }}>Build Type</InputLabel>
                            <Select
                                labelId="build_type-label"
                                id="build_type"
                                name="build_type"
                                value={filters.build_type}
                                onChange={handleFilterChange}
                                label="Build Type"
                                defaultValue={-1}
                                sx={{
                                    color: '#000', // Text color of the label
                                    '& .MuiInputBase-input': {
                                        color: '#000', // Text color within the input field
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: '#000', // Color of the label text
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#000', // Border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#000', // Border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#000', // Border color when focused
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={-1} value={-1}>All</MenuItem>
                                {Object.entries(buildTypes).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={3}>
                        <FormControl fullWidth>
                            <InputLabel id="damage_type-label" sx={{ color: '#000' }}>Damage Type</InputLabel>
                            <Select
                                labelId="damage_type-label"
                                id="damage_type"
                                name="damage_type"
                                value={filters.damage_type}
                                onChange={handleFilterChange}
                                label="Damage Type"
                                defaultValue={-1}
                                sx={{
                                    color: '#000', // Text color of the label
                                    '& .MuiInputBase-input': {
                                        color: '#000', // Text color within the input field
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: '#000', // Color of the label text
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#000', // Border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#000', // Border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#000', // Border color when focused
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={-1}>All</MenuItem>
                                {Object.entries(damageTypes).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={3}>
                        <FormControl fullWidth>
                            <InputLabel id="server_difficulty-label" sx={{ color: '#000' }}>Server Difficulty</InputLabel>
                            <Select
                                labelId="server_difficulty-label"
                                id="server_difficulty"
                                name="server_difficulty"
                                value={filters.server_difficulty}
                                onChange={handleFilterChange}
                                label="Server Difficulty"
                                defaultValue={-1}
                                sx={{
                                    color: '#000', // Text color of the label
                                    '& .MuiInputBase-input': {
                                        color: '#000', // Text color within the input field
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: '#000', // Color of the label text
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#000', // Border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#000', // Border color on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#000', // Border color when focused
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={-1} value={-1}>All</MenuItem>
                                {Object.entries(serverDifficulties).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </ContentBox>
            <SimpleBox>
                <Grid container spacing={2}>
                    {/* Headers */}
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            {isSmallScreen ? (
                                <>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                                        Type
                                    </Grid>
                                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Name
                                    </Grid>
                                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Weapons
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                                        Type
                                    </Grid>
                                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        Name
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Rating
                                        <IconButton
                                            onClick={() => setSortOption('rating')}
                                            color={sortOption === 'rating' ? 'primary' : 'default'}
                                        >
                                            <SortByAlphaIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Weapons
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        PvE / PvP
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Difficulty
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        User
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', mr: 1 }}>
                                        Updated
                                        <IconButton
                                            onClick={() => setSortOption('date')}
                                            color={sortOption === 'date' ? 'primary' : 'default'}
                                        >
                                            <SortByAlphaIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Divider sx={{ border: '1px solid white', m: 1 }} />
                    </Grid>

                    {/* Filtered Builds */}
                    {filteredBuilds.length > 0 ? (
                        filteredBuilds.map((build) => (
                            <Grid item xs={12} key={build.id} sx={{ mb: 1 }}>
                                <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    {/* Small screen layout */}
                                    {isSmallScreen ? (
                                        <>
                                            <Grid item xs={6}>
                                                <Grid container alignItems="center">
                                                    <Button
                                                        component={Link}
                                                        to={`/builds/view/${build.id}`}
                                                        color="primary"
                                                        sx={{
                                                            textTransform: 'none',
                                                            textAlign: 'left',
                                                            justifyContent: 'flex-start',
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        <img
                                                            src={`/images/buildtypes/${build.build_type}.webp`}
                                                            alt={build.title}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                marginRight: '8px',
                                                            }}
                                                        />
                                                        <img
                                                            src={`/images/damagetypes/${build.damage_type}.webp`}
                                                            alt={build.title}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                marginRight: '8px',
                                                            }}
                                                        />
                                                        {build.title}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={2} sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <PercentageStar rating={build.vote_score}/>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img
                                                            src={`/images/gears/${build.main_weapon}.webp`}
                                                            alt={build.title}
                                                            style={{width: '100px', height: '50px', border: '2px solid black', marginRight: '2px'}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <img
                                                            src={`/images/gears/${build.secondary_weapon}.webp`}
                                                            alt={build.title}
                                                            style={{ width: '100px', height: '50px', border: '2px solid black' }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            {/* Full layout */}
                                            <Grid item xs={4}>
                                                <Grid container alignItems="center" className="wrap">
                                                    <Tooltip title={buildTypes[build.build_type]} arrow>
                                                        <img
                                                            src={`/images/buildtypes/${build.build_type}.webp`}
                                                            alt={build.title}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                border: '2px solid black',
                                                                marginRight: '2px',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title={damageTypes[build.damage_type]} arrow>
                                                        <img
                                                            src={`/images/damagetypes/${build.damage_type}.webp`}
                                                            alt={build.title}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                border: '2px solid black',
                                                                marginRight: '2px',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    <Button
                                                        component={Link}
                                                        to={`/builds/view/${build.id}`}
                                                        color="primary"
                                                        sx={{
                                                            textTransform: 'none',
                                                            textAlign: 'left',
                                                            justifyContent: 'flex-start',
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        {build.title}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <PercentageStar rating={build.vote_score}/>
                                            </Grid>
                                            <Grid item xs={2} sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img
                                                    src={`/images/gears/${build.main_weapon}.webp`}
                                                    alt={build.title}
                                                    style={{
                                                        width: '100px',
                                                        height: '50px',
                                                        border: '2px solid black',
                                                        marginRight: '2px'
                                                    }}
                                                />
                                                <img
                                                    src={`/images/gears/${build.secondary_weapon}.webp`}
                                                    alt={build.title}
                                                    style={{width: '100px', height: '50px', border: '2px solid black', marginRight: '2px'}}
                                                />
                                                <img
                                                    src={`/images/deviations/${build.deviation}.webp`}
                                                    alt={build.title}
                                                    style={{width: '50px', height: '50px', border: '2px solid black'}}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                {build.server_type === 0 ? 'PvE' : 'PvP'}
                                            </Grid>
                                            <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {serverDifficulties[build.server_difficulty]}
                                            </Grid>
                                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                                                {build.anonymous === 0 && (
                                                    <a className="link-effect" href={`/builds/user/${build.owner_id}`}>
                                                        {usernames[build.owner_id] || 'Anonymous'}
                                                    </a>
                                                )}
                                            </Grid>
                                            <Grid item xs={1} sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end'
                                            }}>
                                                {new Date(build.last_mod).toLocaleString('en-GB', {
                                                    year: '2-digit',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: false // Use 24-hour format
                                                })}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Divider sx={{ mt: 1 }} />
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No builds found
                        </Grid>
                    )}
                </Grid>
            </SimpleBox>
        </>
    );
}
