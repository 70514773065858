import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from "@mui/system";
import { Box, Divider, Typography } from "@mui/material";
import {useSessionData} from "../auth/SessionDataProvider";

const SimpleBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: 10,
    border: '2px solid black',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: 'auto',
    position: 'relative',
    boxShadow: `0px 0px 10px 0px #000`,
}));

function CreateNickname({ userNicks }) {
    const { sessionData } = useSessionData();
    const [nickname, setNickname] = useState('');
    const [nicknameError, setNicknameError] = useState('');
    const [currentNicks, setCurrentNicks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (userNicks && Array.isArray(userNicks)) {
            // Extract and filter out null nicknames
            const nicknames = userNicks
                .map(user => user.nickname)
                .filter(nick => nick !== null);
            setCurrentNicks(nicknames);
        }
    }, [userNicks]);

    const handleNicknameChange = (e) => {
        const value = e.target.value.trim();
        setNickname(value);

        // Check if the nickname is already taken
        if (value === '') {
            setNicknameError('Nickname cannot be empty.');
        } else if (value.length < 5 || value.length > 30) {
            setNicknameError('Nickname must be between 5 and 30 characters.');
        } else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
            setNicknameError('Nickname can only contain alphanumeric characters and underscores.');
        } else if (currentNicks.map(nick => nick.toLowerCase()).includes(value.toLowerCase())) {
            setNicknameError('Nickname is already taken.');
        } else {
            setNicknameError('');
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('nickname', nickname);

        try {
            const response = await axios.post('/api/user/createNickname', formData);
            if (response.data === 1) {
                window.location.href = '/'; // Reload the page
            }
        } catch (error) {
            console.log(error);
        }
    };

    if (sessionData.logged && sessionData.nickname === null) {
        return (
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} sx={{ p: 10 }}>
                <SimpleBox>
                    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2} sx={{ p: 5 }}>
                        <Grid item>
                            <Typography variant="h5">Please choose your Nickname!</Typography>
                        </Grid>
                        <Grid item><Divider sx={{ m: 1 }}/></Grid>
                        <Grid item>
                            <TextField
                                label="Enter your nickname"
                                value={nickname}
                                onChange={handleNicknameChange}
                                variant="outlined"
                                fullWidth
                                error={!!nicknameError}
                                helperText={nicknameError}
                                sx={{ width: '500px' }}
                            />
                        </Grid>
                        <Grid item><Divider sx={{ m: 1 }}/></Grid>
                        <Grid item>
                            <Button variant="contained" color="success" onClick={handleSubmit} disabled={!!nicknameError} sx={{ p: 2 }}>
                                Save Nickname
                            </Button>
                        </Grid>
                    </Grid>
                </SimpleBox>
            </Grid>
        );
    }

    return null;
}

export default CreateNickname;
