import React, {useEffect, useState} from 'react';
import { Autocomplete, TextField, ListItem, ListItemAvatar, ListItemText, Tooltip, Divider } from '@mui/material';
import CustomTooltip from "../util/CustomTooltip";

const UniversalSelector = ({
                               mainData,
                               inputs,
                               setInputs,
                               itemName,
                               itemTitle,
                               folder,
                               baseWidth = '50px',
                               errorSetter = null, // Initialize errorSetter to null
                               defaultValue,
                               required = true,
                               disabled = false,
                           }) => {
    const [error, setError] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        // Initialize selectedValue based on inputs or default to the first item
        if (!inputs[itemName] && mainData.length > 0) {
            const defaultItem = mainData[0];
            setSelectedValue(defaultItem);
            setInputs((prevInputs) => ({
                ...prevInputs,
                [itemName]: defaultItem.id,
            }));
        } else {
            setSelectedValue(mainData.find(option => option.id === inputs[itemName]) || null);
        }
    }, [inputs[itemName], mainData, itemName, setInputs]);

    useEffect(() => {
        // Notify parent component of current error state if errorSetter is provided
        if (errorSetter) {
            errorSetter(itemName, error);
        }
    }, [error, itemName, errorSetter]);

    const handleChange = (event, newValue) => {
        if (!newValue) {
            // Clear the selection and default to the first item in the list if available
            if (mainData.length > 0) {
                const defaultItem = mainData[0];
                setSelectedValue(defaultItem);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    [itemName]: defaultItem.id,
                }));
                setError(false); // Clear the error state
            }
        } else {
            setError(false);
            setSelectedValue(newValue);
            setInputs((prevInputs) => ({
                ...prevInputs,
                [itemName]: newValue.id,
            }));
        }
    };

    return (
        <Autocomplete
            fullWidth
            disabled={disabled}
            aria-required={required}
            id={itemName}
            name={itemName}
            value={selectedValue}
            onChange={handleChange}
            options={mainData}
            getOptionLabel={(option) => option.title}
            renderOption={(props, option) => (
                <>
                    <ListItem
                        {...props}
                        key={option.id} // Add key prop for list item
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                            },
                            backgroundColor: inputs[itemName] === option.id ? 'rgba(0, 0, 0, 0.14)' : 'transparent',
                            border: inputs[itemName] === option.id ? '2px solid #1976d2' : 'none',
                            borderRadius: '4px',
                        }}
                    >
                        { folder !== 'none' && (
                            <ListItemAvatar>
                                <CustomTooltip
                                    title={option.title}
                                    description={option.description}
                                    rarity={option.rarity || 5}
                                >
                                    <img
                                        src={`/images/${folder}/${option.id}.webp`}
                                        alt={option.title}
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevent infinite loop if the fallback image fails
                                            e.target.src = '/images/calibrations/seasonal.webp'; // Path to your default image
                                        }}
                                        style={{ marginRight: '1rem', width: baseWidth, height: '50px', border: '2px solid #000' }}
                                    />
                                </CustomTooltip>
                            </ListItemAvatar>
                        )}
                        <ListItemText primary={option.title} />
                    </ListItem>
                    <Divider sx={{ border: '1px solid #000' }} />
                </>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={itemTitle}
                    required={required}
                    error={errorSetter ? error : false} // Only show error if errorSetter is provided
                    helperText={error && errorSetter ? `${itemTitle} is required` : ""}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: folder !== 'none' && inputs[itemName] && (
                            <img
                                src={`/images/${folder}/${inputs[itemName]}.webp`}
                                alt={mainData.find(option => option.id === inputs[itemName])?.title || ''}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if the fallback image fails
                                    e.target.src = '/images/calibrations/seasonal.webp'; // Path to your default image
                                }}
                                style={{ marginRight: '8px', width: baseWidth, height: '50px', border: '2px solid #000' }}
                            />
                        ),
                    }}
                />
            )}
        />
    );
};

export default UniversalSelector;
