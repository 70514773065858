import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
    Tooltip,
    Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SimpleBox from "../util/SimpleBox";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import {useSessionData} from "../auth/SessionDataProvider";

export default function LatestListings() {
    const { sessionData } = useSessionData();
    const [listings, setListings] = useState([]);
    const [resources, setResources] = useState({});
    const [servers, setServers] = useState({});

    useEffect(() => {
        getTrades();
    }, []);

    async function getTrades() {
        try {
            const result = await axios.get('/api/get/latestListingsHome');
            if (result.data !== false) {
                console.log(result.data);
                setListings(result.data.listings);

                // Process resources
                const resourcesObject = result.data.resources.reduce((acc, resource) => {
                    acc[resource.id] = resource;
                    return acc;
                }, {});
                setResources(resourcesObject);

                // Process servers
                const serv = result.data.servers.reduce((acc, resource) => {
                    acc[resource.id] = resource;
                    return acc;
                }, {});
                setServers(serv);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function formatNumberWithSpaces(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    if (!listings) {
        return null;
    }

    return (
        <>
            <SimpleBox>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        fontVariant: 'small-caps',
                                        textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                    }}
                                >
                                    Trade Center fresh deals
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mr: 1 }}>
                                <Tooltip title="Find more">
                                    <IconButton to="/tradeCenter" component={Link} color="info">
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Divider sx={{ border: '1px solid white', m: 1 }} />
                    </Grid>

                    {listings.length > 0 ? (
                        listings.map((listing) => (
                            <Grid item xs={12} key={listing.id}>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6" sx={{ mr: 2 }}>
                                            {formatNumberWithSpaces(listing.item_sell_qty)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Tooltip title={`${resources[listing.item_sell_id]?.title}`} arrow>
                                            <img
                                                src={`/images/resources/${listing.item_sell_id}.webp`}
                                                alt={listing.comment || 'Item'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    border: '2px solid black',
                                                    marginRight: '2px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={2} lg={1}>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <DoubleArrowIcon/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {formatNumberWithSpaces(listing.item_buy_qty)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Tooltip title={`${resources[listing.item_buy_id]?.title}`} arrow>
                                            <img
                                                src={`/images/resources/${listing.item_buy_id}.webp`}
                                                alt={listing.comment || 'Item'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    border: '2px solid black',
                                                    marginRight: '2px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {servers[listing.server_id]?.title}
                                    </Grid>
                                    <Grid item xs={6} lg={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {listing.state === 4 ? (
                                            <>
                                                <Tooltip title={'Continous Supply'}>
                                                    <IconButton>
                                                        <AutorenewRoundedIcon color={'success'}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Button
                                                    onClick={() => window.location.href = `/trade/listing/${listing.id}`}
                                                    color="primary"
                                                >
                                                    {`Check deal`}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Tooltip title={
                                                    listing.state === 1 ? 'Available' :
                                                        listing.state === 2 ? 'Buyer Applied' :
                                                            listing.state === 3 ? 'Reported Missing' : 'Inactive'
                                                }>
                                                    <IconButton>
                                                        <LightbulbRoundedIcon color={
                                                            listing.state === 1 ? 'success' :
                                                                listing.state === 2 ? 'warning' :
                                                                    listing.state === 3 ? 'error' : '#fff'
                                                        } />
                                                    </IconButton>
                                                </Tooltip>

                                                {/* Conditional Tooltip based on login status */}
                                                {sessionData.isLogged ? (
                                                    <Button
                                                        onClick={() => window.location.href = `/trade/listing/${listing.id}`}
                                                        color="primary"
                                                    >
                                                        {`Check deal`}
                                                    </Button>
                                                ) : (
                                                    <Tooltip title="You need to log in to check this deal">
                                                        <span>
                                                            <Button
                                                                color="primary"
                                                                disabled
                                                            >
                                                                {`Check deal`}
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Divider/>
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No listings found
                        </Grid>
                    )}
                </Grid>
            </SimpleBox>
        </>
    );
}
