import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    styled,
    LinearProgress,
    Button,
    Collapse, Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RequireAdmin from "../auth/RequireAdmin";
import { Link } from "react-router-dom";
import ImageWithShadow from "./ImageWithShadow";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CelebrationIcon from '@mui/icons-material/Celebration';
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import SimpleBox from "./SimpleBox";
import ChatIcon from '@mui/icons-material/Chat';
import BuildIcon from '@mui/icons-material/Build';

// Progress bar with striped background
const StripedLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 30,
    borderRadius: 5,
    backgroundColor: '#e0e0e0',
    boxShadow: `0px 3px 10px 0px #000`,
    '& .MuiLinearProgress-bar': {
        backgroundImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent)',
        backgroundSize: '4rem 4rem',
        animation: 'progress-bar-stripes 2s linear infinite',
        backgroundColor: value > 80 ? 'red' : value > 50 ? 'orange' : 'green',
    },
    '@keyframes progress-bar-stripes': {
        '0%': { backgroundPosition: '0 0' },
        '100%': { backgroundPosition: '4rem 0' },
    },
}));

const MyProgressBar = ({ value }) => (
    <Box sx={{ width: '100%' }}>
        <StripedLinearProgress variant="determinate" value={value} />
    </Box>
);

const categoryIcons = {
    0: { title: 'Battlepass', icon: <LocalActivityIcon /> },
    1: { title: 'Event', icon: <CelebrationIcon /> },
    2: { title: 'News', icon: <ArticleRoundedIcon /> },
    3: { title: 'DLC', icon: <ExtensionRoundedIcon /> },
    4: { title: 'Patch Notes', icon: <BuildIcon /> },
    5: { title: 'Dev-Talks', icon: <ChatIcon /> }
};

function renderCategoryIcon(event) {
    const category = categoryIcons[event.category];
    return category ? (
        <Tooltip title={category.title}>
            <IconButton>
                {category.icon}
            </IconButton>
        </Tooltip>
    ) : null;
}

const EventCardSubTypeOne = ({ event, extendedEvent, timeRemainingText, eventType, handleCopyClick, progressPercentage, localStartDate, localEndDate, shadowColor, setShadowColor, expanded, setExpanded, contentLoaded }) => {
    const logoUrl = "/images/feed/" + event.category + ".webp";

    return (
        <SimpleBox sx={{ mb: 2,  ml: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <ImageWithShadow src={logoUrl} alt={event.title} gameTitle={eventType} shadowColor={shadowColor} setShadowColor={setShadowColor} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography
                        variant="h5"
                        component="div"
                        gutterBottom
                        sx={{
                            textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                        }}
                    >
                        {renderCategoryIcon(event)}
                        {event.title}
                    </Typography>
                    <MyProgressBar value={extendedEvent.progressPercentage} id={`event-progress-bar-${event.id}`} aria-label={event.title} />
                </Grid>
                <Grid item xs={12} md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <RequireAdmin>
                        <Button component={Link} to={`https://season-tracker.com/editEvent/${event.id}`} variant="outlined" sx={{ ml: 2 }}>
                            <EditIcon />
                        </Button>
                    </RequireAdmin>
                    <Tooltip title={`Copy event address`}>
                        <IconButton onClick={handleCopyClick}>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    {(event.youtube_trailer || event.description || event.youtube_showcase) && (
                        <Button onClick={() => setExpanded(!expanded)} variant={expanded ? 'contained' : 'outlined'} sx={{ mt: 2 }}>
                            <RemoveRedEyeIcon />
                        </Button>
                    )}
                </Grid>
                <Grid xs={12}>
                    <Collapse in={expanded} >
                        {contentLoaded && (
                            <>
                                {event.youtube_trailer && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Trailer
                                        </Typography>
                                        <hr />
                                        <iframe
                                            width="100%"
                                            height="500px"
                                            src={`https://www.youtube.com/embed/${event.youtube_trailer}`}
                                            title="YouTube"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                )}
                                {event.description && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Description
                                        </Typography>
                                        <hr />
                                        <Typography variant="body2" color="text.secondary">
                                            <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                                        </Typography>
                                    </Box>
                                )}
                                {event.youtube_showcase && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Showcase
                                        </Typography>
                                        <hr />
                                        <iframe
                                            width="100%"
                                            height="500px"
                                            src={`https://www.youtube.com/embed/${event.youtube_showcase}`}
                                            title="YouTube"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                )}
                            </>
                        )}
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid>
                            <Typography variant="h6" color="text.secondary">
                                {event.event_start_est === 1 && (
                                    <Tooltip title={`Not officially confirmed yet!`}>
                                        <IconButton>
                                            <InfoOutlinedIcon color="info" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {localStartDate}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="h5" color="text.secondary">
                                {timeRemainingText}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="h6" color="text.secondary">
                                {localEndDate}
                                {event.event_end_est === 1 && (
                                    <Tooltip title={`Not officially confirmed yet!`}>
                                        <IconButton>
                                            <InfoOutlinedIcon color="info" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default EventCardSubTypeOne;
