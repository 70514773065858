import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
    FormControl, MenuItem,
    Select,
    Tooltip,
} from "@mui/material";
import axios from "axios";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import ContentBox from "../util/ContentBox";
import SimpleBox from "../util/SimpleBox";
import {useMediaQuery} from "@mui/system";
import LatestBuilds from "./LatestBuilds";
import OnceHumanEvents from "./OnceHumanEvents";
import DataSearchComponent from "./DataSearchComponent";
import Codex from "./Codex";
import CodexSmall from "./CodexSmall";
import TopRatedBuilds from "./TopRatedBuilds";
import ShowTitle from "../util/ShowTitle";
import WidgetBotEmbed from "../util/WidgetBotEmbed";
import LatestListings from "../tradecenter/LatestListings";

export default function MainPage({ data }) {

    return (
        <>
            <ShowTitle
                noShow={true}
                title="Main Hub"
                description="Discover the ultimate hub for Once Human players. Create, share builds, theorycraft, read news, and explore the comprehensive item codex."
                keywords="Once Human main site, game builds, build sharing, build theorycrafting, Once Human news, item codex, game strategies"
            />
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} xl={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TopRatedBuilds data={data} />
                        </Grid>
                        <Grid item xs={12}>
                            <LatestBuilds data={data} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Grid item xs={12}  sx={{ mb: 2 }}>
                        <LatestListings />
                    </Grid>
                    <Grid item xs={12}  sx={{ mb: 2 }}>
                        <CodexSmall data={data} />
                    </Grid>
                    <OnceHumanEvents data={data} />
                </Grid>
                <WidgetBotEmbed/>
            </Grid>
        </>
    );
}
