import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
    Typography,
    Tooltip,
    IconButton,
} from "@mui/material";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import SimpleBox from "../util/SimpleBox";
import ContentBox from "../util/ContentBox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MapWithPins from "./MapWithPins";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ListItemText from "@mui/material/ListItemText";
import {useSessionData} from "../auth/SessionDataProvider";
import RequireAuthLogInWarning from "../auth/RequireAuthLogInWarning";

export default function ViewListing() {
    const { id } = useParams();
    const { sessionData } = useSessionData();
    const [listing, setListing] = useState(null);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTrade();
        getResources();
    }, []);

    function getTrade() {
        axios.get(`/api/get/listing/${id}`)
            .then((result) => {
                if (result.data !== false) {
                    console.log(result.data);
                    setListing(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getResources() {
        axios.get(`/api/list/resources`)
            .then((result) => {
                if (result.data !== false) {
                    setResources(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleBuy = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('listing_id', id);
        formData.append('state', 2);
        axios.post('/api/update/listing', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setListing((prevInputs) => ({
                        ...prevInputs,
                        state: 2,
                    }));
                }
            })
    };

    const handleReport = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('listing_id', id);
        formData.append('state', 3);
        axios.post('/api/update/listing', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setListing((prevInputs) => ({
                        ...prevInputs,
                        state: 3,
                    }));
                }
            })
    };

    if (listing === null) {
        return <SimpleBox>Loading...</SimpleBox>;
    }

    // Find resource names for display
    const resource = resources.find(resource => resource.id === listing.item_sell_id);
    const exchangeResource = resources.find(resource => resource.id === listing.item_buy_id);

    return (
        <>
            <RequireAuthLogInWarning passThrough={listing.state === 4}>
            <SimpleBox>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: 1 }}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        fontVariant: 'small-caps',
                                        textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                    }}
                                >
                                    View Trade details ({listing.state === 1 ? 'Available' : listing.state === 2 ? 'Buyer Applied' : listing.state === 3 ? 'Reported Missing' : 'Continuous Supply'})
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ border: '1px solid white', m: 1 }} />
                    </Grid>
                    <Grid item xs={12} lg={6} >
                        <Typography variant="h5" component="h2">
                            Item for Sale
                        </Typography>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}>
                            <img
                                src={`/images/resources/${listing.item_sell_id}.webp`}
                                alt={listing.comment || 'Item'}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    border: '2px solid black',
                                    marginRight: '10px',
                                }}
                            />
                            {resource ? resource.title : "Resource not found"} {listing.item_sell_qty}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h5" component="h2">
                            Exchange For
                        </Typography>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}>
                            <img
                                src={`/images/resources/${listing.item_buy_id}.webp`}
                                alt={listing.comment || 'Item'}
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    border: '2px solid black',
                                    marginRight: '10px',
                                }}
                            />
                            {exchangeResource ? exchangeResource.title : "Resource not found"} {listing.item_buy_qty}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}>
                            {listing.comment}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                            <Grid item xs={12}
                                  sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', mt: 3}}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    gutterBottom
                                    sx={{
                                        fontVariant: 'small-caps',
                                        textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                    }}
                                >
                                    Location ({listing.coordinate_x}, {listing.coordinate_y})
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ border: '1px solid white', m: 1 }} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h6">
                            Server: {listing.server_name}
                        </Typography>
                        <Typography variant="h6">
                            World {listing.world}
                        </Typography>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Coordinates: <br/>
                            X: {listing.coordinate_x} <br/>
                            Y: {listing.coordinate_y}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MapWithPins initialCoords={{ x: listing.coordinate_x, y: listing.coordinate_y }}/>
                    </Grid>
                    {listing.state === 1  && (
                        <>
                            <Grid item xs={12} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2}}>
                                <Button
                                    onClick={handleReport}
                                    color="error"
                                    variant="contained"
                                    disabled={loading}
                                    sx={{ p: 2 }}
                                >
                                    Report Missing Item
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    onClick={handleBuy}
                                    fullWidth
                                    color="success"
                                    variant="contained"
                                    disabled={loading}
                                    sx={{ p: 2 }}
                                >
                                    Buy Item
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </SimpleBox>
        </RequireAuthLogInWarning>
        </>
    );
}
