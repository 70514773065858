import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
    FormControl, MenuItem,
    Select,
    Tooltip,
} from "@mui/material";
import axios from "axios";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import ContentBox from "../util/ContentBox";
import SimpleBox from "../util/SimpleBox";
import {useMediaQuery} from "@mui/system";
import LatestBuilds from "./LatestBuilds";
import OnceHumanEvents from "./OnceHumanEvents";
import ShowTitle from "../util/ShowTitle";

export default function NewsPage({ data, isSmallScreen }) {

    return (
        <>
            <ShowTitle
                noShow={true}
                title="Fresh News"
                description="Stay updated with the latest news and announcements for Once Human. Get the latest updates, event news, and game improvements."
                keywords="Once Human news, game updates, Once Human announcements, game events, latest news, Once Human patches, Once Human Dev Talks, Once Human sneak peek"
            />
            <OnceHumanEvents data={data} isSmallScreen={isSmallScreen} />
        </>
    );
}
