import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
    Divider, Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";
import DarkTooltip from "../util/DarkTooltip";
import axios from "axios";

export default function AdminMenu() {
    const [isProcessed, setIsProcessed] = useState(false);

    const handleCsvToDb = () => {
        if (!isProcessed) {
            axios.post('/api/create/csvToDb')
                .then(function (response) {
                    console.log(response);
                });
            setIsProcessed(true);
        }
    };

    return (
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        Admin menu
                    </Typography>
                    <Divider sx={{ m: 1 }} />
                    <ListItem>
                        <Button
                            to="/addLocation"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Location" />
                        </Button>
                    </ListItem>

                    {/* Add Set Button */}
                    <ListItem>
                        <Button
                            to="/addSet"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Set" />
                        </Button>
                    </ListItem>

                    {/* Add Gear Button */}
                    <ListItem>
                        <Button
                            to="/addGear"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Gear" />
                        </Button>
                    </ListItem>

                    {/* Add Mod Button */}
                    <ListItem>
                        <Button
                            to="/addMod"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Mod" />
                        </Button>
                    </ListItem>

                    {/* Add Cradle Button */}
                    <ListItem>
                        <Button
                            to="/addCradle"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Cradle" />
                        </Button>
                    </ListItem>

                    {/* Add Calibration Button */}
                    <ListItem>
                        <Button
                            to="/addCalibration"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Calibration" />
                        </Button>
                    </ListItem>

                    {/* Add Stat Button */}
                    <ListItem>
                        <Button
                            to="/addStat"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Stat" />
                        </Button>
                    </ListItem>

                    {/* Add Build Type Button */}
                    <ListItem>
                        <Button
                            to="/addBuildType"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Build Type" />
                        </Button>
                    </ListItem>

                    {/* Add Anomaly Button */}
                    <ListItem>
                        <Button
                            to="/addAnomaly"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Anomaly" />
                        </Button>
                    </ListItem>

                    {/* Add Server Difficulty Button */}
                    <ListItem>
                        <Button
                            to="/addServerDifficulty"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Server Difficulty" />
                        </Button>
                    </ListItem>

                    <ListItem>
                        <Button
                            to="/addDamageType"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Damage Type" />
                        </Button>
                    </ListItem>

                    <ListItem>
                        <Button
                            to="/addDeviation"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Deviation" />
                        </Button>
                    </ListItem>

                    <ListItem>
                        <Button
                            to="/addResource"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Resource" />
                        </Button>
                    </ListItem>

                    <ListItem>
                        <Button
                            to="/addSecondaryStat"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <AddIcon fontSize="large" />
                            </ListItemIcon>
                            <ListItemText primary="Add Secondary Stat" />
                        </Button>
                    </ListItem>

                    {/*<ListItem>*/}
                    {/*    <Button*/}
                    {/*        onClick={handleCsvToDb}*/}
                    {/*        sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}*/}
                    {/*    >*/}
                    {/*        <ListItemIcon>*/}
                    {/*            <AddIcon fontSize="large" />*/}
                    {/*        </ListItemIcon>*/}
                    {/*        <ListItemText primary="Csv To Db" />*/}
                    {/*    </Button>*/}
                    {/*</ListItem>*/}

                    <Divider sx={{ m: 1 }} />
                </Grid>
            </Grid>
        </>
    );
}
