import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider,
} from "@mui/material";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import SimpleBox from "../util/SimpleBox";

export default function BuildsByUser({ data }) {
    const { id } = useParams();
    const [sortedBuilds, setSortedBuilds] = useState([]);

    useEffect(() => {
        getBuilds();
    }, [data]);

    function getBuilds() {
        axios.post(`/api/get/buildsByUser/${id}`)
            .then((result) => {
                if (result.data !== false) {
                    console.log(result.data);
                    setSortedBuilds(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <SimpleBox>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                                Type
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                Name
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                Weapons
                            </Grid>
                        </Grid>
                        <Divider sx={{ border: '1px solid white', m: 1 }} />
                    </Grid>

                    {/* Filtered Builds */}
                    {sortedBuilds.length > 0 ? (
                        sortedBuilds.map((build) => (
                            <Grid item xs={12} key={build.id} sx={{ mb: 1 }}>
                                <Grid container spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Button
                                                component={Link}
                                                to={`/builds/view/${build.id}`}
                                                color="primary"
                                                sx={{
                                                    textTransform: 'none',
                                                    textAlign: 'left',
                                                    justifyContent: 'flex-start',
                                                    flexGrow: 1,
                                                }}
                                            >
                                                <img
                                                    src={`/images/buildtypes/${build.build_type}.webp`}
                                                    alt={build.title}
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        border: '2px solid black',
                                                        marginRight: '8px',
                                                    }}
                                                />
                                                {build.title}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img
                                                    src={`/images/gears/${build.main_weapon}.webp`}
                                                    alt={build.title}
                                                    style={{width: '100px', height: '50px', border: '2px solid black'}}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}  sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img
                                                    src={`/images/gears/${build.secondary_weapon}.webp`}
                                                    alt={build.title}
                                                    style={{ width: '100px', height: '50px', border: '2px solid black' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 1 }} />
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No builds found
                        </Grid>
                    )}
                </Grid>
            </SimpleBox>
        </>
    );
}
