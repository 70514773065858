import React, { useEffect } from 'react';

const WidgetBotEmbed = () => {
    useEffect(() => {
        // Function to initialize WidgetBot
        const loadWidgetBot = () => {
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/@widgetbot/crate@3';
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (window.Crate) {
                    new window.Crate({
                        server: '1283729792927142000',
                        channel: '1283729793682243585',
                    });
                }
            };
            document.body.appendChild(script);

            // Cleanup function to remove the script
            return () => {
                document.body.removeChild(script);
            };
        };

        loadWidgetBot();
    }, []);

    return (
            <div id="widgetbot-container" />
    );
};

export default WidgetBotEmbed;
