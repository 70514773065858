import * as React from 'react';
import Rating from '@mui/material/Rating';
import { Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const labels = {
    0.5: 'F',
    1: 'D',
    1.5: 'D+',
    2: 'C',
    2.5: 'C+',
    3: 'B',
    3.5: 'B+',
    4: 'A',
    4.5: 'A+',
    5: 'S',
};

function getLabelText(value) {
    return `${value} ${labels[value]}`;
}

function getClosestLabel(value) {
    // Find the closest label for the given value
    const closest = Object.keys(labels).reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    return labels[closest];
}

export default function DisplayRating({ rating, votes }) {
    const adjustedRating = rating === 0 ? 3 : Number(rating);
    const roundedRating = Number(adjustedRating).toFixed(1);

    return (
        <div style={{ display: 'flex', alignItems: 'center'}}>
            <Rating
                name="display-rating"
                value={parseFloat(roundedRating)}
                precision={0.1}
                readOnly
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            <Typography variant="h6" sx={{ fontStyle: 'italic', ml: 2, mr: 2 }}>
                ({votes}) {getClosestLabel(parseFloat(roundedRating))}
            </Typography>
        </div>
    );
}
