import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";
import {Autocomplete, MenuItem, Select, TextareaAutosize} from "@mui/material";
import {Editor} from "@tinymce/tinymce-react";
import ImageUpload from "../util/ImageUpload";

export default function AddResource() {
    const [inputs, setInputs] = useState({});
    const [resources, setResources] = useState([]);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        setImageUrl(inputs.image instanceof Blob ? URL.createObjectURL(inputs.image) : inputs.image);
    }, [inputs]);


    useEffect(() => {
        getResources();
        setInputs((prevInputs) => ({
            ...prevInputs,
            rarity: 0,
            inputs: '',
        }));
    }, []);


    function getResources() {
        axios.get('/api/list/resources')
            .then((result) => {
                if (result.data !== false) {
                    console.log(result.data);
                    setResources(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('image', imageFile);
        // axios.post('/api/update/resource', formData)
        axios.post('/api/create/resource', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        title: '',
                    }));
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Resource</h3>
                    </InputLabel>
                    <Grid sx={{mb: 2}}>
                        <ImageUpload data={inputs} setData={setInputs} setImageFile={setImageFile} cropWidth={62} cropHeight={62}/>
                        {imageUrl && (
                        <img src={imageUrl} alt="main"/>
                            )}
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <Grid sx={{mb: 2}}>
                            <TextField
                                fullWidth
                                id="title"
                                label="Name"
                                name="title"
                                value={inputs.title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sx={{mb: 2}}>
                            <InputLabel id="rarity-label" sx={{ mt: 3 }}>Rarity</InputLabel>
                            <Select
                                id="rarity"
                                name="rarity"
                                labelId="rarity-label"
                                value={inputs.rarity}
                                onChange={handleChange}
                                fullWidth
                                label="rarity"
                                defaultValue={0}
                            >
                                <MenuItem key={0} value={0}> Grey </MenuItem>
                                <MenuItem key={1} value={1}> Uncommon </MenuItem>
                                <MenuItem key={2} value={2}> Rare </MenuItem>
                                <MenuItem key={3} value={3}> Epic </MenuItem>
                                <MenuItem key={5} value={5}> Legendary </MenuItem>
                                <MenuItem key={6} value={6}> Mythic </MenuItem>
                            </Select>
                        </Grid>
                        {/*<Autocomplete*/}
                        {/*    id="resource"*/}
                        {/*    options={resources} // The array of resource objects*/}
                        {/*    getOptionLabel={(option) => option.title || ''} // Handle undefined title*/}
                        {/*    value={resources.find(resource => resource.id === inputs.resource) || null} // Ensure value is a resource object, not an id*/}
                        {/*    onChange={(event, newValue) => {*/}
                        {/*        handleChange({*/}
                        {/*            target: {*/}
                        {/*                name: 'resource',*/}
                        {/*                value: newValue ? newValue.id : null // Update the value with resource id*/}
                        {/*            }*/}
                        {/*        });*/}
                        {/*    }}*/}
                        {/*    renderInput={(params) => (*/}
                        {/*        <TextField*/}
                        {/*            {...params}*/}
                        {/*            label="Resource"*/}
                        {/*            fullWidth*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                    </Grid>
                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity}/>
            </Container>
        </>
    );
}
