import React, { useRef, useEffect } from 'react';
import Button from '@mui/material/Button';

const CustomFileInput = ({ handleImageChange, customText }) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && isValidImage(file)) {
            handleImageChange(e);
        } else {
            alert('Please select a valid image file.');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && isValidImage(file)) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imageData = event.target.result;
                handleImageChange({ target: { files: [dataURLtoFile(imageData, file.name)] } });
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please drop a valid image file.');
        }
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file' && isValidImage(items[i].getAsFile())) {
                const file = items[i].getAsFile();
                const reader = new FileReader();
                reader.onload = (event) => {
                    const imageData = event.target.result;
                    handleImageChange({ target: { files: [dataURLtoFile(imageData, file.name)] } });
                };
                reader.readAsDataURL(file);
                break;
            }
        }
    };

    const isValidImage = (file) => {
        if (!file) return false;
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return allowedTypes.includes(file.type);
    };

    const dataURLtoFile = (dataUrl, fileName) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };

    // Add event listener on mount and clean up on unmount
    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
                width: '1100px',
                border: '2px dashed #2196f3',
                borderRadius: '7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                boxSizing: 'border-box',
            }}
        >
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
            <Button color="info" variant="contained" fullWidth sx={{ p: 1 }} onClick={handleClick}>
                {customText}
            </Button>
        </div>
    );
};

export default CustomFileInput;
