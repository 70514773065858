import React, {useEffect} from "react";

export default function GoogleSignInButton() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: "1055892000197-q7nv667nq9k7h5t45tv85jt1j2qencbs.apps.googleusercontent.com",
                callback: window.handleCredentialResponse,
            });

            const buttonContainer = document.getElementById('google-button');

            if (buttonContainer) {
                window.google.accounts.id.renderButton(
                    buttonContainer,
                    {
                        theme: "outline",
                        size: "large",
                    }
                );
            }
        };
        document.body.appendChild(script);
    }, []);

    return (
        <div id="google-button"></div>
    );
}
