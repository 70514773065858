import React, {useState} from 'react';
import {Drawer, Grid, Tooltip, Typography} from '@mui/material';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SignInWithGoogle from "../auth/SignInWithGoogle";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ListItemText from "@mui/material/ListItemText";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import SearchIcon from "@mui/icons-material/Search";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RequireAuth from "../auth/RequireAuth";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import RequireAdmin from "../auth/RequireAdmin";
import AddIcon from "@mui/icons-material/Add";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import {useSessionData} from "../auth/SessionDataProvider";
import axios from "axios";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QueueRoundedIcon from '@mui/icons-material/QueueRounded';

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

function stringAvatar(name) {
    if (name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name[0]}${name[1] || ''}`, // Use the first two letters
        };
    }
    return {};
}

const SidebarDrawer = ({ isSmallScreen, loading }) => {
    const { sessionData } = useSessionData();
    const [drawerOpen, setDrawerOpen] = useState(!isSmallScreen); // State for drawer
    const [rotationAngle, setRotationAngle] = useState(isSmallScreen ? 90 : 0);

    const LogOut = ()=>{
        localStorage.removeItem('email');
        localStorage.removeItem('userName');
        localStorage.removeItem('rights');
        localStorage.clear();
        axios.post('/api/user/close_session')
            .then(()=>{
                window.location.href = '/';
            })
    }

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleToggleDrawer = () => {
        toggleDrawer(); // Call the external drawer toggle
        setRotationAngle(prevAngle => prevAngle + 90); // Increase the angle by 90 degrees on each click
    };

    return (
        <Drawer
            variant="permanent"
            open={drawerOpen}
            sx={{
                width: drawerOpen ? 240 : 65,
                backgroundColor: 'rgba(29, 29, 29, 0.7)',
                '& .MuiDrawer-paper': {
                    width: drawerOpen ? 240 : 65,
                    backgroundColor: 'rgba(29,29,29,0.95)',
                    backgroundImage: `linear-gradient(to top, rgba(92, 232, 237, 0.1), rgba(0, 0, 0, 0))`,
                    transition: 'width 0.3s',
                },
            }}
        >
            <Grid sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: drawerOpen ? 'center' : 'flex-start',
                justifyContent: 'center',
            }}>
                <Button
                    component={Link}
                    to="/"
                    onClick={() => setRotationAngle(prevAngle => prevAngle + 90)}
                    fullWidth
                    sx={{
                        p: 0,
                        minWidth: 0,
                        mb: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: drawerOpen ? 'center' : 'flex-start',
                        justifyContent: 'center',
                        transition: 'height 0.3s ease-in-out',
                        '& img': {
                            width: drawerOpen ? '170px' : '50px',
                            height: drawerOpen ? '170px' : '50px',
                            transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out, transform 0.3s ease-in-out',
                            transform: `rotate(${rotationAngle}deg)`, // Dynamic rotation based on state
                        },
                    }}
                >
                    <img src="/logo192.png" alt="Season-Tracker logo" className="img-no-style" style={{ marginTop: '20px', alignSelf: 'center' }} />
                </Button>
                <Tooltip
                    title={drawerOpen ? "Close" : "Open"}
                    arrow
                    sx={{
                        color: 'black',
                        backgroundColor: 'black',
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: 'black',
                            color: 'white',
                            fontSize: '0.75rem', // Adjust font size as needed
                            padding: '8px',
                        },
                        '& .MuiTooltip-arrow': {
                            color: 'black', // Arrow color
                        },
                    }}
                >
                    <Button
                        fullWidth
                        onClick={handleToggleDrawer}
                        sx={{
                            p: 0,
                            minWidth: 0,
                            mb: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <MenuOpenIcon
                            fontSize="large"
                            sx={{
                                mb: 1,
                                mt: 1,
                                alignSelf: 'center',
                                width: '45px',
                                height: '45px',
                                transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out, transform 0.3s ease-in-out',
                                transform: drawerOpen ? 'rotate(0deg)' : 'rotate(180deg)', // Icon rotation
                            }}
                        />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid sx={{ m: 1}}>
                {!loading && (
                    <div>
                        {sessionData.userId === 0 ? (
                            <SignInWithGoogle />
                        ) : (
                            <div style={{ padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar {...stringAvatar(`${sessionData.nickname}`)} />
                                {drawerOpen && <Typography variant="h6" style={{ marginLeft: 8 }}>Welcome, {sessionData.nickname}!</Typography>}
                            </div>
                        )}
                    </div>
                )}
            </Grid>
            <Divider sx={{m: 2}}/>
            <List>
                {/* Home Button */}
                <ListItem sx={{ mb: 1, p: 0 }}>
                    <Button
                        to="/"
                        component={Link}
                        sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                    >
                        <ListItemIcon>
                            <HomeRoundedIcon fontSize="large" />
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary="Home" />}
                    </Button>
                </ListItem>

                <ListItem sx={{ mb: 1, p: 0 }}>
                    <Button
                        to="/news"
                        component={Link}
                        sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                    >
                        <ListItemIcon>
                            <ArticleRoundedIcon fontSize="large" />
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary="News" />}
                    </Button>
                </ListItem>

                <ListItem sx={{ mb: 1, p: 0 }}>
                    <Button
                        to="/tradeCenter"
                        component={Link}
                        sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                    >
                        <ListItemIcon>
                            <LocalShippingIcon fontSize="large" />
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary="Trade Center" />}
                    </Button>
                </ListItem>

                <ListItem sx={{ mb: 1, p: 0 }}>
                    <Button
                        to="/builds"
                        component={Link}
                        sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                    >
                        <ListItemIcon>
                            <SearchIcon fontSize="large" />
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary="Search Builds" />}
                    </Button>
                </ListItem>

                <ListItem sx={{ mb: 1, p: 0 }}>
                    <Button
                        to="/buildPlanner"
                        component={Link}
                        sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                    >
                        <ListItemIcon>
                            <ConstructionRoundedIcon fontSize="large" />
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary="Build Planner" />}
                    </Button>
                </ListItem>

                <ListItem sx={{ mb: 1, p: 0 }}>
                    <Button
                        to="/codex"
                        component={Link}
                        sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                    >
                        <ListItemIcon>
                            <MenuBookIcon fontSize="large" />
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary="Codex" />}
                    </Button>
                </ListItem>

                <RequireAuth>
                    <ListItem sx={{ mb: 1, p: 0 }}>
                        <Button
                            to={`/builds/user/${sessionData.userId}`}
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <MyLocationIcon fontSize="large" />
                            </ListItemIcon>
                            {drawerOpen && <ListItemText primary="My Builds" />}
                        </Button>
                    </ListItem>

                    <ListItem sx={{ mb: 1, p: 0 }}>
                        <Button
                            to="/trade/listings"
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <QueueRoundedIcon fontSize="large" />
                            </ListItemIcon>
                            {drawerOpen && <ListItemText primary="My Listings" />}
                        </Button>
                    </ListItem>
                </RequireAuth>

                <RequireAdmin>
                    <Divider sx={{ m: 1 }} />
                    <ListItem sx={{ mb: 1, p: 0 }}>
                        <Button
                            to={`/adminMenu`}
                            component={Link}
                            sx={{ width: '100%', display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}
                        >
                            <ListItemIcon>
                                <MyLocationIcon fontSize="large" />
                            </ListItemIcon>
                            {drawerOpen && <ListItemText primary="Admin Menu" />}
                        </Button>
                    </ListItem>
                </RequireAdmin>
            </List>


            {sessionData.userId !== 0 && (
                    <Button color="primary" onClick={LogOut} sx={{ position: 'absolute', bottom: 10, display: 'flex', justifyContent: drawerOpen ? 'flex-start' : 'center', textAlign: 'left' }}>
                        <LogoutRoundedIcon fontSize="small"/>
                        {drawerOpen && <ListItemText primary="Logout" />}
                    </Button>
            )}
        </Drawer>
    );
};

export default SidebarDrawer;
