import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    styled,
    LinearProgress,
    Button,
    Collapse, Tooltip, Dialog,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RequireAdmin from "../auth/RequireAdmin";
import { Link } from "react-router-dom";
import ImageWithShadow from "./ImageWithShadow";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CelebrationIcon from '@mui/icons-material/Celebration';
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import SimpleBox from "./SimpleBox";
import ChatIcon from '@mui/icons-material/Chat';
import BuildIcon from '@mui/icons-material/Build';
import Divider from "@mui/material/Divider";

const categoryIcons = {
    0: { title: 'Battlepass', icon: <LocalActivityIcon /> },
    1: { title: 'Event', icon: <CelebrationIcon /> },
    2: { title: 'News', icon: <ArticleRoundedIcon /> },
    3: { title: 'DLC', icon: <ExtensionRoundedIcon /> },
    4: { title: 'Patch Notes', icon: <BuildIcon /> },
    5: { title: 'Dev-Talks', icon: <ChatIcon /> }
};

function renderCategoryIcon(event) {
    const category = categoryIcons[event.category];
    return category ? (
        <Tooltip title={category.title}>
            <IconButton>
                {category.icon}
            </IconButton>
        </Tooltip>
    ) : null;
}

const EventCardSubTypeTwo = ({ event, eventType, handleCopyClick, progressPercentage, localStartDate, localEndDate, shadowColor, setShadowColor, expanded, setExpanded, contentLoaded }) => {
    const logoUrl = "/images/feed/" + event.category + ".webp";
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClickImage = (e) => {
        if (e.target.tagName === 'IMG') {
            setSelectedImage(e.target.src); // Get the clicked image's src
            setOpen(true); // Open the dialog
        }
    };

    const handleClose = () => {
        setOpen(false); // Close the dialog
        setSelectedImage(null); // Reset the selected image
    };

    return (
        <SimpleBox sx={{ mb: 2, ml: 2 }}>
            <Grid container spacing={2} className="wrap">
                <Grid item xs={12} md={2}>
                    <ImageWithShadow src={logoUrl} alt={event.title} gameTitle={eventType} shadowColor={shadowColor} setShadowColor={setShadowColor} />
                </Grid>
                <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography
                        variant="h5"
                        component="div"
                        gutterBottom
                        sx={{
                            textShadow: '0px 2px 10px rgba(0, 0, 0, 1)' // Applying textShadow with RGBA color
                        }}
                    >
                        {renderCategoryIcon(event)}
                        {event.title}
                    </Typography>
                    <Divider sx={{ border: '1px solid #fff', width: '90%', mt: 3, boxShadow: '0px 2px 10px rgba(0, 0, 0, 1)' }}  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <RequireAdmin>
                        <Button component={Link} to={`https://season-tracker.com/editEvent/${event.id}`} variant="outlined" sx={{ ml: 2 }}>
                            <EditIcon />
                        </Button>
                    </RequireAdmin>
                    <Tooltip title={`Copy event address`}>
                        <IconButton onClick={handleCopyClick}>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {(event.youtube_trailer || event.description || event.youtube_showcase) && (
                        <Tooltip title={`View Details`}>
                            <Button onClick={() => setExpanded(!expanded)} variant={expanded ? 'contained' : 'outlined'} sx={{ mt: 2 }}>
                                <RemoveRedEyeIcon/>
                            </Button>
                        </Tooltip>
                    )}
                </Grid>
                <Grid xs={12}>
                    <Collapse in={expanded} >
                        {contentLoaded && (
                            <>
                                {event.youtube_trailer && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Trailer
                                        </Typography>
                                        <hr />
                                        <iframe
                                            width="100%"
                                            height="500px"
                                            src={`https://www.youtube.com/embed/${event.youtube_trailer}`}
                                            title="YouTube"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                )}
                                {event.description && (
                                    <>
                                        <Box sx={{ ml: 1, p: 2 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: event.description }}
                                                    onClick={handleClickImage}
                                                ></div>
                                            </Typography>
                                        </Box>

                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            maxWidth="xl"
                                            fullWidth
                                            PaperProps={{
                                                sx: {
                                                    backgroundColor: 'rgba(29, 29, 29, 0.5)', // Apply to the dialog content
                                                },
                                            }}
                                        >
                                            <Box sx={{ p: 2, textAlign: 'center' }}>
                                                <img
                                                    src={selectedImage}
                                                    alt="enlarged"
                                                    style={{
                                                        maxWidth: '100%',  // Restrict to the dialog width
                                                        width: 'auto',      // Use natural width up to max-width
                                                        height: 'auto'      // Keep aspect ratio
                                                    }}
                                                />
                                            </Box>
                                        </Dialog>
                                    </>
                                )}
                                {event.youtube_showcase && (
                                    <Box sx={{ ml: 1, p: 2 }}>
                                        <Typography variant="h5" color="text.secondary">
                                            Showcase
                                        </Typography>
                                        <hr />
                                        <iframe
                                            width="100%"
                                            height="500px"
                                            src={`https://www.youtube.com/embed/${event.youtube_showcase}`}
                                            title="YouTube"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                )}
                            </>
                        )}
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" alignItems="center">
                            <Typography variant="h6" color="text.secondary">
                                {localEndDate}
                            </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default EventCardSubTypeTwo;
