import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";
import {MenuItem, Select, TextareaAutosize} from "@mui/material";
import {Editor} from "@tinymce/tinymce-react";
import ImageUpload from "../util/ImageUpload";

export default function AddCradle({ data }) {
    const [inputs, setInputs] = useState({
        category: 0,
        location: 0,
        anomaly: 0,
    });
    const [anomalies, setAnomalies] = useState([]);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        setImageUrl(inputs.image instanceof Blob ? URL.createObjectURL(inputs.image) : inputs.image);
        console.log(anomalies);
    }, [inputs]);


    useEffect(() => {
        if (data) {
            const filteredAnomalies = Array.isArray(data.anomalies) ? data.anomalies.filter(anomaly => anomaly.id >= 0) : [];
            setAnomalies(filteredAnomalies);
        }
    }, [data]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('image', imageFile);
        axios.post('/api/create/cradle', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        title: '',
                        decription: ''
                    }));
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Cradle</h3>
                    </InputLabel>
                    <Grid sx={{mb: 2}}>
                        <ImageUpload data={inputs} setData={setInputs} setImageFile={setImageFile}/>
                        {imageUrl && (
                        <img src={imageUrl} alt="main"/>
                            )}
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <TextField
                            fullWidth
                            id="title"
                            label="Name"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <div>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                value={inputs.description}
                                onEditorChange={(newValue, editor) => {
                                    setInputs(values => ({...values, description: newValue}));
                                }}
                                init={{
                                    height: 500,
                                    menubar: 'file edit view insert format tools table help',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                                        'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                                        'accordion'
                                    ],
                                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                                    image_advtab: true,
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_class: 'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    contextmenu: 'link image table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid sx={{ mb: 2 }}>
                        <InputLabel id="anomaly-label" sx={{ mt: 3 }}>Anomaly</InputLabel>
                        <Select
                            id="anomaly"
                            name="anomaly"
                            labelId="anomaly-label"
                            value={inputs.anomaly}
                            onChange={handleChange}
                            fullWidth
                            label="Anomaly"
                            defaultValue={0}
                        >
                            {anomalies.map((anomaly) => (
                                <MenuItem key={anomaly.id} value={anomaly.id}>
                                    {anomaly.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity}/>
            </Container>
        </>
    );
}
