import React from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";

// Styled BootstrapTooltip component
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

// Main component that accepts tooltip text as props
const DarkTooltip = ({ tooltipText }) => {
    return (
        <BootstrapTooltip title={tooltipText}>
            <InfoOutlinedIcon sx={{ ml: 1 }} color="text.secondary" />
        </BootstrapTooltip>
    );
};

export default DarkTooltip;
