import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const SimpleBoxStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: 10,
    border: '2px solid black',
    backgroundColor: 'rgba(29, 29, 29, 0.9)',
    backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.07), rgba(0, 0, 0, 0))`,
    width: '100%',
    height: 'auto',
    position: 'relative',
    boxShadow: `0px 0px 10px 0px #000`,
}));

const SimpleBox = ({ children, ...props }) => {
    return <SimpleBoxStyled {...props}>{children}</SimpleBoxStyled>;
};

export default SimpleBox;
