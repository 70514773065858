import React, { useEffect, useState } from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import {TextField, Button, Divider, Select, MenuItem, FormControl, Typography} from '@mui/material';
import SimpleBox from "../util/SimpleBox";
import Grid from "@mui/material/Grid";
import ShowTitle from "../util/ShowTitle";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";

const MapWithPins = ({ initialCoords }) => {
    const [inputs, setInputs] = useState({});
    const [pinCoordX, setPinCoordX] = useState(0);
    const [pinCoordY, setPinCoordY] = useState(0);
    const [zoom, setZoom] = useState(2);
    const [mapPosition, setMapPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [mapStart, setMapStart] = useState({ x: 0, y: 0 });
    const [dropPinMode, setDropPinMode] = useState(false);
    const [errors, setErrors] = useState({});

    const imageSrc = '/images/map/once_human_map.jpg';
    const containerWidth = 500;
    const containerHeight = 500;
    const mapWidth = 500;
    const mapHeight = 500;

    const minCoordX = -8400;
    const maxCoordX = 8400;
    const minCoordY = -8400;
    const maxCoordY = 8400;

    const calculatePixelPosition = (coordX, coordY) => {
        const xPercent = (coordX - minCoordX) / (maxCoordX - minCoordX);
        const yPercent = (coordY - minCoordY) / (maxCoordY - minCoordY);

        const xPixel = xPercent * mapWidth;
        const yPixel = (1 - yPercent) * mapHeight;

        return { x: xPixel, y: yPixel };
    };

    const updatePinPosition = (coordX, coordY) => {
        const { x, y } = calculatePixelPosition(coordX, coordY);
        setPinCoordX(coordX);
        setPinCoordY(coordY);
        centerOnPin(x, y);
    };

    const centerOnPin = (x, y) => {
        setMapPosition({
            x: (containerWidth / 2) - (x * zoom),
            y: (containerHeight / 2) - (y * zoom),
        });
    };

    const zoomIn = () => {
        setZoom((prevZoom) => {
            const newZoom = Math.min(prevZoom + 0.5, 10);
            centerZoom(newZoom);
            return newZoom;
        });
    };

    const zoomOut = () => {
        setZoom((prevZoom) => {
            const newZoom = Math.max(prevZoom - 0.5, 1);
            centerZoom(newZoom);
            return newZoom;
        });
    };

    const centerZoom = (newZoom) => {
        const centerX = containerWidth / 2 - mapPosition.x;
        const centerY = containerHeight / 2 - mapPosition.y;

        setMapPosition({
            x: containerWidth / 2 - (centerX * newZoom) / zoom,
            y: containerHeight / 2 - (centerY * newZoom) / zoom,
        });
    };

    useEffect(() => {
        if (initialCoords) {
            const { x, y } = initialCoords;
            updatePinPosition(x, y);
            setZoom(5);
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            title: '',
            server_type: 1,
            world: 1,

        }));
    }, [initialCoords]);

    const handleMouseDown = (e) => {
        if (e.button !== 0 || dropPinMode) return; // Only allow dragging with left mouse button and if not in drop pin mode
        e.preventDefault(); // Prevent default browser behavior (like text selection)
        setDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
        setMapStart(mapPosition);
    };

    const handleMouseMove = (e) => {
        if (!dragging) return; // Only move the map if dragging is active
        e.preventDefault(); // Prevent default behavior while dragging
        const deltaX = e.clientX - dragStart.x;
        const deltaY = e.clientY - dragStart.y;

        setMapPosition({
            x: mapStart.x + deltaX,
            y: mapStart.y + deltaY,
        });
    };

    const handleMouseUp = (e) => {
        e.preventDefault(); // Prevent any default behavior on mouse release
        setDragging(false); // Stop dragging when the mouse button is released
    };

    const handleMapClick = (e) => {
        if (!dropPinMode) return;

        const rect = e.target.getBoundingClientRect();
        const x = (e.clientX - rect.left) / zoom;
        const y = (e.clientY - rect.top) / zoom;

        const coordX = minCoordX + (x / mapWidth) * (maxCoordX - minCoordX);
        const coordY = maxCoordY - (y / mapHeight) * (maxCoordY - minCoordY);

        updatePinPosition(coordX, coordY);
        setDropPinMode(false); // Exit drop pin mode after placing pin
    };

    useEffect(() => {
        // Listen for global mouse movements and release
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        console.log(errors);

        return () => {
            // Clean up event listeners on unmount
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging, dragStart, mapStart]);

    return (
        <SimpleBox sx={{
            width: `${containerWidth}px`,
            height: `${containerHeight}px`,
            overflow: 'hidden',
        }}>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    className="map-container"
                    sx={{
                        position: 'relative',
                        width: `${containerWidth}px`,
                        height: `${containerHeight}px`,
                    }}
                >
                    <div style={{ position: 'absolute', zIndex: 1, top: '20px', left: '10px' }}>
                        <Button variant="contained" onClick={zoomIn}>+</Button>
                        <Button variant="contained" onClick={zoomOut} sx={{ ml: 1 }}>-</Button>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            top: `${mapPosition.y}px`,
                            left: `${mapPosition.x}px`,
                            transform: `scale(${zoom})`,
                            transformOrigin: 'top left',
                            transition: dragging ? 'none' : 'transform 0.3s ease, top 0.3s ease, left 0.3s ease',
                            cursor: dropPinMode ? 'crosshair' : (dragging ? 'grabbing' : 'grab'),
                        }}
                        onMouseDown={handleMouseDown}
                        onClick={handleMapClick}
                    >
                        <img src={imageSrc} alt="Map" style={{ width: `${containerWidth}px`, height: `${containerHeight}px` }} />

                        <PlaceIcon
                            style={{
                                position: 'absolute',
                                top: `${calculatePixelPosition(pinCoordX, pinCoordY).y}px`,
                                left: `${calculatePixelPosition(pinCoordX, pinCoordY).x}px`,
                                transform: 'translate(-50%, -100%)',
                                color: 'rgba(0,94,232,0.9)',
                                fontSize: '25px',
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default MapWithPins;
