import React, { useEffect, useState } from 'react';
import {Divider, Grid, Box, Select, MenuItem, FormControl, Typography} from '@mui/material';
import UniversalSelector from './UniversalSelector';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import {useSessionData} from "../auth/SessionDataProvider";
import {useParams} from "react-router-dom";
import ContentBox from "../util/ContentBox";
import SimpleBox from "../util/SimpleBox";
import {Editor} from "@tinymce/tinymce-react";
import UniversalCalibrationSelector from "./UniversalCalibrationSelector";
import ShowTitle from "../util/ShowTitle";

const BuildPlanner = ({ data }) => {
    const { sessionData } = useSessionData();
    const { id } = useParams();
    const [inputs, setInputs] = useState({});
    const [weapons, setWeapons] = useState([]);
    const [meleeWeapons, setMeleeWeapons] = useState([]);
    const [weaponMods, setWeaponMods] = useState([]);
    const [meleeMods, setMeleeMods] = useState([]);
    const [calibrations, setCalibrations] = useState([]);
    const [helmets, setHelmets] = useState([]);
    const [helmetMods, setHelmetMods] = useState([]);
    const [masks, setMasks] = useState([]);
    const [maskMods, setMaskMods] = useState([]);
    const [tops, setTops] = useState([]);
    const [topMods, setTopMods] = useState([]);
    const [bottoms, setBottoms] = useState([]);
    const [bottomMods, setBottomMods] = useState([]);
    const [gloves, setGloves] = useState([]);
    const [gloveMods, setGloveMods] = useState([]);
    const [shoes, setShoes] = useState([]);
    const [shoeMods, setShoeMods] = useState([]);
    const [serverDifficulty, setServerDifficulty] = useState([]);
    const [anomalies, setAnomalies] = useState([]);
    const [buildTypes, setBuildTypes] = useState([]);
    const [cradles, setCradles] = useState([]);
    const [stats, setStats] = useState([]);
    const [damageTypes, setDamageTypes] = useState([]);
    const [deviations, setDeviations] = useState([]);
    const [buildNameError, setBuildNameError] = useState('Name your build');
    const [ytNameError, setYtNameError] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);


    const isFormValid = Object.values(errors).every((error) => !error);

    const selectedWeaponOne = weapons.find(option => option.id === inputs['weapon_one']) || null;
    const selectedWeaponTwo = weapons.find(option => option.id === inputs['weapon_two']) || null;
    const selectedWeaponThree = meleeWeapons.find(option => option.id === inputs['weapon_three']) || null;

    const handleError = (itemName, hasError) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [itemName]: hasError,
        }));
    };

    const decodeHtml = (html) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    useEffect(() => {
        if (data && data.gears) {
            const filteredWeapons = data.gears.filter(gear => gear.category === 0);
            setWeapons(filteredWeapons);
        }
        if (data && data.gears) {
            const filteredWeapons = data.gears.filter(gear => gear.category === 7);
            setMeleeWeapons(filteredWeapons);
        }
        if (data && data.gears) {
            const filtered = data.gears.filter(mod => mod.category === 1);
            setHelmets(filtered);
        }
        if (data && data.gears) {
            const filtered = data.gears.filter(mod => mod.category === 2);
            setMasks(filtered);
        }
        if (data && data.gears) {
            const filtered = data.gears.filter(mod => mod.category === 3);
            setTops(filtered);
        }
        if (data && data.gears) {
            const filtered = data.gears.filter(mod => mod.category === 4);
            setBottoms(filtered);
        }
        if (data && data.gears) {
            const filtered = data.gears.filter(mod => mod.category === 5);
            setGloves(filtered);
        }
        if (data && data.gears) {
            const filtered = data.gears.filter(mod => mod.category === 6);
            setShoes(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 0)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setWeaponMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 8 || mod.category === 0)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setMeleeMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 1 || mod.category === 7)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setHelmetMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 2 || mod.category === 7)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setMaskMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 3 || mod.category === 7)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setTopMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 4 || mod.category === 7)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setBottomMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 5 || mod.category === 7)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setGloveMods(filtered);
        }
        if (data && data.mods) {
            const filtered = data.mods
                .filter(mod => mod.category === 6 || mod.category === 7)
                .map(mod => ({
                    ...mod,
                    title: `${mod.title} (${mod.mod_type})`
                }));
            setShoeMods(filtered);
        }
        if (data && data.calibrations) {
            const filtered = data.calibrations.filter(mod => mod.id >= 0);
            setCalibrations(filtered);
        }
        if (data && data.serverdifficulty) {
            const filtered = data.serverdifficulty.filter(mod => mod.id >= 0);
            setServerDifficulty(filtered);
        }
        if (data && data.anomalies) {
            const filtered = data.anomalies.filter(mod => mod.id >= 0);
            setAnomalies(filtered);
        }
        if (data && data.buildtypes) {
            const filtered = data.buildtypes.filter(mod => mod.id >= 0);
            setBuildTypes(filtered);
        }
        if (data && data.cradles) {
            const filtered = data.cradles.filter(mod => mod.id >= 0);
            setCradles(filtered);
        }
        if (data && data.stats) {
            const filtered = data.stats.filter(mod => mod.id >= 0);
            setStats(filtered);
        }
        if (data && data.damagetypes) {
            const filtered = data.damagetypes.filter(mod => mod.id >= 0);
            setDamageTypes(filtered);
        }
        if (data && data.deviations) {
            const filtered = data.deviations.filter(mod => mod.category <= 2);
            setDeviations(filtered);
        }

        if (data) {  // Check if data is available before setting default value
            setInputs((prevInputs) => ({
                ...prevInputs,
                anonymous: prevInputs.anonymous || 0,
                damage_type: prevInputs.damage_type || 1,
                helmet: prevInputs.helmet || '',
                helmet_mod: prevInputs.helmet_mod || '',
                mask: prevInputs.mask || '',
                mask_mod: prevInputs.mask_mod || '',
                top: prevInputs.top || '',
                top_mod: prevInputs.top_mod || '',
                bottom: prevInputs.bottom || '',
                bottom_mod: prevInputs.bottom_mod || '',
                glove: prevInputs.glove || '',
                glove_mod: prevInputs.glove_mod || '',
                shoe: prevInputs.shoe || '',
                shoe_mod: prevInputs.shoe_mod || '',
                server_type: prevInputs.server_type || 0,
                build_type: prevInputs.build_type || 1,
                server_difficulty: prevInputs.server_difficulty || 1,
                anomaly: prevInputs.anomaly || 1,
                deviation: prevInputs.deviation || '',
                description: prevInputs.description || '',
                cradle_one: prevInputs.cradle_one || 1,
                cradle_two: prevInputs.cradle_two || 1,
                cradle_three: prevInputs.cradle_three || 1,
                cradle_four: prevInputs.cradle_four || 1,
                cradle_five: prevInputs.cradle_five || 1,
                cradle_six: prevInputs.cradle_six || 1,
                cradle_seven: prevInputs.cradle_seven || 1,
                cradle_eight: prevInputs.cradle_eight || 1,
                stat_one: prevInputs.stat_one || 1,
                stat_two: prevInputs.stat_two || 1,
                stat_three: prevInputs.stat_three || 1,
                stat_four: prevInputs.stat_four || 1,
                stat_five: prevInputs.stat_five || 1,
                stat_six: prevInputs.stat_six || 1,
                stat_seven: prevInputs.stat_seven || 1,
                stat_eight: prevInputs.stat_eight || 1,
                weapon_one: prevInputs.weapon_one || '',
                weapon_two: prevInputs.weapon_two || '',
                weapon_three: prevInputs.weapon_three || '',
                weapon_one_mod: prevInputs.weapon_one_mod || '',
                weapon_two_mod: prevInputs.weapon_two_mod || '',
                weapon_three_mod: prevInputs.weapon_three_mod || '',
                weapon_one_calibration: prevInputs.weapon_one_calibration || '',
                weapon_two_calibration: prevInputs.weapon_two_calibration || '',
                weapon_three_calibration: prevInputs.weapon_three_calibration || '',
                youtube_link: prevInputs.youtube_link || '',
            }));
        }
    }, [data]);

    useEffect(() => {
        if (id) {
            getBuild();
        }
    }, [id]);

    function getBuild() {
        axios.get(`/api/get/build/${id}`)
            .then((result) => {
                if (result.data && Array.isArray(result.data) && result.data.length > 0) {
                    const buildData = result.data[0];
                    const settings = buildData.settings || {};

                    if (sessionData.userId !== Number(buildData.owner_id)) {
                        setEditMode(false);
                        setErrors('OwnerMismatch', 'Not the owner of the build!');
                    } else {
                        setInputs((prevInputs) => ({
                            ...prevInputs,
                            anonymous: settings.anonymous || 0,
                            damage_type: settings.damage_type || '',
                            helmet: settings.helmet || 0,
                            helmet_mod: settings.helmet_mod || 0,
                            mask: settings.mask || 0,
                            mask_mod: settings.mask_mod || 0,
                            top: settings.top || 0,
                            top_mod: settings.top_mod || 0,
                            bottom: settings.bottom || 0,
                            bottom_mod: settings.bottom_mod || 0,
                            glove: settings.glove || 0,
                            glove_mod: settings.glove_mod || 0,
                            shoe: settings.shoe || 0,
                            shoe_mod: settings.shoe_mod || 0,
                            server_type: settings.server_type || 0,
                            build_type: settings.build_type || 1,
                            server_difficulty: settings.server_difficulty || 1,
                            anomaly: settings.anomaly || 1,
                            deviation: settings.deviation || '',
                            description: decodeHtml(settings.description) || '',
                            cradle_one: settings.cradle_one || 1,
                            cradle_two: settings.cradle_two || 1,
                            cradle_three: settings.cradle_three || 1,
                            cradle_four: settings.cradle_four || 1,
                            cradle_five: settings.cradle_five || 1,
                            cradle_six: settings.cradle_six || 1,
                            cradle_seven: settings.cradle_seven || 1,
                            cradle_eight: settings.cradle_eight || 1,
                            stat_one: settings.stat_one || 1,
                            stat_two: settings.stat_two || 1,
                            stat_three: settings.stat_three || 1,
                            stat_four: settings.stat_four || 1,
                            stat_five: settings.stat_five || 1,
                            stat_six: settings.stat_six || 1,
                            stat_seven: settings.stat_seven || 1,
                            stat_eight: settings.stat_eight || 1,
                            title: buildData.title || '',
                            weapon_one: settings.weapon_one || '',
                            weapon_two: settings.weapon_two || '',
                            weapon_three: settings.weapon_three || '',
                            weapon_one_mod: settings.weapon_one_mod || '',
                            weapon_two_mod: settings.weapon_two_mod || '',
                            weapon_three_mod: settings.weapon_three_mod || '',
                            weapon_one_calibration: settings.weapon_one_calibration || '',
                            weapon_two_calibration: settings.weapon_two_calibration || '',
                            weapon_three_calibration: settings.weapon_three_calibration || '',
                            youtube_link: settings.youtube_link || '',
                        }));
                        setBuildNameError('');
                        setEditMode(true);
                    }
                } else {
                    console.error('Unexpected result data format:', result.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching build data:', error);
            });
    }

    const handleSubmit = async () => {
        if (isFormValid) {
            setSaving(true);
            const formData = new FormData();
            for (const [key, value] of Object.entries(inputs)) {
                formData.append(key, value);
            }

            if (editMode) {
                formData.append('build_id', id);
                formData.append('owner_id', sessionData.userId);
                axios.post('/api/edit/build', formData)
                    .then(function (response) {
                        if (response.data !== false) {
                            window.location.href = `${response.data}`;
                        }
                        setSaving(false);
                    });
            } else if (sessionData.isLogged) {
                axios.post('/api/create/build', formData)
                    .then(function (response) {
                        if (response.data !== false) {
                            window.location.href = `${response.data}`;
                        }
                        setSaving(false);
                    });
            } else {
                setSaving(false);
            }
        }
    };

    const handleBuildNameChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));

        if (!sessionData.logged) {
            setBuildNameError('Please log in to save your build!');
        } else if (value === '') {
            setBuildNameError('Build name cannot be empty.');
        } else if (value.length < 5 || value.length > 100) {
            setBuildNameError('Build name must be between 5 and 100 characters.');
        } else {
            setBuildNameError('');
        }
    };

    const isValidYouTubeURL = (url) => {
        const regex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=|watch\?.+v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;
        return regex.test(url);
    };

    const handleYoutubeChange = (event) => {
        const { name, value } = event.target;
        setInputs({
            ...inputs,
            [name]: value,
        });

        if (name === 'youtube_link') {
            if (value === '' || isValidYouTubeURL(value)) {
                setYtNameError('');
            } else {
                setYtNameError('Invalid YouTube link');
            }
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
        console.log(inputs);
    };

    return (
        <>
            <ContentBox sx={{ mb: 2 }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <ShowTitle
                            noShow={true}
                            title="Build Planner"
                            description="Create and customize your Once Human builds with our advanced build planner. Explore various strategies and setup your perfect build."
                            keywords="Once Human build planner, custom builds, build creator, Once Human strategies, build customization, game build planner"
                        />
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <TextField
                                fullWidth
                                id="title"
                                label="Build Name"
                                name="title"
                                value={inputs.title}
                                error={!!buildNameError}
                                helperText={buildNameError}
                                onChange={handleBuildNameChange}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleBox>
                            <FormControl fullWidth>
                                <InputLabel id="server_type-label">Anonymous</InputLabel>
                                <Select
                                    labelId="server_type-label"
                                    id="anonymous"
                                    name="anonymous"
                                    value={inputs.anonymous}
                                    onChange={handleChange}
                                    label="Anonymous"
                                    defaultValue={0}
                                >
                                    <MenuItem key={0} value={0}>Show Nickname</MenuItem>
                                    <MenuItem key={1} value={1}>Hide Nickname</MenuItem>
                                </Select>
                            </FormControl>
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={buildTypes}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="build_type"
                                itemTitle="Build Type"
                                folder="buildtypes"
                                defaultValue={1}
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={damageTypes}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="damage_type"
                                itemTitle="Damage Type"
                                folder="damagetypes"
                                defaultValue={1}
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleBox>
                            <FormControl fullWidth>
                                <InputLabel id="server_type-label">Server Type</InputLabel>
                                <Select
                                    labelId="server_type-label"
                                    id="server_type"
                                    name="server_type"
                                    value={inputs.server_type}
                                    onChange={handleChange}
                                    label="Server Type"
                                    defaultValue={0}
                                    errorSetter={handleError}
                                >
                                    <MenuItem key={0} value={0}>PvE</MenuItem>
                                    <MenuItem key={1} value={1}>PvP</MenuItem>
                                </Select>
                            </FormControl>
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={serverDifficulty}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="server_difficulty"
                                itemTitle="Server Difficulty"
                                folder="none"
                                defaultValue={1}
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleBox>
                            <FormControl fullWidth>
                                <InputLabel id="anomaly-label">Anomalies</InputLabel>
                                <Select
                                    labelId="anomaly-label"
                                    id="anomaly"
                                    name="anomaly"
                                    value={inputs.anomaly}
                                    onChange={handleChange}
                                    label="Anomalies"
                                    defaultValue={[1]}
                                    multiple
                                    required
                                >
                                    {anomalies.map((anomaly) => (
                                        <MenuItem key={anomaly.id} value={anomaly.id}>
                                            {anomaly.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </SimpleBox>
                    </Grid>
                </Grid>
            </ContentBox>
            <ContentBox>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2 style={{ marginBottom: 0, marginTop: 0 }}>Weapons</h2>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container >
                            <Grid item xs={12}>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        <SimpleBox>
                                            <UniversalSelector
                                                mainData={weapons}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_one"
                                                itemTitle="Main Weapon"
                                                folder="gears"
                                                baseWidth="100px"
                                                errorSetter={handleError}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleBox>
                                            <UniversalSelector
                                                mainData={weaponMods}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_one_mod"
                                                itemTitle="Main Weapon Mod"
                                                folder="mods"
                                                errorSetter={handleError}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleBox>
                                            <UniversalCalibrationSelector
                                                mainData={calibrations}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_one_calibration"
                                                itemTitle="Main Weapon Calibration"
                                                folder="calibrations"
                                                errorSetter={handleError}
                                                calibrationCategory={selectedWeaponOne ? selectedWeaponOne.weapon_type : null}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        <SimpleBox>
                                            <UniversalSelector
                                                mainData={weapons}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_two"
                                                itemTitle="Secondary Weapon"
                                                folder="gears"
                                                baseWidth="100px"
                                                errorSetter={handleError}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleBox>
                                            <UniversalSelector
                                                mainData={weaponMods}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_two_mod"
                                                itemTitle="Secondary Weapon Mod"
                                                folder="mods"
                                                errorSetter={handleError}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleBox>
                                            <UniversalCalibrationSelector
                                                mainData={calibrations}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_two_calibration"
                                                itemTitle="Secondary Weapon Calibration"
                                                folder="calibrations"
                                                errorSetter={handleError}
                                                calibrationCategory={selectedWeaponTwo ? selectedWeaponTwo.weapon_type : null}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        <SimpleBox>
                                            <UniversalSelector
                                                mainData={meleeWeapons}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_three"
                                                itemTitle="Melee Weapon"
                                                folder="gears"
                                                baseWidth="100px"
                                                errorSetter={handleError}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleBox>
                                            <UniversalSelector
                                                mainData={meleeMods}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_three_mod"
                                                itemTitle="Melee Weapon Mod"
                                                folder="mods"
                                                errorSetter={handleError}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleBox>
                                            <UniversalCalibrationSelector
                                                mainData={calibrations}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="weapon_three_calibration"
                                                itemTitle="Melee Weapon Calibration"
                                                folder="calibrations"
                                                errorSetter={handleError}
                                                calibrationCategory={selectedWeaponThree ? selectedWeaponThree.weapon_type : null}
                                            />
                                        </SimpleBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={{ marginBottom: 0, marginTop: 0 }}>Gear</h2>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={helmets}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="helmet"
                                itemTitle="Helmet"
                                folder="gears"
                                baseWidth="100px"
                                errorSetter={handleError}
                            />
                            <Box sx={{ ml: 2 }}></Box>
                            <UniversalSelector
                                mainData={helmetMods}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="helmet_mod"
                                itemTitle="Helmet Mod"
                                folder="mods"
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={masks}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="mask"
                                itemTitle="Mask"
                                folder="gears"
                                baseWidth="100px"
                                errorSetter={handleError}
                            />
                            <Box sx={{ ml: 2 }}></Box>
                            <UniversalSelector
                                mainData={maskMods}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="mask_mod"
                                itemTitle="Mask Mod"
                                folder="mods"
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={tops}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="top"
                                itemTitle="Top"
                                folder="gears"
                                baseWidth="100px"
                                errorSetter={handleError}
                            />
                            <Box sx={{ ml: 2 }}></Box>
                            <UniversalSelector
                                mainData={topMods}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="top_mod"
                                itemTitle="Top Mod"
                                folder="mods"
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={bottoms}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="bottom"
                                itemTitle="Bottom"
                                folder="gears"
                                baseWidth="100px"
                                errorSetter={handleError}
                            />
                            <Box sx={{ ml: 2 }}></Box>
                            <UniversalSelector
                                mainData={bottomMods}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="bottom_mod"
                                itemTitle="Bottom Mod"
                                folder="mods"
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={gloves}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="glove"
                                itemTitle="Glove"
                                folder="gears"
                                baseWidth="100px"
                                errorSetter={handleError}
                            />
                            <Box sx={{ ml: 2 }}></Box>
                            <UniversalSelector
                                mainData={gloveMods}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="glove_mod"
                                itemTitle="Glove Mod"
                                folder="mods"
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleBox>
                            <UniversalSelector
                                mainData={shoes}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="shoe"
                                itemTitle="Shoe"
                                folder="gears"
                                baseWidth="100px"
                                errorSetter={handleError}
                            />
                            <Box sx={{ ml: 2 }}></Box>
                            <UniversalSelector
                                mainData={shoeMods}
                                inputs={inputs}
                                setInputs={setInputs}
                                itemName="shoe_mod"
                                itemTitle="Shoe Mod"
                                folder="mods"
                                errorSetter={handleError}
                            />
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={{ marginBottom: 0 }}>Cradle and Stats</h2>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SimpleBox>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <UniversalSelector
                                        mainData={deviations}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="deviation"
                                        itemTitle="Deviation"
                                        folder="deviations"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_one"
                                        itemTitle="Cradle 1"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_two"
                                        itemTitle="Cradle 2"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_three"
                                        itemTitle="Cradle 3"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_four"
                                        itemTitle="Cradle 4"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_five"
                                        itemTitle="Cradle 5"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_six"
                                        itemTitle="Cradle 6"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_seven"
                                        itemTitle="Cradle 7"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UniversalSelector
                                        mainData={cradles}
                                        inputs={inputs}
                                        setInputs={setInputs}
                                        itemName="cradle_eight"
                                        itemTitle="Cradle 8"
                                        folder="cradles"
                                        errorSetter={handleError}
                                    />
                                </Grid>
                            </Grid>
                        </SimpleBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SimpleBox>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Typography variant="body" sx={{fontWeight: 'bold', display: 'flex', alignItems: 'center', color: '#fff', m: 1}}>
                                        Stat priority currently is for informative purposes only, but will be taken to account
                                        when advanced calculations implemented. You can leave them on None.
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_one"
                                                itemTitle="Priority Stat 1"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_two"
                                                itemTitle="Priority Stat 2"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_three"
                                                itemTitle="Priority Stat 3"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_four"
                                                itemTitle="Priority Stat 4"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_five"
                                                itemTitle="Priority Stat 5"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_six"
                                                itemTitle="Priority Stat 6"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_seven"
                                                itemTitle="Priority Stat 7"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UniversalSelector
                                                mainData={stats}
                                                inputs={inputs}
                                                setInputs={setInputs}
                                                itemName="stat_eight"
                                                itemTitle="Priority Stat 8"
                                                folder="none"
                                                errorSetter={handleError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SimpleBox>
                    </Grid>
                </Grid>
            </ContentBox>
            <ContentBox sx={{ mt: 2, mb: 2 }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={10}>
                        <h2 style={{ marginBottom: 0, marginTop: 0 }}>Description (optional)</h2>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleBox>
                            <Editor
                                apiKey='5x3x33fujcfwfu9u3bbptad4mjd1zizoix52ui4e3zuipmfo'
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                value={inputs.description}
                                onEditorChange={(newValue, editor) => {
                                    setInputs(values => ({...values, description: newValue}));
                                }}
                                init={{
                                    width: '100%',
                                    height: 250,
                                    menubar: false,
                                    plugins: [
                                        'advlist', 'lists', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'table', 'preview', 'help', 'wordcount',
                                        'importcss', 'directionality', 'codesample', 'emoticons',
                                        'accordion'
                                    ],
                                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_class: 'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    contextmenu: 'link image table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                            />
                        </SimpleBox>
                    </Grid>


                    <Grid item xs={12}>
                        <SimpleBox>
                            <TextField
                                fullWidth
                                id="youtube_link"
                                label="YouTube link to build video"
                                name="youtube_link"
                                value={inputs.youtube_link}
                                onChange={handleYoutubeChange}
                                error={!!ytNameError}
                                helperText={ytNameError}
                            />
                        </SimpleBox>
                    </Grid>

                    <Grid item xs={12} sx={{ m: 5, fontWeight: 'bold', color: '#6e0000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontVariant: 'small-caps'}}>
                        {(buildNameError || ytNameError) && (
                            <>
                            <Typography variant="h4" >
                                Error:
                            </Typography>
                            <Typography variant="h4" >
                            {buildNameError}
                            </Typography>
                            <Typography variant="h4" >
                                {ytNameError}
                            </Typography>
                            </>
                        )}
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                            disabled={!!buildNameError || !!ytNameError || saving || !isFormValid}
                            sx={{ mt: 3, p: 2 }}
                            fullWidth
                        >
                            Save Build
                        </Button>
                    </Grid>
                </Grid>
            </ContentBox>
        </>
    );
};

export default BuildPlanner;
