import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const ContentBoxStyled = styled(Box)(({ theme }) => ({
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: 10,
    border: '2px solid black',
    backgroundColor: 'rgba(188,239,255,0.8)',
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))`,
    width: '100%',
    height: 'auto',
    position: 'relative',
    boxShadow: `0px 5px 20px 0px #000`,
}));

const ContentBox = ({ children, ...props }) => {
    return <ContentBoxStyled {...props}>{children}</ContentBoxStyled>;
};

export default ContentBox;
