import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider, FormControl, MenuItem, Select,
    Tooltip,
    Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SimpleBox from "../util/SimpleBox";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ShowTitle from "../util/ShowTitle";
import ContentBox from "../util/ContentBox";
import UniversalSelector from "../build_planner/UniversalSelector";
import Cookies from 'js-cookie';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import IconButton from "@mui/material/IconButton";
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import {useSessionData} from "../auth/SessionDataProvider";

export default function TradeCenter() {
    const { sessionData } = useSessionData();
    const [inputs, setInputs] = useState({});
    const [listings, setListings] = useState([]);
    const [newest, setNewest] = useState([]);
    const [resources, setResources] = useState([]);
    const [resourcesArray, setResourcesArray] = useState([]);
    const [stats, setStats] = useState([]);
    const [servers, setServers] = useState([]);
    const [acknowledged, setAcknowledged] = useState(true);
    const [filters, setFilters] = useState({
        sortBy: 'date', // 'ppu' or 'date'
        sortOrder: 'desc', // 'asc' or 'desc'
    });

    useEffect(() => {
        getTrades();

        const storedInputs = Cookies.get('trade_inputs');
        const parsedInputs = storedInputs ? JSON.parse(storedInputs) : { server: 0, search_item_sold: 0, search_item_need: 0 };

        setInputs(prevInputs => ({
            ...prevInputs,
            ...parsedInputs
        }));

        const acknowledgedStatus = Cookies.get('acknowledged');
        if (!acknowledgedStatus) {
            setAcknowledged(false);
        }
    }, []);

    useEffect(() => {
        if (inputs.server) {
            getServerListing();
        }
    }, [inputs.server]);

    const handleAcknowledge = () => {
        Cookies.set('acknowledged', true, { expires: 60 }); // Acknowledge cookie, expires in 7 days
        setAcknowledged(true);
    };

    useEffect(() => {
        Cookies.set('trade_inputs', JSON.stringify(inputs), { expires: 7 });
    }, [inputs]);

    useEffect(() => {
        const selectedItem = resources.find(resource => resource.id === Number(inputs.search_item_sold));
        const isPrimaryStatEnabled = selectedItem?.primary_stat !== 0;
        const isSecondaryStatEnabled = selectedItem?.secondary_stat !== 0;

        if (!isPrimaryStatEnabled && inputs.primary_stat !== 0) {
            setInputs(prevInputs => ({
                ...prevInputs,
                primary_stat: null,
            }));
        }
        if (!isSecondaryStatEnabled && inputs.secondary_stat !== 0) {
            setInputs(prevInputs => ({
                ...prevInputs,
                secondary_stat: null,
            }));
        }
    }, [inputs.search_item_sold]);

    function getTrades() {
        axios.get('/api/get/latestListings')
            .then((result) => {
                if (result.data !== false) {
                    // console.log(result.data);
                    setListings(result.data.listings);

                    const resourcesObject = result.data.resources.reduce((acc, resource) => {
                        acc[resource.id] = resource;
                        return acc;
                    }, {});
                    setResourcesArray(resourcesObject);

                    setResources([
                        { id: 0, title: "All items" },
                        ...Object.values(result.data.resources)
                    ]);

                    setServers([
                        { id: 0, title: "Select server" }, // Add your "Select server" item at the beginning
                        ...Object.values(result.data.servers) // Spread the existing server items
                    ]);
                    setStats([
                        { id: 0, title: "All" }, // Add your "Select server" item at the beginning
                        ...Object.values(result.data.secondaryStats) // Spread the existing server items
                    ]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getServerListing() {
        axios.get(`/api/get/serverListings/${inputs.server}`)
            .then((result) => {
                console.log(result);
                if (result.data !== false) {
                    setListings(result.data.listings);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function formatNumberWithSpaces(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    function formatBuySellRatio(buyQty, sellQty) {
        const ratio = buyQty / sellQty;

        if (Number.isInteger(ratio)) {
            return formatNumberWithSpaces(ratio);
        } else {
            return formatNumberWithSpaces(ratio.toFixed(3));
        }
    }

    if (!listings) {
        return null;
    }

    const filteredListings = listings
        .filter(listing => {
            const matchesSoldItem = inputs.search_item_sold === 0 || listing.item_sell_id === inputs.search_item_sold;
            const matchesBuyItem = inputs.search_item_need === 0 || listing.item_buy_id === inputs.search_item_need;
            const matchesPrimaryStat = inputs.primary_stat === 0 || listing.primary_stat_id === inputs.primary_stat;
            const matchesSecondaryStat = inputs.secondary_stat === 0 || listing.secondary_stat_id === inputs.secondary_stat;
            return matchesSoldItem && matchesBuyItem && matchesPrimaryStat && matchesSecondaryStat;
        })
        .sort((a, b) => {
            const field = filters.sortBy;
            let valueA, valueB;

            if (field === 'ppu') {
                const ppuA = a.item_sell_qty && a.item_buy_qty ? a.item_buy_qty / a.item_sell_qty : 0;
                const ppuB = b.item_sell_qty && b.item_buy_qty ? b.item_buy_qty / b.item_sell_qty : 0;
                valueA = ppuA;
                valueB = ppuB;
            } else if (field === 'date') {
                valueA = new Date(a.uploaded_at);
                valueB = new Date(b.uploaded_at);
            }

            if (filters.sortOrder === 'asc') {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA < valueB ? 1 : -1;
            }
        });

    const toggleSortOrder = (sortBy) => {
        setFilters(prevFilters => ({
            sortBy,
            sortOrder: prevFilters.sortOrder === 'asc' ? 'desc' : 'asc'
        }));
    };

    const selectedItem = resources.find(resource => resource.id === Number(inputs.search_item_sold));
    const isPrimaryStatEnabled = selectedItem?.primary_stat !== 0;
    const isSecondaryStatEnabled = selectedItem?.secondary_stat !== 0;

    return (
        <>
            {!acknowledged && (
                <ContentBox sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center'}}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontVariant: 'small-caps',
                                    textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                }}
                            >
                                Trade Center Disclaimer
                            </Typography>
                            <Divider sx={{ border: '1px solid black', m: 1 }} />
                            <Typography variant="h6">
                                The Trade Center is designed to help players connect and find the best deals with ease.
                                It only displays items that users have uploaded.<br/>
                                <strong>All transactions must take place in-game.</strong><br/>

                                Please note that purchasing an item is entirely optional, and there is no guarantee that
                                the item will still be available when you check with the vendor.<br/>
                                To ensure the listings are as up-to-date as possible, we provide tools such as the "Buy"
                                and "Report Missing Item" options.<br/>
                                You are <i>required to sign in to view the trader's
                                location</i>  and express your intent to purchase.<br/>
                            </Typography>
                            <Divider sx={{ border: '1px solid black', m: 1 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleAcknowledge} color="primary" variant="contained" fullWidth sx={{ p: 2 }}>
                                I Acknowledge
                            </Button>
                        </Grid>
                    </Grid>
                </ContentBox>
            )}
            <SimpleBox sx={{mb: 2}}>
                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', mt: 1}}>
                        {inputs.server === 0 ? (
                        <>
                            <Grid item xs={12} lg={3}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontVariant: 'small-caps',
                                        textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                    }}
                                >
                                    Select your server to search:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <UniversalSelector
                                    mainData={servers}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    itemName="server"
                                    itemTitle="Server"
                                    folder="none"
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1}}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontVariant: 'small-caps',
                                        textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                    }}
                                >
                                    If you dont see your server, you need to
                                    <Button
                                        sx={{ m: 1 }}
                                        component={Link}
                                        to={`/addVendor`}
                                        color="primary"
                                    >
                                        Add a Vendor
                                    </Button>to it!
                                </Typography>
                            </Grid>
                        </>
                        ) : (
                        <>
                            <Grid item xs={12} lg={6} xl={3} sx={{ mt: 2}}>
                                <UniversalSelector
                                    mainData={resources}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    itemName="search_item_sold"
                                    itemTitle="Search items"
                                    folder="resources"
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={3} sx={{ mt: 2}}>
                                <UniversalSelector
                                    mainData={resources}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    itemName="search_item_need"
                                    itemTitle="Exchange to"
                                    folder="resources"
                                    required={false}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={2}>
                                <UniversalSelector
                                    mainData={stats}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    itemName="primary_stat"
                                    itemTitle="Primary stat"
                                    folder="none"
                                    required={false}
                                    disabled={!isPrimaryStatEnabled}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={2}>
                                <UniversalSelector
                                    mainData={stats}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    itemName="secondary_stat"
                                    itemTitle="Secondary stat"
                                    folder="none"
                                    required={false}
                                    disabled={!isSecondaryStatEnabled}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6} xl={2}>
                                <UniversalSelector
                                    mainData={servers}
                                    inputs={inputs}
                                    setInputs={setInputs}
                                    itemName="server"
                                    itemTitle="Server"
                                    folder="none"
                                    required={false}
                                />
                            </Grid>
                        </>
                        )}
                </Grid>
            </SimpleBox>
            <SimpleBox>
                <Grid container>
                    <ShowTitle
                        noShow={true}
                        title="Trade Center - Find the Best Once Human trade deals!"
                        description="Find the best Once Human trading deals on our dedicated marketplace. Connect with other players, buy and sell items, and negotiate the best prices for your in-game needs."
                        keywords="Once Human trade center, Once Human item trading, Once Human trading marketplace, Once Human in-game trading, Once Human trading deals, Once Human item exchange, Once Human trading community, Once Human buy and sell, Once Human trading platform, Once Human trading forum"
                    />
                    {inputs.server === 0 ? (
                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        gutterBottom
                                        sx={{
                                            fontVariant: 'small-caps',
                                            textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                        }}
                                    >
                                        Fresh deals
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ border: '1px solid white', m: 1 }} />
                        </Grid>
                    ) : (
                    <>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Exchange To
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        <Tooltip title={`Price Per Unit`}>
                                            PPU
                                        </Tooltip>
                                        <Tooltip title={`Sort by Price Per Unit`}>
                                            <IconButton onClick={() => toggleSortOrder('ppu')}>
                                                <SortByAlphaRoundedIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Stats
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Date
                                        <Tooltip title={`Sort by Date`}>
                                            <IconButton onClick={() => toggleSortOrder('date')}>
                                                <SortByAlphaRoundedIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Check
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ border: '1px solid white', m: 1 }} />
                        </Grid>
                    </>
                    )}

                    {filteredListings.length > 0 ? (
                        filteredListings.map((listing) => (
                            <Grid item xs={12} key={listing.id} sx={{ mb: 1 }}>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6">
                                            {formatNumberWithSpaces(listing.item_sell_qty)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Tooltip title={`${resourcesArray[listing.item_sell_id]?.title}`} arrow>
                                            <img
                                                src={`/images/resources/${listing.item_sell_id}.webp`}
                                                alt={listing.comment || 'Item'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    border: '2px solid black',
                                                    marginRight: '2px',
                                                }}
                                            />
                                        </Tooltip>
                                        <Typography variant="h6" sx={{ ml: 2 }}>
                                            {`${resourcesArray[listing.item_sell_id]?.title}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} lg={1}>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <DoubleArrowIcon/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} lg={1}>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {formatNumberWithSpaces(listing.item_buy_qty)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Tooltip title={`${resourcesArray[listing.item_buy_id]?.title}`} arrow>
                                            <img
                                                src={`/images/resources/${listing.item_buy_id}.webp`}
                                                alt={listing.comment || 'Item'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    border: '2px solid black',
                                                    marginRight: '2px',
                                                }}
                                            />
                                        </Tooltip>
                                        <Typography variant="h6" sx={{ ml: 2 }}>
                                            {`${resourcesArray[listing.item_buy_id]?.title}`}
                                        </Typography>
                                    </Grid>
                                    {inputs.server === 0 ? (
                                        <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {servers.find(server => server.id === listing.server_id)?.title}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {(listing.item_sell_qty && listing.item_buy_qty)
                                                ? formatBuySellRatio(listing.item_buy_qty, listing.item_sell_qty)
                                                : 'N/A'}
                                        </Grid>
                                    )}
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {listing.primary_stat_id !== 0 && (
                                            <>
                                                {stats.find(stat => stat.id === listing.primary_stat_id)?.title}: {listing.primary_stat_qty} <br/>
                                            </>
                                        )}
                                        {listing.primary_stat_id !== 0 && (
                                            <>
                                                {stats.find(stat => stat.id === listing.secondary_stat_id)?.title}: {listing.secondary_stat_qty}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={6} lg={1} sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                        mr: 1
                                    }}>
                                        {new Date(listing.updated_at).toLocaleString('en-GB', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: false // Use 24-hour format
                                        })}
                                    </Grid>
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                                            {listing.state === 4 ? (
                                                <Tooltip title={'Continous Supply'}>
                                                    <IconButton>
                                                        <AutorenewRoundedIcon color={'success'}/>
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={listing.state === 1 ? 'Available' : listing.state === 2 ? 'Buyer Applied' : 'Reported Missing'}>
                                                    <IconButton>
                                                        <LightbulbRoundedIcon color={listing.state === 1 ? 'success' : listing.state === 2 ? 'warning' : 'error'}/>
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                        {sessionData.isLogged || listing.state === 4 ? (
                                            <Button
                                                component={Link}
                                                to={`/trade/listing/${listing.id}`}
                                                color="primary"
                                            >
                                                {`Check deal`}
                                            </Button>
                                        ) : (
                                            <Tooltip title="You need to log in to check this deal">
                                                        <span>
                                                            <Button
                                                                color="primary"
                                                                disabled
                                                            >
                                                                {`Check deal`}
                                                            </Button>
                                                        </span>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 1 }}/>
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No listings found
                        </Grid>
                    )}
                </Grid>
            </SimpleBox>
        </>
    );
}
