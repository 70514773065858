import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const Countdown = ({ resetTime, description }) => {
    const calculateTimeLeft = () => {
        const now = moment().tz("GMT");
        let nextReset = resetTime();

        if (nextReset.isBefore(now)) {
            nextReset.add(1, 'week');
        }

        const difference = nextReset.diff(now);
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div>
            <h2>{description}</h2>
            <h3>
                {timeLeft.days > 0 && `${timeLeft.days}d `}
                {`${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
            </h3>
        </div>
    );
};

export default Countdown;
