import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, ListItem, ListItemAvatar, ListItemText, Tooltip, Divider } from '@mui/material';
import CustomTooltip from "../util/CustomTooltip";

const UniversalCalibrationSelector = ({
                               mainData,
                               inputs,
                               setInputs,
                               itemName,
                               itemTitle,
                               folder,
                               baseWidth = '50px',
                               errorSetter,
                               defaultValue,
                               calibrationCategory // New prop for filtering based on category
                           }) => {
    const [error, setError] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const defaultOption = {
        id: 0,
        title: 'Default',
        category: null,
    };

    // Filter mainData based on calibrationCategory
    const filteredData = calibrationCategory
        ? [defaultOption, ...mainData.filter(item => item.category === calibrationCategory)]
        : [defaultOption, ...mainData];

    useEffect(() => {
        // Initialize selectedValue based on inputs or default to the first item
        if (!inputs[itemName] && filteredData.length > 0) {
            const defaultItem = filteredData[0];
            setSelectedValue(defaultItem);
            setInputs((prevInputs) => ({
                ...prevInputs,
                [itemName]: defaultItem.id,
            }));
        } else {
            setSelectedValue(filteredData.find(option => option.id === inputs[itemName]) || null);
        }
    }, [inputs[itemName], itemName, setInputs]);

    useEffect(() => {
        // Notify parent component of current error state
        errorSetter(itemName, error);
    }, [error, itemName, errorSetter]);

    const handleChange = (event, newValue) => {
        if (!newValue) {
            setError(true);
        } else {
            setError(false);
            setInputs((prevInputs) => ({
                ...prevInputs,
                [itemName]: newValue ? newValue.id : "",
            }));
        }
    };

    return (
        <Autocomplete
            fullWidth
            aria-required="true"
            id={itemName}
            name={itemName}
            value={selectedValue}
            onChange={handleChange}
            options={filteredData} // Use filteredData here
            getOptionLabel={(option) => option.title}
            renderOption={(props, option) => (
                <>
                    <ListItem
                        {...props}
                        key={option.id} // Add key prop for list item
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                            },
                            backgroundColor: inputs[itemName] === option.id ? 'rgba(0, 0, 0, 0.14)' : 'transparent',
                            border: inputs[itemName] === option.id ? '2px solid #1976d2' : 'none',
                            borderRadius: '4px',
                        }}
                    >
                        { folder !== 'none' && (
                            <ListItemAvatar>
                                <CustomTooltip
                                    title={option.title}
                                    description={option.description}
                                    rarity={option.rarity || 5}
                                >
                                    <img
                                        src={`/images/${folder}/${calibrationCategory}.webp`}
                                        alt={option.title}
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevent infinite loop if the fallback image fails
                                            e.target.src = '/images/calibrations/seasonal.webp'; // Path to your default image
                                        }}
                                        style={{ marginRight: '1rem', width: baseWidth, height: '50px', border: '2px solid #000' }}
                                    />
                                </CustomTooltip>
                            </ListItemAvatar>
                        )}
                        <ListItemText primary={option.title} />
                    </ListItem>
                    <Divider sx={{ border: '1px solid #000' }} />
                </>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={itemTitle}
                    required
                    error={error}
                    helperText={error ? `${itemTitle} is required` : ""}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: folder !== 'none' && inputs[itemName] && (
                            <img
                                src={`/images/${folder}/${calibrationCategory}.webp`}
                                alt={mainData.find(option => option.id === inputs[itemName])?.title || ''}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if the fallback image fails
                                    e.target.src = '/images/calibrations/seasonal.webp'; // Path to your default image
                                }}
                                style={{ marginRight: '8px', width: baseWidth, height: '50px', border: '2px solid #000' }}
                            />
                        ),
                    }}
                />
            )}
        />
    );
};

export default UniversalCalibrationSelector;
