import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    CircularProgress, circularProgressClasses,
} from "@mui/material";
import axios from "axios";
import {Link, useLocation} from "react-router-dom";
import EventCard from "../util/eventCard";

export default function OnceHumanEvents({ data, isSmallScreen }) {
    const [onceHumanEvents, setOnceHumanEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [eventTypes, setEventTypes] = useState([
        { id: 0, name: 'Battlepass' },
        { id: 1, name: 'Event' },
        { id: 2, name: 'News' },
        { id: 3, name: 'DLC' },
        { id: 4, name: 'Patch Note' },
        { id: 5, name: 'Dev Talks' },
    ]);

    const location = useLocation();

    const calculateDaysLeft = (event) => {
        const endDate = new Date(event.event_end);
        const currentDate = new Date();
        const daysLeft = Math.max(Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24)), 0);
        return daysLeft;
    };


    useEffect(() => {
        getOnceHumanEvents()
    }, [data]);

    async function getOnceHumanEvents() {
        try {
            const result = await axios.get('https://season-tracker.com/api/get/onceHumanEvents');
            if (result.data !== false) {
                const eventsWithDaysLeft = result.data.map(event => ({
                    ...event,
                    daysLeft: calculateDaysLeft(event)
                }));

                // Update the state with the events including the `daysLeft`
                setOnceHumanEvents(eventsWithDaysLeft);

                // Optionally set a selected event (for example, the first event)
                // if (eventsWithDaysLeft.length > 0) {
                //     setSelectedEvent(eventsWithDaysLeft[0]);
                // }
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    }


    if (loading) {
        return <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={(theme) => ({
                color: '#1a90ff',
                animationDuration: '550ms',
                position: 'middle',
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                ...theme.applyStyles('dark', {
                    color: '#308fe8',
                }),
            })}
            size={40}
            thickness={4}
        />;
    }

    const splitAlternating = (items) => {
        const column1 = [];
        const column2 = [];

        items.forEach((item, index) => {
            if (index % 2 === 0) {
                column1.push(item); // Odd indexed items (0, 2, 4, etc.)
            } else {
                column2.push(item); // Even indexed items (1, 3, 5, etc.)
            }
        });

        return [column1, column2];
    };


    const isNewsPage = location.pathname === "/news";

    if (isNewsPage && !isSmallScreen) {
        const [col1, col2] = splitAlternating(onceHumanEvents);

        return (
            <>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {/* First Column */}
                    <Grid item xs={12} lg={6}>
                        {col1.map((event) => (
                            ((event.category > 1 && isNewsPage) || !isNewsPage) && (
                                <EventCard
                                    key={event.id}
                                    event={event}
                                    eventTypes={eventTypes}
                                />
                            )
                        ))}
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={12} lg={6}>
                        {col2.map((event) => (
                            ((event.category > 1 && isNewsPage) || !isNewsPage) && (
                                <EventCard
                                    key={event.id}
                                    event={event}
                                    eventTypes={eventTypes}
                                />
                            )
                        ))}
                    </Grid>
                </Grid>

            </>
        );
    }


    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ mt: 2, display: 'flex', flexDirection: 'row'}}  // Ensure all items stretch to the same height
            >
                {onceHumanEvents.length > 0 && (
                    onceHumanEvents.map((event) => {
                        return ((event.category > 1 && isNewsPage) || !isNewsPage) ? (
                            <EventCard event={event} eventTypes={eventTypes}/>
                        ) : null;
                    })
                )}
            </Grid>
        </>
    );
}
