import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    Divider, FormControl, MenuItem, Select,
    Tooltip,
    Typography,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SimpleBox from "../util/SimpleBox";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ShowTitle from "../util/ShowTitle";
import ContentBox from "../util/ContentBox";
import UniversalSelector from "../build_planner/UniversalSelector";
import Cookies from 'js-cookie';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import RequireAuthLogInWarning from "../auth/RequireAuthLogInWarning";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";

export default function MyListings() {
    const [inputs, setInputs] = useState({});
    const [listings, setListings] = useState([]);
    const [resources, setResources] = useState([]);
    const [resourcesArray, setResourcesArray] = useState([]);
    const [servers, setServers] = useState([]);

    useEffect(() => {
        getTrades();
        getResources();
    }, []);

    function getTrades() {
        axios.get('/api/get/userListings')
            .then((result) => {
                if (result.data !== false) {
                    setListings(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getResources() {
        axios.get('/api/list/resources')
            .then((result) => {
                if (result.data !== false) {
                    const resourcesObject = result.data.reduce((acc, resource) => {
                        acc[resource.id] = resource;
                        return acc;
                    }, {});
                    setResourcesArray(resourcesObject);
                    setResources(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if (!listings) {
        return null;
    }

    return (
        <>
            <RequireAuthLogInWarning>
            <SimpleBox sx={{ mb: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={4} sx={{ mb: 1 }}>
                        <Button
                            fullWidth
                            component={Link}
                            to={`/addListing`}
                            color="success"
                            variant="contained"
                        >
                            List Items
                        </Button>
                    </Grid>
                    <Grid item xs={4} sx={{ mb: 1 }}>
                        <Button
                            fullWidth
                            component={Link}
                            to={`/addVendor`}
                            color="success"
                            variant="contained"
                        >
                            Add New Vendor
                        </Button>
                    </Grid>
                </Grid>
            </SimpleBox>
            <SimpleBox>
                <Grid container>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Exchange To
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        <Tooltip title={`Price Per Unit`}>
                                            PPU
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h6">
                                        Check
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ border: '1px solid white', m: 1 }} />
                        </Grid>

                    {listings.length > 0 ? (
                        listings.map((listing) => (
                            <Grid item xs={12} key={listing.id} sx={{ mb: 1 }}>
                                <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6">
                                            {listing.item_sell_qty}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Tooltip title={`${resourcesArray[listing.item_sell_id]?.title}`} arrow>
                                            <img
                                                src={`/images/resources/${listing.item_sell_id}.webp`}
                                                alt={listing.comment || 'Item'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    border: '2px solid black',
                                                    marginRight: '2px',
                                                }}
                                            />
                                        </Tooltip>
                                        <Typography variant="h6" sx={{ ml: 2 }}>
                                            {`${resourcesArray[listing.item_sell_id]?.title}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} lg={1}>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <DoubleArrowIcon/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} lg={1}>
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {listing.item_buy_qty}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <Tooltip title={`${resourcesArray[listing.item_buy_id]?.title}`} arrow>
                                            <img
                                                src={`/images/resources/${listing.item_buy_id}.webp`}
                                                alt={listing.comment || 'Item'}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    border: '2px solid black',
                                                    marginRight: '2px',
                                                }}
                                            />
                                        </Tooltip>
                                        <Typography variant="h6" sx={{ ml: 2 }}>
                                            {`${resourcesArray[listing.item_buy_id]?.title}`}
                                        </Typography>
                                    </Grid>
                                    {inputs.server === 0 ? (
                                        <Grid item xs={6} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {servers[listing.server_id]?.title}
                                        </Grid>
                                    ) : (
                                        <Grid item xs={6} lg={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {(listing.item_sell_qty && listing.item_buy_qty) ? (listing.item_buy_qty / listing.item_sell_qty).toFixed(3) : 'N/A'}
                                        </Grid>
                                    )}
                                    <Grid item xs={6} lg={2} sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mr: 1
                                    }}>
                                        {new Date(listing.updated_at).toLocaleString('en-GB', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: false // Use 24-hour format
                                        })}
                                    </Grid>
                                    <Grid item xs={6} lg={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        {listing.state === 4 ? (
                                            <Tooltip title={'Continous Supply'}>
                                                <IconButton>
                                                    <AutorenewRoundedIcon color={'success'}/>
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={
                                                listing.state === 1 ? 'Available' : listing.state === 2 ? 'Buyer Applied' : listing.state === 3 ? 'Reported Missing' : 'Inactive'}>
                                                <IconButton>
                                                    <LightbulbRoundedIcon color={
                                                        listing.state === 1 ? 'success' : listing.state === 2 ? 'warning' : listing.state === 3 ? 'error' : '#fff'
                                                    }/>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Button
                                            sx={{ flexGrow: 1 }}
                                            onClick={() => window.location.href = `/trade/edit/${listing.id}`}
                                            color="success"
                                            variant="contained"
                                        >
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 1 }}/>
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            No listings found
                        </Grid>
                    )}
                </Grid>
            </SimpleBox>
            </RequireAuthLogInWarning>
        </>
    );
}
