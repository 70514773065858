import React from 'react';
import { Grid, Divider } from '@mui/material';

const ModGridDisplay = ({ modData, locations }) => {

    if (!modData) {
        return null;
    }

    // Check if modData.location is a string, otherwise default to an empty array
    const locationIds = typeof modData.location === 'string'
        ? modData.location.split(',')
        : [];

    return (
        <Grid item xs={12} >
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={12} md={3} sx={{fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <img
                        src={`/images/mods/${modData.id}.webp`}
                        alt={modData.title}
                        style={{
                            width: '50px',
                            height: '50px',
                            border: '2px solid black',
                            marginRight: '8px',
                        }}
                    />
                    {modData.title}
                </Grid>
                <Grid item xs={12} md={6}>
                    <div dangerouslySetInnerHTML={{__html: modData.description}}/>
                </Grid>
                <Grid item xs={12} md={3} sx={{textAlign: 'right'}}>
                    {locationIds.map((locId) => (
                        <div key={locId}>
                            {locations[locId]?.title || 'Unknown Location'}
                        </div>
                    ))}
                </Grid>
            </Grid>
            <Divider sx={{bgcolor: 'white'}}/>
        </Grid>
    );
};

export default ModGridDisplay;
