import * as React from 'react';
import StarIcon from '@mui/icons-material/Star';
import {Tooltip, Typography} from '@mui/material';

// Rating labels
const labels = {
    0.5: 'F',
    1: 'D',
    1.5: 'D+',
    2: 'C',
    2.5: 'C+',
    3: 'B',
    3.5: 'B+',
    4: 'A',
    4.5: 'A+',
    5: 'S',
};

// Function to get the closest label for a given value
function getClosestLabel(value) {
    // Find the closest label for the given value
    const closest = Object.keys(labels).reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    return labels[closest];
}

export default function PercentageStar({ rating }) {
    // Ensure the rating is between 0 and 5
    const adjustedRating = Math.max(0, Math.min(5, Number(rating).toFixed(1)));

    // Calculate the percentage of the star to fill
    const percentage = (adjustedRating / 5) * 100;
    const label = getClosestLabel(parseFloat(adjustedRating));

    return (
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '40px', height: '40px', marginLeft: '10px', marginRight: '20px' }}>
            <Tooltip title={`Average rating: ${adjustedRating}`} arrow>
                <StarIcon
                    style={{
                        fontSize: '40px',
                        color: '#ffb400',
                        position: 'absolute',
                        clipPath: `inset(0 ${100 - percentage}% 0 0)`,
                        zIndex: 1,
                    }}
                />
                <StarIcon
                    style={{
                        fontSize: '40px',
                        color: '#e0e0e0',
                    }}
                />
            </Tooltip>
            <Tooltip title={`Average rating: ${adjustedRating}`} arrow>
                <Typography variant="body2" sx={{ fontStyle: 'bold', ml: 2, position: 'absolute', zIndex: 2, color: 'black' }}>
                    {label}
                </Typography>
            </Tooltip>
        </div>
    );
}
