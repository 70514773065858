import React, { useState, useEffect } from 'react';
import {TextField, Pagination, MenuItem, Select, InputLabel, FormControl, Typography, Tooltip} from '@mui/material';
import SimpleBox from "../util/SimpleBox";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import DarkTooltip from "../util/DarkTooltip";

const CodexSmall = ({ data }) => {
    const [gears, setGears] = useState([]);
    const [mods, setMods] = useState([]);
    const [calibrations, setCalibrations] = useState([]);
    const [serverDifficulty, setServerDifficulty] = useState([]);
    const [anomalies, setAnomalies] = useState([]);
    const [buildTypes, setBuildTypes] = useState([]);
    const [cradles, setCradles] = useState([]);
    const [stats, setStats] = useState([]);
    const [locations, setLocations] = useState({});
    const [sets, setGeasets] = useState([]);
    const [damageTypes, setDamageTypes] = useState([]);
    const [deviations, setDeviations] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]); // To store filtered items
    const [searchTerm, setSearchTerm] = useState(''); // For the search field
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const [selectedCategory, setSelectedCategory] = useState(''); // To store the selected category
    const itemsPerPage = 6;

    useEffect(() => {
        if (data) {
            const filters = {
                gears: data.gears?.filter(gear => gear.category >= 0) || [],
                mods: data.mods?.filter(mod => mod.category >= 0) || [],
                cradles: data.cradles?.filter(cradle => cradle.id >= 0) || [],
                deviations: data.deviations?.filter(location => location.id >= 0) || []
            };

            setGears(filters.gears);
            setMods(filters.mods);
            setCalibrations(filters.calibrations);
            setCradles(filters.cradles);
            setDeviations(filters.deviations);
        }
    }, [data]);

    // Combine all items for filtering with type info
    const allItems = [
        ...gears.map(item => ({ ...item, type: 'gears' })),
        ...mods.map(item => ({ ...item, type: 'mods' })),
        ...cradles.map(item => ({ ...item, type: 'cradles' })),
        ...deviations.map(item => ({ ...item, type: 'deviations' }))
    ];

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to the first page when search term changes
    };

    // Handle category change
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset to the first page when category changes
    };

    // Filter items based on search term and selected category
    const filteredItemsToShow = allItems.filter(item => {
        const matchesSearch = item.title?.toLowerCase().trim().includes(searchTerm.toLowerCase().trim());
        const matchesCategory = selectedCategory ? item.type === selectedCategory : true;
        return matchesSearch && matchesCategory;
    });

    // Pagination logic
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const itemsToShow = filteredItemsToShow.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <SimpleBox>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', ml: 1 }}>
                            <Typography
                                variant="h5"
                                component="div"
                                gutterBottom
                                sx={{
                                    fontVariant: 'small-caps',
                                    textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)' // Applying textShadow with RGBA color
                                }}
                            >
                                Codex
                                <DarkTooltip tooltipText={'Codex is a knowledge base that we constantly expand to find every item, deviation, mod, etc. and their available info.'} />
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mr: 1 }}>
                            <Tooltip title={`Open Codex for advanced search`}>
                                <IconButton to={"/codex"} component={Link} color="info">
                                    <SearchIcon  />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Divider sx={{ border: '1px solid white', m: 1}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Search anything"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {filteredItemsToShow.length > 0 && (
                            <>
                                {itemsToShow.map(item => {
                                    const cellWidth = item.type === 'gears' ? '100px' : '50px';

                                    return (
                                        <Grid item xs={12} md={6} key={item.id}>
                                            <Button to={`/view/${item.type}/${item.id}`} component={Link} fullWidth
                                                    sx={{alignItems: 'center', justifyContent: 'flex-start'}}>
                                                <img
                                                    src={`/images/${item.type}/${item.id}.webp`}
                                                    alt={item.title}
                                                    style={{
                                                        width: cellWidth,
                                                        height: '50px',
                                                        border: '2px solid black',
                                                        marginRight: '10px'
                                                    }}
                                                />
                                                {item.title}
                                            </Button>
                                            <Divider/>
                                        </Grid>
                                    );
                                })}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </SimpleBox>
    );
};

export default CodexSmall;
