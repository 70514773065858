import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";

export default function AddLocation() {
    const [inputs, setInputs] = useState({});
    const [imageSrc, setImageSrc] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        axios.post('/api/create/location', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        title: ''
                    }));
                    setImageSrc(null);
                    setImageFile(null);
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
            setImageFile(file);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Location</h3>
                    </InputLabel>
                    {/*<CustomFileInput handleImageChange={handleImageChange} customText={"Tölts fel vagy húzz ide képet"} />*/}
                    {/*{imageSrc && <img src={imageSrc} alt="preview" height="100" />}*/}
                    <Grid>
                        <TextField
                            fullWidth
                            id="title"
                            label="Location"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
            </Container>
        </>
    );
}
