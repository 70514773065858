import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import CustomFileInput from "../util/CustomFileInput";
import Grid from "@mui/material/Grid";
import ShowSnack from "../util/ShowSnack";
import {MenuItem, Select, TextareaAutosize} from "@mui/material";
import {Editor} from "@tinymce/tinymce-react";
import ImageUpload from "../util/ImageUpload";

export default function AddCalibration() {
    const [inputs, setInputs] = useState({});
    const [locations, setLocations] = useState([]);

    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    useEffect(() => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            category: 0,
            location: 0
        }));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        axios.post('/api/create/calibration', formData)
            .then(function (response) {
                console.log(response);
                if (response.data === 1) {
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        title: '',
                        decription: ''
                    }));
                    setSnackOpen(true);
                    setMessage('Sikeres hozzáadás');
                    setSeverity('success');
                }
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value
        }));
    };

    return (
        <>
            <Container>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <InputLabel id="main-cat-label">
                        <h3>Add Calibration</h3>
                    </InputLabel>
                    <Grid sx={{mb: 2}}>
                        <TextField
                            fullWidth
                            id="title"
                            label="Name"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <div>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                value={inputs.description}
                                onEditorChange={(newValue, editor) => {
                                    setInputs(values => ({...values, description: newValue}));
                                }}
                                init={{
                                    height: 500,
                                    menubar: 'file edit view insert format tools table help',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                                        'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                                        'accordion'
                                    ],
                                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                                    image_advtab: true,
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_class: 'mceNonEditable',
                                    toolbar_mode: 'sliding',
                                    contextmenu: 'link image table',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <InputLabel id="category-label" sx={{ mt: 3 }}>Weapon Type</InputLabel>
                        <Select
                            id="category"
                            name="category"
                            labelId="category-label"
                            value={inputs.category}
                            onChange={handleChange}
                            fullWidth
                            label="Weapon Type"
                        >
                            <MenuItem key={0} value={0}> No Category </MenuItem>
                            <MenuItem key={1} value={1}> Shotgun </MenuItem>
                            <MenuItem key={2} value={2}> SMG </MenuItem>
                            <MenuItem key={3} value={3}> Assault Rifle </MenuItem>
                            <MenuItem key={4} value={4}> Sniper Rifle </MenuItem>
                            <MenuItem key={5} value={5}> LMG </MenuItem>
                            <MenuItem key={6} value={6}> Crossbow </MenuItem>
                            <MenuItem key={7} value={7}> Heavy Art. </MenuItem>
                            <MenuItem key={8} value={8}> Melee </MenuItem>
                            <MenuItem key={9} value={9}> Pistol </MenuItem>
                        </Select>
                    </Grid>
                    <Grid sx={{mb: 2}}>
                        <InputLabel id="category-label" sx={{ mt: 3 }}>Seasonal</InputLabel>
                        <Select
                            id="seasonal"
                            name="seasonal"
                            labelId="seasonal-label"
                            value={inputs.seasonal}
                            onChange={handleChange}
                            fullWidth
                            label="Seasonal"
                            defaultValue={0}
                        >
                            <MenuItem key={0} value={0}> No </MenuItem>
                            <MenuItem key={1} value={1}> Yes </MenuItem>
                        </Select>
                    </Grid>
                    <Button
                        type="submit"
                        color="success"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Add
                    </Button>
                </Box>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity}/>
            </Container>
        </>
    );
}
